<template>
  <div v-if="files" :class="$style['wrapper']">
    <div
        v-for="file in files"
        :key="file.file_name"
        :class="$style['file']"
    >
      <ion-card type="button" @click.prevent="openFile(file)">
        <div
            v-if=" preview_images[file.file_name]"
            :class="$style['image']">
          <img
              :src="preview_images[file.file_name]"
          />
        </div>
        <div v-else :class="$style['loader']">
          <ion-spinner/>
        </div>
        <ion-card-header>
          <ion-card-subtitle>
            {{ $t("files.types." + file.type) }}
          </ion-card-subtitle>
        </ion-card-header>
      </ion-card>
    </div>

    <ion-modal :class="$style['document-modal']" :is-open="opened_document" @willDismiss="opened_document=null">
      <ion-header>
        <ion-toolbar>
          <ion-title>Document</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="opened_document=null;">
              {{ $t('global.close') }}
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content v-if="opened_document">
        <iframe :class="$style['preview-frame']" :src="opened_document"/>
      </ion-content>
    </ion-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonModal,
  IonSpinner,
  IonToolbar
} from "@ionic/vue";
import { File } from "@/files";

export default defineComponent({
  props: ["files"],
  components: {
    IonSpinner, IonCard, IonCardHeader, IonCardSubtitle, IonModal, IonHeader, IonContent,
    IonToolbar,
    IonButtons, IonButton
  },
  data() {
    return {
      preview_images: {} as { [key: string]: string | ArrayBuffer | null },
      opened_document: null as string | null,
    }
  },
  mounted() {
    this.loadPreviewImages();
  },
  watch: {
    files() {
      this.loadPreviewImages();
    }
  },
  methods: {
    loadPreviewImages() {
      (Object.values(this.$props.files) as File[]).forEach((file) => {
        this.axios.get("storage/preview/" + file.file_name).then((response) => {
          this.preview_images[(file.file_name as string)] = response.data;
        });
      });
    },
    openFile(file: { file_name: string }, download = false) {
      this.axios.get("storage/download/" + file.file_name).then((response) => {
        const base64 = response.data.replace('image/pdf', 'application/pdf');

        if (download) {
          const link = document.createElement("a");
          link.href = base64;
          link.download = file.file_name;
          link.click();
        } else {
          this.opened_document = base64;
        }
      });
    },
  },
});
</script>

<style lang="scss" module>
.wrapper {
  overflow: auto;
  white-space: nowrap;

  .file {
    display: inline-block;
    width: 14em;
    margin-bottom: 1em;
    margin-right: 1em;

    ion-card {
      margin: 0.25em;
    }

    .loader,
    .image {
      width: 100%;
      height: 10em;
      position: relative;
    }

    .image {
      background: black;

      img {
        object-fit: cover;
        object-position: top center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: .9;
      }
    }

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--ion-color-light);
    }
  }
}

.preview-frame {
  display: flex;
  width: 100%;
  height: calc(100% - 5em);
  border: none;
}

.document-modal {
  @media only screen and (min-width: 768px) and (min-height: 768px) {
    --width: 76vw;
    --height: 90vh;
  }
}
</style>