import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = {
  key: 4,
  class: "ion-anti-padding"
}
const _hoisted_6 = { key: 5 }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_FilesPreview = _resolveComponent("FilesPreview")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    text: _ctx.$t('equipment.back_button_label'),
                    "default-href": "/equipment"
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.equipment?.display_name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipment?.tracker?.sort(
          (a, b) => _ctx.moment(b.tracked_at).unix() - _ctx.moment(a.tracked_at).unix()
        ), (tracked) => {
            return (_openBlock(), _createElementBlock("div", {
              key: tracked.uuid,
              class: _normalizeClass(_ctx.$style['item'] + ' ion-padding')
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style['item__left'])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['item__bullet'])
                }, [
                  (tracked.action === 'check:in')
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 0,
                        icon: _ctx.homeIcon
                      }, null, 8, ["icon"]))
                    : (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 1,
                        icon: _ctx.mapIcon
                      }, null, 8, ["icon"]))
                ], 2)
              ], 2),
              _createElementVNode("div", null, [
                (tracked.action === 'check:out')
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(tracked?.site?.display_name), 1))
                  : (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.$t("equipment.tracker.events." + tracked.action)), 1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.moment(tracked?.tracked_at).format("lll")), 1),
                _createElementVNode("p", null, [
                  _createVNode(_component_ion_icon, { icon: _ctx.userIcon }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(tracked?.roles
                  ?.filter((role) => role.role === "user")
                  ?.map((role) => role.user.display_name)
                  .join(", ")), 1)
                ]),
                (0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                      _createVNode(_component_ion_icon, { icon: _ctx.scanQrCodeIcon }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(tracked?.roles
                  ?.filter((role) => role.role === "reporter")
                  ?.map((role) => role.user.display_name)
                  .join(", ")), 1)
                    ]))
                  : _createCommentVNode("", true),
                (tracked?.comment)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("p", null, [
                        _createVNode(_component_ion_icon, { icon: _ctx.chatbubbleOutline }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(tracked?.comment), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (tracked?.files?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_FilesPreview, {
                        files: tracked?.files,
                        class: "ion-padding"
                      }, null, 8, ["files"])
                    ]))
                  : _createCommentVNode("", true),
                (tracked?.signature)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("h2", null, _toDisplayString(_ctx.$t("equipment.tracker.sign.title")), 1),
                      _createElementVNode("img", {
                        src: tracked?.signature,
                        width: "50%",
                        height: "50%"
                      }, null, 8, _hoisted_7)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}