import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = { class: "ion-text-center" }
const _hoisted_3 = {
  key: 2,
  class: "ion-text-center"
}
const _hoisted_4 = { class: "ion-text-center" }
const _hoisted_5 = { class: "ion-text-center" }
const _hoisted_6 = { class: "ion-text-center" }
const _hoisted_7 = { class: "ion-text-center" }
const _hoisted_8 = { class: "ion-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_EquipmentSelectorButton = _resolveComponent("EquipmentSelectorButton")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_SiteSelectorButton = _resolveComponent("SiteSelectorButton")!
  const _component_UserSelectorButton = _resolveComponent("UserSelectorButton")!
  const _component_uploadable_file = _resolveComponent("uploadable-file")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_vue_drawing_canvas = _resolveComponent("vue-drawing-canvas")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.item?.display_name)
        ? (_openBlock(), _createBlock(_component_ion_chip, {
            key: 0,
            color: "primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back_to_step ? (_ctx.currentStep = 0) : {}))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.wrenchIcon }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.display_name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.tracked_event)
        ? (_openBlock(), _createBlock(_component_ion_chip, {
            key: 1,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.back_to_step ? (_ctx.currentStep = 1) : {}))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.deliveryIcon }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`equipment.tracker.events.${_ctx.tracked_event}`)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.site?.display_name)
        ? (_openBlock(), _createBlock(_component_ion_chip, {
            key: 2,
            color: "secondary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.back_to_step ? (_ctx.currentStep = 2) : {}))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.mapIcon }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.site.display_name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.used_user?.display_name)
        ? (_openBlock(), _createBlock(_component_ion_chip, {
            key: 3,
            color: "tertiary",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.back_to_step ? (_ctx.currentStep = 3) : {}))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.userIcon }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.used_user.display_name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.photos?.length)
        ? (_openBlock(), _createBlock(_component_ion_chip, {
            key: 4,
            color: "success",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.currentStep = 4))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.cameraIcon }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.photos.length), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass({ 'ion-padding': true, [_ctx.$style['step-panel']]: true })
    }, [
      _createVNode(_component_ion_icon, {
        class: _normalizeClass(_ctx.$style['step-icon']),
        icon: _ctx.wrenchIcon
      }, null, 8, ["class", "icon"]),
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("equipment.tracker.equipment_name")), 1),
      _createVNode(_component_EquipmentSelectorButton, {
        selectedItem: _ctx.item,
        onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.item = $event?.value))
      }, null, 8, ["selectedItem"]),
      _createVNode(_component_ion_button, {
        disabled: !_ctx.item,
        fill: "clear",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.currentStep++))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("equipment.tracker.next")), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ], 2), [
      [_vShow, _ctx.currentStep === 0]
    ]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass({ 'ion-padding': true, [_ctx.$style['step-panel']]: true })
    }, [
      (_ctx.last_tracked_event_loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.loader)
          }, [
            _createVNode(_component_ion_spinner)
          ], 2))
        : _createCommentVNode("", true),
      (!_ctx.last_tracked_event_loading)
        ? (_openBlock(), _createBlock(_component_ion_icon, {
            key: 1,
            class: _normalizeClass(_ctx.$style['step-icon']),
            icon: _ctx.deliveryIcon
          }, null, 8, ["class", "icon"]))
        : _createCommentVNode("", true),
      (!_ctx.last_tracked_event_loading)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.$t("equipment.tracker.event")), 1))
        : _createCommentVNode("", true),
      (!_ctx.last_tracked_event_loading)
        ? (_openBlock(), _createBlock(_component_ion_radio_group, {
            key: 3,
            disabled: !!(_ctx.trackEvents?.length <= 1 && _ctx.tracked_event),
            value: _ctx.tracked_event,
            onIonChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.tracked_event = $event.detail.value))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trackEvents, (trackEvent) => {
                return (_openBlock(), _createBlock(_component_ion_radio, {
                  key: trackEvent,
                  value: trackEvent
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`equipment.tracker.events.${trackEvent}`)), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["disabled", "value"]))
        : _createCommentVNode("", true),
      (!_ctx.last_tracked_event_loading)
        ? (_openBlock(), _createBlock(_component_ion_button, {
            key: 4,
            disabdled: !_ctx.tracked_event,
            fill: "clear",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.currentStep++))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("equipment.tracker.next")), 1)
            ]),
            _: 1
          }, 8, ["disabdled"]))
        : _createCommentVNode("", true)
    ], 2), [
      [_vShow, _ctx.currentStep === 1]
    ]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass({ 'ion-padding': true, [_ctx.$style['step-panel']]: true })
    }, [
      _createVNode(_component_ion_icon, {
        class: _normalizeClass(_ctx.$style['step-icon']),
        icon: _ctx.mapIcon
      }, null, 8, ["class", "icon"]),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("equipment.tracker.site")), 1),
      _createVNode(_component_SiteSelectorButton, {
        selectedItem: _ctx.site,
        onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.site = $event?.value))
      }, null, 8, ["selectedItem"]),
      _createVNode(_component_ion_button, {
        disabled: !_ctx.site,
        fill: "clear",
        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.currentStep++))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("equipment.tracker.next")), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ], 2), [
      [_vShow, _ctx.currentStep === 2]
    ]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass({ 'ion-padding': true, [_ctx.$style['step-panel']]: true })
    }, [
      _createVNode(_component_ion_icon, {
        class: _normalizeClass(_ctx.$style['step-icon']),
        icon: _ctx.userIcon
      }, null, 8, ["class", "icon"]),
      _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t("equipment.tracker.user")), 1),
      _createVNode(_component_UserSelectorButton, {
        selectedItem: _ctx.used_user,
        onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.used_user = $event?.value))
      }, null, 8, ["selectedItem"]),
      _createVNode(_component_ion_button, {
        disabled: !_ctx.used_user,
        fill: "clear",
        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.currentStep++))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("equipment.tracker.next")), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ], 2), [
      [_vShow, _ctx.currentStep === 3]
    ]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass({ 'ion-padding': true, [_ctx.$style['step-panel']]: true })
    }, [
      _createVNode(_component_ion_icon, {
        class: _normalizeClass(_ctx.$style['step-icon']),
        icon: _ctx.cameraIcon
      }, null, 8, ["class", "icon"]),
      _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t("equipment.tracker.photos")), 1),
      _createVNode(_component_uploadable_file, {
        params: { type: 'equipment-state' },
        value: _ctx.photos,
        multiple: "true",
        onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.photos = $event))
      }, null, 8, ["value"]),
      _createVNode(_component_ion_button, {
        disabled: _ctx.tracked_event === 'check:in' && !_ctx.photos?.length,
        fill: "clear",
        onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.currentStep++))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("equipment.tracker.next")), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ], 2), [
      [_vShow, _ctx.currentStep === 4]
    ]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass({ 'ion-padding': true, [_ctx.$style['step-panel']]: true })
    }, [
      _withDirectives(_createVNode(_component_ion_icon, {
        class: _normalizeClass(_ctx.$style['step-icon']),
        icon: _ctx.chatBubbleIcon
      }, null, 8, ["class", "icon"]), [
        [_vShow, false]
      ]),
      _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("equipment.tracker.comment")), 1),
      _createVNode(_component_ion_textarea, {
        modelValue: _ctx.comment,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.comment) = $event)),
        class: _normalizeClass(_ctx.$style['comment']),
        placeholder: _ctx.$t('equipment.tracker.comment_placeholder')
      }, null, 8, ["modelValue", "class", "placeholder"]),
      _createElementVNode("h1", _hoisted_8, _toDisplayString(_ctx.$t("equipment.tracker.sign.title")), 1),
      _createVNode(_component_vue_drawing_canvas, {
        class: _normalizeClass([_ctx.canvaError ? _ctx.$style['sign-bloc-error'] : _ctx.$style['sign-bloc']]),
        ref: "VueCanvasDrawing",
        image: _ctx.signature,
        "onUpdate:image": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.signature) = $event)),
        width: "300",
        height: "150"
      }, null, 8, ["class", "image"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['sign-bloc-button'])
      }, [
        _createVNode(_component_ion_button, {
          class: _normalizeClass(_ctx.$style['sign-bloc-clear-button']),
          color: "danger",
          expand: "block",
          fill: "clear",
          size: "default",
          onClick: _cache[17] || (_cache[17] = _withModifiers(($event: any) => (_ctx.$refs.VueCanvasDrawing.reset()), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("equipment.tracker.sign.clear")), 1)
          ]),
          _: 1
        }, 8, ["class"])
      ], 2),
      (_ctx.canvaError)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(_ctx.$style['error-message'])
          }, _toDisplayString(_ctx.$t("equipment.tracker.sign.error")), 3))
        : _createCommentVNode("", true),
      (_ctx.tracked_event)
        ? (_openBlock(), _createBlock(_component_ion_button, {
            key: 1,
            disabled: 
        _ctx.save_loading ||
        !_ctx.item ||
        !_ctx.tracked_event ||
        !_ctx.used_user ||
        _ctx.last_tracked_event_loading ||
        !_ctx.site ||
        !_ctx.item ||
        (_ctx.tracked_event === 'check:in' && !_ctx.photos?.length)
      ,
            color: "success",
            expand: "block",
            fill: "solid",
            size: "default",
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("equipment.tracker.actions." + _ctx.tracked_event)), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
        : _createCommentVNode("", true)
    ], 2), [
      [_vShow, _ctx.currentStep === 5]
    ])
  ], 64))
}