import { defineStore } from "pinia";
import { EquipmentType } from "@/equipments";

export const useEquipmentStore = defineStore("equipment", {
    state: () => {
        return {
            types: [] as EquipmentType['name'][],
            tracker: {
                events: [] as string[]
            }
        }
    }
}); 
