<template>
  <div>
    <SiteSelector
        :active="open"
        :filter="filter"
        @change="change"
        @close="open=false"
    />

    <ion-button color="light" expand="full"
                @click="(event) => {event.stopPropagation(); if(value){change(null)}else{open=true;}}">
      <ion-text v-if="!value" color="medium">
        {{ $t('global.select') }}
      </ion-text>
      <span v-else>
          {{ value }}
         </span>
    </ion-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SiteSelector from "@/components/data/selectors/SiteSelector.vue";
import { IonButton, IonText } from "@ionic/vue";
import { Site } from "@/sites";
import CloseIcon from "iconoir/icons/regular/asterisk.svg";

export default defineComponent({
  components: {SiteSelector, IonButton, IonText},
  emits: ['change'],
  props: ['filter', 'selectedItem'],
  setup() {
    return {
      CloseIcon
    };
  },
  mounted() {
    if (this.$props.selectedItem) {
      this.value = this.$props.selectedItem.name;
    }
  },
  watch: {
    selectedItem(value: Site | null) {
      if (value) {
        this.value = value.name;
      }
    }
  },
  methods: {
    change(value: any) {
      this.value = value?.value?.name;

      this.$emit('change', value);
    }
  },
  data() {
    return {
      open: false,
      value: null as Site['name'] | null
    }
  }
});
</script>