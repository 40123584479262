import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    "is-open": _ctx.is_open,
    onWillDismiss: _cache[4] || (_cache[4] = ($event: any) => (_ctx.is_open=false))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('widgets.equipment_selector.title')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.is_open=false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.close')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_searchbar, {
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                placeholder: _ctx.$t('global.search')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }),
          (_ctx.filter_types?.length)
            ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_segment, {
                    value: _ctx.selected_type??'',
                    onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selected_type=$event.detail.value))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filter_types, (type) => {
                        return (_openBlock(), _createBlock(_component_ion_segment_button, {
                          key: type,
                          value: type
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('equipment.types.' + type)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.first_loading&&_ctx.equipments.length)
            ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipments, (equipment) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: equipment.uuid,
                      button: true,
                      onClick: ($event: any) => (_ctx.selectEquipment(equipment))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(equipment.reference) + " — " + _toDisplayString(equipment.name), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128)),
                  _createVNode(_component_ion_infinite_scroll, {
                    disabled: _ctx.loading,
                    hidden: _ctx.has_more_data||_ctx.loading,
                    threshold: "200px",
                    onIonInfinite: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadEquipments($event)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_infinite_scroll_content)
                    ]),
                    _: 1
                  }, 8, ["disabled", "hidden"])
                ]),
                _: 1
              }))
            : (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass({
        'ion-padding':true,
        [_ctx.$style['no-result']]:true
       })
                }, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('widgets.equipment_selector.no_result')), 1)
                ], 2))
              : (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass({
      'ion-padding':true,
      [_ctx.$style['no-result']]:true
    })
                  }, [
                    _createVNode(_component_ion_spinner)
                  ], 2))
                : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}