<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button
            :text="$t('orders.back_button_label')"
            default-href="/orders"
          ></ion-back-button>
        </ion-buttons>

        <ion-title>
          {{ order?.display_name }}
        </ion-title>

        <ion-buttons
          v-if="order?.status !== 'done' && order?.status !== 'refused'"
          slot="end"
        >
          <ion-button
            :disabled="save_loading || !loaded || order?.sync_needed"
            @click="presentActionSheet"
          >
            {{ $t("global.actions") }}
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ContentSidebarWrapper>
        <template v-slot:content>
          <div v-if="loaded">
            <div v-if="show_summary" :class="$style['email-summary']">
              <h1 v-if="0">
                {{ $t("orders.fields.reference") }}: {{ order?.reference }}
              </h1>
              <div v-if="order?.provider">
                <div :class="$style['label']">
                  {{ $t("orders.fields.provider.name") }}
                </div>
                <div :class="$style['value']">
                  {{ order?.provider?.name }}
                </div>
              </div>
              <div v-if="order?.site">
                <div :class="$style['label']">
                  {{ $t("orders.fields.site") }}
                </div>
                <div :class="$style['value']">
                  {{ order?.site?.name }}
                </div>
              </div>
              <div v-if="issuer">
                <div :class="$style['label']">
                  {{ $t("users.roles.issuer") }}
                </div>
                <div :class="$style['value']">
                  {{ issuer?.display_name }}
                </div>
              </div>
              <div v-if="order?.created_at">
                <div :class="$style['label']">
                  {{ $t("orders.fields.created_at") }}
                </div>
                <div :class="$style['value']">
                  {{ moment(order?.created_at).format("LL") }}
                </div>
              </div>
              <div>
                <div :class="$style['label']">
                  {{ $t("orders.products_table.labels.total") }}
                </div>
                <div :class="$style['value']">
                  {{ text.formatPrice(order_total) }}
                </div>
              </div>
              <div>
                <div :class="$style['label']">
                  {{ $t("orders.fields.company") }}
                </div>
                <div :class="$style['value']">
                  {{ user?.current_organization?.name }}
                </div>
              </div>
            </div>

            <ProductsTable
              :class="$style['products-table']"
              :editable="
                !save_loading &&
                order?.status === 'draft' &&
                !order?.sync_needed
              "
              :order="order"
              :scheme="show_summary ? 'medium' : 'primary'"
              @change="itemsChanged"
            />

            <div class="ion-padding">
              <div :class="$style['comment-wrapper']">
                <ion-textarea
                  ref="delivery_address_field"
                  v-model="order_delivery_address"
                  :auto-grow="true"
                  :class="$style['validation-comment']"
                  :disabled="
                    current_user_validation?.approved ||
                    order?.status === 'refused'
                  "
                  :placeholder="$t('orders.delivery_address_field.placeholder')"
                  :spellcheck="true"
                  autocapitalize="on"
                  @ionBlur="delivery_address_autocompose = ''"
                  @keyup="deliveryAddressChanged"
                  @keydown.enter="validateDeliveryAddressAutoCompose"
                  @keydown.tab="validateDeliveryAddressAutoCompose"
                />
                <div
                  v-if="delivery_address_autocompose"
                  :class="$style['auto-compose']"
                  v-html="
                    (
                      order_delivery_address + delivery_address_autocompose
                    ).replaceAll('\n', '<br />')
                  "
                ></div>
              </div>

              <div
                v-if="order?.status !== 'done' && order?.status !== 'refused'"
                v-show="0"
                :class="$style['comment-actions']"
              >
                <ion-button
                  :disabled="!can_save"
                  color="success"
                  @click="save_delivery_address"
                >
                  {{ $t("global.save") }}
                </ion-button>
              </div>
            </div>

            <div class="ion-padding">
              <div :class="$style['comment-wrapper']">
                <ion-textarea
                  ref="comment_field"
                  v-model="order_comment"
                  :auto-grow="true"
                  :class="$style['validation-comment']"
                  :disabled="
                    current_user_validation?.approved ||
                    order?.status === 'refused'
                  "
                  :placeholder="$t('orders.comment_field.placeholder')"
                  :spellcheck="true"
                  autocapitalize="on"
                  @ionBlur="comment_autocompose = ''"
                  @keyup="commentChanged"
                  @keydown.enter="validateAutoCompose"
                  @keydown.tab="validateAutoCompose"
                />
                <div
                  v-if="comment_autocompose"
                  :class="$style['auto-compose']"
                  v-html="
                    (order_comment + comment_autocompose).replaceAll(
                      '\n',
                      '<br />'
                    )
                  "
                ></div>

                <div
                  v-if="order?.status !== 'done' && order?.status !== 'refused'"
                  :class="$style['comment-toolbar']"
                >
                  <ion-button
                    id="defined-comments-trigger"
                    :disabled="
                      current_user_validation?.approved || order?.sync_needed
                    "
                    fill="clear"
                    shape="round"
                  >
                    <ion-icon :icon="arrowUndo"></ion-icon>
                  </ion-button>
                  <ion-popover
                    trigger="defined-comments-trigger"
                    trigger-action="click"
                  >
                    <ion-item
                      v-for="(comment, index) in JSON.parse(
                        $t('orders.defined_comments.values')
                      )"
                      :key="index"
                      :button="true"
                      type="button"
                      @click="order_comment = comment"
                    >
                      <ion-label>{{ comment }}</ion-label>
                    </ion-item>
                  </ion-popover>
                </div>
              </div>

              <div
                v-if="order?.status !== 'done' && order?.status !== 'refused'"
                v-show="0"
                :class="$style['comment-actions']"
              >
                <ion-button
                  :disabled="!can_save"
                  color="success"
                  @click="save_comment"
                >
                  {{ $t("global.save") }}
                </ion-button>
              </div>
            </div>

            <FilesPreview
              v-if="order?.files"
              :files="order?.files"
              class="ion-padding"
            />

            <div
              v-if="0 && !current_user_validation?.approved"
              class="ion-padding"
            >
              <ion-textarea
                v-model="validation_comment"
                :class="$style['validation-comment']"
                :placeholder="
                  current_user_validation?.comment ??
                  $t('orders.validation.comment_placeholder')
                "
                :spellcheck="true"
                autocapitalize="on"
              />
            </div>

            <div v-if="0" class="ion-padding">
              <ion-button
                :disabled="
                  current_user_validation?.approved ||
                  save_loading ||
                  order?.sync_needed
                "
                color="success"
                expand="block"
                fill="solid"
                size="large"
                @click="approveOrder"
              >
                <ion-spinner v-if="save_loading" />
                <span v-else-if="!current_user_validation?.approved">
                  {{ $t("orders.validation.validate_button_label") }}
                </span>
                <span v-else>
                  {{ $t("orders.validation.validated_button_label") }}
                </span>
              </ion-button>
            </div>

            <div v-if="order?.site" class="ion-padding">
              <SiteBlock :site="order?.site" />
            </div>
          </div>

          <div v-else class="ion-padding">
            <OrderSkeleton />
          </div>
        </template>

        <template v-slot:sidebar>
          <ion-accordion-group
            :disabled="!loaded"
            :multiple="true"
            :value="
              order?.status?.includes('validation')
                ? ['informations', 'validation']
                : ['informations']
            "
          >
            <ion-accordion value="informations">
              <ion-item slot="header" color="light">
                <ion-label>
                  {{ $t("orders.tabs.informations") }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-item lines="none">
                  <ion-label>{{ $t("orders.fields.reference") }}</ion-label>
                  <ion-label slot="end"> {{ order?.reference }}</ion-label>
                </ion-item>
                <ion-item lines="none">
                  <ion-label>{{ $t("orders.fields.status") }}</ion-label>
                  <ion-label slot="end">
                    <OrderStatus :order="order" />
                  </ion-label>
                </ion-item>
                <ion-item lines="none">
                  <ion-label>{{ $t("orders.fields.folder") }}</ion-label>
                  <ion-label v-if="order" slot="end">
                    <span
                      v-if="last_folder"
                      @click.prevent.stop="folder_modal = true"
                    >
                      {{ $t("orders.folder_labels." + last_folder) }}
                    </span>
                    <ion-button
                      v-else
                      color="light"
                      @click.prevent.stop="folder_modal = true"
                    >
                      {{ $t("global.select") }}
                    </ion-button>
                  </ion-label>
                </ion-item>
                <ion-item lines="none">
                  <ion-label>{{ $t("orders.fields.created_at") }}</ion-label>
                  <ion-label v-if="order" slot="end">
                    {{
                      moment(order?.created_at) > moment().subtract(5, "days")
                        ? moment(order?.created_at).fromNow()
                        : moment(order?.created_at).format("LLL")
                    }}
                  </ion-label>
                </ion-item>
                <ion-item
                  v-if="order?.created_at !== order?.updated_at"
                  lines="none"
                >
                  <ion-label>{{ $t("orders.fields.updated_at") }}</ion-label>
                  <ion-label slot="end">
                    {{
                      moment(order?.updated_at) > moment().subtract(5, "days")
                        ? moment(order?.updated_at).fromNow()
                        : moment(order?.updated_at).format("LLL")
                    }}
                  </ion-label>
                </ion-item>
              </div>
            </ion-accordion>

            <ion-accordion v-if="order?.validations?.length" value="validation">
              <ion-item slot="header" color="light">
                <ion-label>
                  {{ $t("orders.tabs.validation") }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-item
                  v-for="validation in order?.validations"
                  :key="validation.user.uuid"
                  lines="none"
                >
                  <ion-avatar slot="start">
                    <ion-img :src="validation.user.profile_picture"></ion-img>
                  </ion-avatar>
                  <ion-label>
                    <span v-if="user.uuid === validation.user.uuid">
                      {{ $t("orders.fields.validation.you") }}
                    </span>
                    <span v-else>
                      {{ validation.user.display_name }}
                    </span>
                  </ion-label>

                  <ion-label slot="end">
                    <ion-text v-if="validation.approved" color="success">
                      <ion-icon :icon="checkmarkOutline" />
                    </ion-text>
                    <ion-text v-else-if="validation.refused" color="danger">
                      <ion-icon :icon="close" />
                    </ion-text>
                    <ion-text v-else color="warning">
                      <ion-icon :icon="hourglassOutline" />
                    </ion-text>
                  </ion-label>
                </ion-item>
              </div>
            </ion-accordion>

            <ion-accordion value="roles">
              <ion-item slot="header" color="light">
                <ion-label>
                  {{ $t("orders.tabs.roles") }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-item
                  v-for="role in [
                    ...(order?.roles ?? []),
                    ...(order?.site?.roles ?? []),
                  ]"
                  :key="role.user.uuid"
                  lines="none"
                >
                  <ion-avatar slot="start">
                    <ion-img :src="role.user.profile_picture"></ion-img>
                  </ion-avatar>
                  <ion-label>
                    <span v-if="user.uuid === role.user.uuid">
                      {{ $t("orders.fields.validation.you") }}
                    </span>
                    <span v-else>
                      {{ role.user.display_name }}
                    </span>
                  </ion-label>

                  <ion-label slot="end">
                    <ion-text color="medium">
                      {{ $t("users.roles." + role.role) }}
                    </ion-text>
                  </ion-label>
                </ion-item>
              </div>
            </ion-accordion>

            <ion-accordion
              v-if="commented_validations?.length"
              value="comments"
            >
              <ion-item slot="header" color="light">
                <ion-label>
                  {{ $t("orders.tabs.comments") }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-item
                  v-for="validation in commented_validations"
                  :key="validation.user.uuid"
                  lines="none"
                >
                  <ion-chip color="medium">
                    <ion-icon :icon="personCircle"></ion-icon>
                    <ion-label v-if="user.uuid === validation.user.uuid">
                      {{ $t("orders.fields.validation.you") }}
                    </ion-label>
                    <ion-label v-else>
                      {{ validation.user.display_name }}
                    </ion-label>
                  </ion-chip>

                  {{ validation.comment }}
                </ion-item>
              </div>
            </ion-accordion>

            <ion-accordion value="provider">
              <ion-item slot="header" color="light">
                <ion-label>
                  {{ $t("orders.tabs.provider") }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-item lines="none">
                  <ion-label>{{ $t("orders.fields.provider.name") }}</ion-label>
                  <ion-label slot="end"> {{ order?.provider?.name }}</ion-label>
                </ion-item>
                <ion-item lines="none">
                  <ion-label>
                    {{ $t("orders.fields.provider.email") }}
                  </ion-label>
                  <ion-label slot="end">
                    <span
                      v-html="
                        order?.provider?.recipients
                          ?.map((item) => item.email)
                          .join('<br /> ')
                      "
                    ></span>
                  </ion-label>
                </ion-item>

                <ion-item lines="none">
                  <ion-label>{{
                    $t("orders.fields.provider.iban_number")
                  }}</ion-label>
                  <ion-label slot="end">
                    {{ order?.provider?.iban_number }}</ion-label
                  >
                </ion-item>
                <ion-item lines="none">
                  <ion-label>{{
                    $t("orders.fields.provider.vat_number")
                  }}</ion-label>
                  <ion-label slot="end">
                    {{ order?.provider?.vat_number }}</ion-label
                  >
                </ion-item>

                <ion-item lines="none">
                  <ion-label>{{
                    $t("orders.fields.provider.billing_address")
                  }}</ion-label>
                  <ion-label slot="end">
                    {{ order?.provider?.billing_address?.number }}
                    {{ order?.provider?.billing_address?.street }},
                    {{ order?.provider?.billing_address?.zip }}
                    {{ order?.provider?.billing_address?.city }},
                    {{ order?.provider?.billing_address?.country }}
                  </ion-label>
                </ion-item>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </template>
      </ContentSidebarWrapper>
    </ion-content>

    <FolderSelector
      v-if="order"
      :active="folder_modal"
      @change="
        ($event) => {
          order.folder = $event.value;
          save();
        }
      "
      @close="folder_modal = false"
    />

    <ion-modal
      :backdrop-dismiss="true"
      :can-dismiss="true"
      :is-open="discussion_modal"
      @willDismiss="discussion_modal = false"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button @click="discussion_modal = false">{{
              $t("global.close")
            }}</ion-button>
          </ion-buttons>
          <ion-title> </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <related-messages
          v-if="discussion_modal && order?.uuid"
          :related="order?.uuid"
        />
      </ion-content>
    </ion-modal>

    <ion-fab slot="fixed" horizontal="end" vertical="bottom">
      <ion-fab-button
        v-if="
          order?.status !== 'done' &&
          order?.status !== 'refused' &&
          (save_loading || !loaded)
        "
        :disabled="true"
        color="primary"
      >
        <ion-spinner />
      </ion-fab-button>

      <ion-fab-button
        v-else-if="order?.status !== 'done' && order?.status !== 'refused'"
        :disabled="save_loading || !loaded || order?.sync_needed"
        color="primary"
        @click="presentActionSheet"
      >
        <ion-icon :icon="caretUp"></ion-icon>
      </ion-fab-button>

      <ion-fab-button
        v-if="loaded"
        color="dark"
        @click="discussion_modal = true"
      >
        <ion-badge
          v-if="order?.messages_count"
          color="dark"
          style="background: none; z-index: 3; position: absolute"
        >
          {{ order?.messages_count }}
        </ion-badge>
        <ion-icon :icon="chatbubbleOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-page>
</template>

<script lang="ts">
import moment from "moment";

import text from "@/helpers/text";

import SiteBlock from "@/components/data/site/SiteBlock.vue";
import {
  actionSheetController,
  alertController,
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonPopover,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  toastController,
} from "@ionic/vue";
import { defineAsyncComponent, defineComponent, ref } from "vue";

import { useUserStore } from "@/stores/user";

import OrderSkeleton from "@/components/skeletons/OrderSkeleton.vue";

import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue";
import ProductsTable from "@/components/data/orders/ProductsTable.vue";
import FilesPreview from "@/components/data/files/FilesPreview.vue";

import { Order, OrderItem } from "@/orders";

import {
  arrowUndo,
  caretUp,
  chatbubbleOutline,
  checkmarkOutline,
  close,
  hourglassOutline,
  personCircle,
} from "ionicons/icons";

import OrderStatus from "@/components/data/orders/OrderStatus.vue";
import RelatedMessages from "@/components/data/messages/RelatedMessages.vue";

const FolderSelector = defineAsyncComponent(
  () => import("@/components/data/selectors/FolderSelector.vue")
);

export default defineComponent({
  components: {
    RelatedMessages,
    IonButton,
    IonContent,
    FolderSelector,
    IonBackButton,
    OrderSkeleton,
    ContentSidebarWrapper,
    ProductsTable,
    FilesPreview,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonAccordionGroup,
    IonAccordion,
    IonItem,
    IonLabel,
    IonBadge,
    IonIcon,
    IonText,
    IonTextarea,
    IonChip,
    IonSpinner,
    IonModal,
    IonPopover,
    IonAvatar,
    IonImg,
    IonFab,
    IonFabButton,
    OrderStatus,
    SiteBlock,
    IonPage,
  },
  setup() {
    const user = useUserStore();
    const comment_field = ref();
    const delivery_address_field = ref();

    return {
      user,
      hourglassOutline,
      close,
      checkmarkOutline,
      personCircle,
      arrowUndo,
      caretUp,
      moment,
      comment_field,
      delivery_address_field,
      text,
      chatbubbleOutline,
    };
  },
  data() {
    return {
      order: null,
      validation_comment: "",
      validation_elivery_address: "",
      order_comment: "",
      order_delivery_address: "",
      comment_autocompose: "",
      delivery_address_autocompose: "",
      order_save_timeout: 0,
      delivery_address_save_timeout: 0,
      autocompose_timeout: 0,
      autocompose_delivery_address_timeout: 0,
      loaded: false,
      save_loading: false,
      can_save: false,
      folder_modal: false,
      discussion_modal: false,
    } as {
      order: Order | null;
      validation_comment: string;
      validation_elivery_address: string;
      order_comment: string;
      order_delivery_address: string;
      comment_autocompose: string;
      delivery_address_autocompose: string;
      order_save_timeout: number;
      delivery_address_save_timeout: number;
      autocompose_timeout: number;
      autocompose_delivery_address_timeout: number;
      loaded: boolean;
      save_loading: boolean;
      can_save: boolean;
      folder_modal: boolean;
      discussion_modal: boolean;
    };
  },
  beforeUnmount() {
    (window as any).Echo?.leave(`orders.${this.order?.uuid}`);
  },
  watch: {
    order() {
      const comment_field_has_focus =
        document.activeElement?.tagName === "TEXTAREA";
      if (this.order?.comment && !comment_field_has_focus)
        this.order_comment = this.order.comment;

      const delivery_address_field_has_focus =
        document.activeElement?.tagName === "TEXTAREA";

      if (this.order?.delivery_address && !delivery_address_field_has_focus)
        this.order_delivery_address = this.order.delivery_address;
    },
  },
  methods: {
    async presentActionSheet() {
      const buttons = [];

      if (this.order?.status === "draft")
        buttons.push({
          text: this.$t("orders.actions.delete"),
          cssClass: "ion-color-danger",
          role: "destructive",
          data: {
            type: "delete",
          },
          handler: async () => {
            const confirm = await alertController.create({
              header: this.$t("orders.actions.delete"),
              message: this.$t("orders.actions.delete_confirm"),
              buttons: [
                {
                  text: this.$t("global.cancel"),
                  role: "cancel",
                },
                {
                  text: this.$t("orders.actions.delete"),
                  role: "confirm",
                  handler: () => {
                    this.axios
                      .delete("/orders/" + this.order?.uuid)
                      .then(() => {
                        this.$router.push({ name: "orders.list" }).then(() => {
                          toastController
                            .create({
                              message: this.$t("orders.actions.delete_success"),
                              duration: 2000,
                              color: "success",
                            })
                            .then((t) => t.present());
                        });
                      });
                  },
                },
              ],
            });

            await confirm.present();
          },
        });

      /*
      if (this.order?.status !== 'draft')

        buttons.push({
          disabled: !this.can_save,
          text: this.$t('orders.actions.save'), handler: async () => {
            this.save();
          }
        });
        */

      if (this.order?.status !== "draft")
        buttons.push({
          text: this.$t("orders.actions.approve"),
          cssClass: "ion-color-success",
          handler: () => {
            this.approveOrder();
          },
        });

      if (this.order?.status !== "draft")
        buttons.push({
          text: this.$t("orders.actions.refuse"),
          handler: async () => {
            const alert = await alertController.create({
              header: this.$t("orders.actions.refuse_confirm"),
              buttons: [
                {
                  text: this.$t("orders.actions.refuse"),
                  role: "destructive",
                  cssClass: "ion-color-danger",
                  handler: (data) => {
                    this.validation_comment = data[0] ?? "";
                    this.refuseOrder();
                  },
                },
                {
                  text: this.$t("global.cancel"),
                  role: "cancel",
                },
              ],
              inputs: [
                {
                  type: "textarea",
                  placeholder: this.$t("orders.actions.refuse_reason"),
                },
              ],
            });

            await alert.present();
          },
        });

      if (this.order?.status === "draft")
        buttons.push({
          text: this.$t("orders.actions.save"),
          handler: async () => {
            const confirm = await alertController.create({
              header: this.$t("orders.actions.save"),
              message: this.$t("orders.actions.save_confirm"),
              buttons: [
                {
                  text: this.$t("global.cancel"),
                  role: "cancel",
                },
                {
                  text: this.$t("orders.actions.save"),
                  role: "confirm",
                  handler: () => {
                    this.save(true);
                  },
                },
              ],
            });

            await confirm.present();
          },
        });

      /*
      buttons.push({
            text: this.$t('global.cancel'), role: 'cancel'
          }
      );

       */

      const actionSheet = await actionSheetController.create({
        header: this.order?.display_name,
        subHeader: this.$t("global.actions"),
        buttons: buttons,
      });
      await actionSheet.present();
    },
    load() {
      this.axios.get("/orders/" + this.$route.params.uuid).then((response) => {
        this.order = response.data;

        this.loaded = true;

        (window as any).Echo?.private(`orders.${this.order?.uuid}`).listen(
          "OrderChanged",
          (e: { order: Order }) => {
            console.log(e);
            if (e.order?.uuid) this.order = e.order;
          }
        );
      });
    },
    itemsChanged(event: { data: OrderItem; index: number }) {
      if (this.order?.items?.length && event.index !== null) {
        this.order?.items?.forEach((item, index) => {
          if (index === event.index) {
            item.sku = event.data.sku;
            item.label = event.data.label;
            item.quantity = event.data.quantity;
            item.price = event.data.price;
            item.total = event.data.price * event.data.quantity;
          }
        });
      } else {
        this.order?.items?.push(event.data);
      }

      if (this.save_loading) {
        console.log("Still loading");
      }

      this.save_loading = true;

      this.axios
        .put(
          "/orders/" + this.$route.params.uuid + "/items?index=" + event.index,
          event.data
        )
        .then(() => {
          this.save_loading = false;
        })
        .catch(() => {
          this.save_loading = false;
        });
    },

    deliveryAddressChanged() {
      this.save_loading = true;

      if (this.order_save_timeout) clearTimeout(this.order_save_timeout);
      this.order_save_timeout = setTimeout(this.save, 2000);

      this.can_save = true;
      this.delivery_address_autocompose = "";
      if (this.autocompose_delivery_address_timeout) {
        clearTimeout(this.autocompose_delivery_address_timeout);
      }
      this.autocompose_delivery_address_timeout = setTimeout(
        this.autocomposeDeliveryAddress,
        1000
      );
    },

    commentChanged() {
      this.save_loading = true;

      if (this.order_save_timeout) clearTimeout(this.order_save_timeout);
      this.order_save_timeout = setTimeout(this.save, 2000);

      this.can_save = true;
      this.comment_autocompose = "";
      if (this.autocompose_timeout) {
        clearTimeout(this.autocompose_timeout);
      }
      this.autocompose_timeout = setTimeout(this.autocompose, 1000);
    },

    autocomposeDeliveryAddress() {
      if (this.order_delivery_address.split(" ").length < 3) {
        this.delivery_address_autocompose = "";
        return;
      }

      const sentences = [
        ...JSON.parse(this.$t("orders.defined_comments.values")),
      ];

      const propositions = sentences.filter((sentence) => {
        return sentence
          .toLowerCase()
          .includes(this.order_delivery_address.toLowerCase());
      });

      if (propositions[0]) {
        this.comment_autocompose = propositions[0]
          .toLowerCase()
          .split(this.order_delivery_address.toLowerCase())[1];
      }
    },

    autocompose() {
      if (this.order_comment.split(" ").length < 3) {
        this.comment_autocompose = "";
        return;
      }

      const sentences = [
        ...JSON.parse(this.$t("orders.defined_comments.values")),
      ];

      const propositions = sentences.filter((sentence) => {
        return sentence
          .toLowerCase()
          .includes(this.order_comment.toLowerCase());
      });

      if (propositions[0]) {
        this.comment_autocompose = propositions[0]
          .toLowerCase()
          .split(this.order_comment.toLowerCase())[1];
      }
    },
    validateDeliveryAddressAutoCompose(e: Event) {
      if (this.delivery_address_autocompose) {
        e.preventDefault();
        this.order_delivery_address += this.delivery_address_autocompose;
      }
    },
    validateAutoCompose(e: Event) {
      if (this.comment_autocompose) {
        e.preventDefault();
        this.order_comment += this.comment_autocompose;
      }
    },

    save_comment() {
      this.save();
    },
    save_delivery_address() {
      this.save();
    },
    save(submit = false) {
      this.save_loading = true;
      this.axios
        .post("/orders/" + this.$route.params.uuid, {
          comment: this.order_comment ?? "",
          delivery_address: this.order_delivery_address,
          folder: this.order?.folder ?? null,
          submit: submit,
        })
        .then(() => {
          this.save_loading = false;
          this.can_save = false;

          if (submit) {
            toastController
              .create({
                message: this.$t("orders.actions.save_success"),
                duration: 2000,
                color: "success",
              })
              .then((t) => t.present());
          }
        });
    },
    async approveOrder() {
      // Check if provider has at least one email address
      if (
        !this.order?.provider?.recipients ||
        !this.order?.provider?.recipients[0]?.email
      ) {
        toastController
          .create({
            message: this.$t("orders.toasts.approve_no_provider_email"),
            duration: 2000,
            color: "danger",
          })
          .then((t) => t.present());

        return;
      }

      this.save_loading = true;
      this.axios
        .post("/orders/" + this.$route.params.uuid + "/approve", {
          comment: this.validation_comment,
        })
        .then(() => {
          this.save_loading = false;
        });

      (
        await toastController.create({
          message: this.$t("orders.toasts.approved_success"),
          duration: 5000,
          color: "success",
        })
      ).present();
    },
    async refuseOrder() {
      this.save_loading = true;
      this.axios
        .post("/orders/" + this.$route.params.uuid + "/refuse", {
          comment: this.validation_comment,
        })
        .then(() => {
          this.save_loading = false;
        });

      (
        await toastController.create({
          message: this.$t("orders.toasts.refused_success"),
          duration: 5000,
          color: "success",
        })
      ).present();
    },
  },
  computed: {
    current_user_validation() {
      return this.order?.validations?.find(
        (validation: { user: { uuid?: string } }) =>
          validation.user.uuid === this.user.uuid
      );
    },
    issuer() {
      return this.order?.roles?.find(
        (role: { role: string }) => role.role === "issuer"
      )?.user;
    },
    commented_validations() {
      return this.order?.validations?.filter((validation) => {
        return validation.comment;
      });
    },
    last_folder() {
      const folder = this.order?.folder?.split("/");

      return folder?.length ? folder[folder?.length - 1] : null;
    },
    show_summary() {
      return (
        this.$route.query?.source === "email" ||
        this.$route.query?.source === "notification"
      );
    },
    order_total() {
      return (
        this.order?.totals?.find(
          (total: { name: string }) => total.name === "total"
        )?.price ?? 0
      );
    },
  },
  mounted() {
    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });
  },
});
</script>

<style lang="scss" module>
$comment-padding: 1em;
.validation-comment {
  --background: var(--ion-color-light);

  --padding-top: #{$comment-padding};
  --padding-bottom: #{$comment-padding};
  --padding-start: #{$comment-padding};
  --padding-end: #{$comment-padding};
  min-height: 5em;
}

.comment-toolbar {
  background: var(--ion-color-light);
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  > * {
    margin: 0.5em;
  }
}

.comment-wrapper {
  position: relative;

  .auto-compose {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0.5;
    padding: $comment-padding;
    pointer-events: none;
  }
}

.comment-actions {
  display: flex;
  justify-content: flex-end;
}

.products-table,
.email-summary {
  @media (max-width: 800px) {
    font-size: 0.9em;
  }
}

.email-summary {
  h1 {
    display: block;
    text-align: left;
    font-size: 1em;
    font-weight: bold;
    padding: 1.5em;
    background: var(--ion-color-primary-shade);
    color: var(--ion-color-primary-contrast);
    margin: 0;
  }

  > div {
    padding: 0.75em 0;
    border-bottom: 1px solid var(--ion-color-light);

    &:last-child {
      border-bottom: none;
    }

    > div {
      padding: 0 1.5em;

      &.label {
        width: 12em;
        font-weight: bold;
      }
    }

    @media (min-width: 600px) {
      display: flex;
      align-items: center;
      padding: 1em 0;
    }
  }
}
</style>
