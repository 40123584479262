<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button
              :text="$t('planning.back_button_label')"
              default-href="/planning"
          ></ion-back-button>
        </ion-buttons>

        <ion-title>
          {{ executiveName ?? entry?.display_name }}
        </ion-title>

      </ion-toolbar>
    </ion-header>

    <ion-content v-if="entry">
      <ion-list>
        <ion-item>
          <ion-label position="fixed">
            Name
          </ion-label>
          {{ entry.task_name }}
        </ion-item>
        <ion-item>
          <ion-label position="fixed">
            Date
          </ion-label>
          {{ moment(entry.starts_at).format('LL') }}
        </ion-item>
      </ion-list>

    </ion-content>

    <ion-content v-else>
      <ion-grid :fixed="true">
        <ion-row>
          <ion-col>
            <PlanningEntrySkeleton/>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>


  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar
} from "@ionic/vue";

import PlanningEntrySkeleton from "@/components/skeletons/PlanningEntrySkeleton.vue";

import { chevronForwardOutline, mailUnreadOutline } from "ionicons/icons";

import { useUserStore } from "@/stores/user";
import { PlanningEntry } from "@/planning";

import moment from "moment";
import { usePlanningStore } from "@/stores/planning";

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonGrid,
    PlanningEntrySkeleton,
    IonRow,
    IonCol,
  },
  setup() {
    const planningDataset = usePlanningStore();
    const user = useUserStore();
    return {user, moment, chevronForwardOutline, mailUnreadOutline, planningDataset};
  },
  data() {
    return {
      loaded: false as boolean,
      entry: null as PlanningEntry | null,
      validation_step: false as boolean | string,
      edit_hours: false,
      show_messages: false,
      changed_comment: null as string | null,

      starts_at_hour: '00:00',
      ends_at_hour: '00:00',
      pause_duration: 0,

      files: [] as any
    }
  },
  computed: {
    compiled_roles() {
      const entry_roles = this.entry?.roles ?? [];
      const contractors = entry_roles.filter(role => role.user.contractor).map(role => {
        return {user: role.user.contractor, role: 'contractor'}
      }) ?? [];
      const site_roles = this.entry?.site?.roles ?? [];

      return [...entry_roles, ...contractors, ...site_roles].sort((a, b) => {
        if (a.role === 'contractor' && b.role === 'assignee') {
          return -1;
        } else if (a.role === 'assignee' && b.role === 'contractor') {
          return 1;
        } else {
          return 0;
        }
      }).filter(role => role.role !== 'validator')
    },
    site_supervisors() {
      return this.compiled_roles.filter((role: any) => role.role === 'site-supervisor').map((role: any) => role.user);
    },
    executiveName() {
      return this.compiled_roles.filter((role: any) => role.role === 'contractor').map((role: any) => role.user.display_name)[0];
    },
  },
  methods: {
    commentSubmit(data: any) {
      this.show_messages = false;
      this.changed_comment = data.message.length > 12 ? data.message.substring(0, 12) + '...' : data.message;
    },
    load() {
      this.axios.get("/planning/" + this.$route.params.uuid).then((response) => {
        this.entry = response.data;

        this.starts_at_hour = moment(this.entry?.starts_at).parseZone().format('HH:mm');
        this.ends_at_hour = moment(this.entry?.ends_at).parseZone().format('HH:mm');

        this.pause_duration = this.entry?.pause_duration ?? 0;

        this.loaded = true;
      });
    },
    save() {
      const starts_at = moment(this.entry?.starts_at).set({
        hour: moment(this.starts_at_hour, 'HH:mm').hour(),
        minute: moment(this.starts_at_hour, 'HH:mm').minute(),
      }).add(moment().utcOffset(), 'minutes');

      const ends_at = moment(this.entry?.ends_at).set({
        hour: moment(this.ends_at_hour, 'HH:mm').hour(),
        minute: moment(this.ends_at_hour, 'HH:mm').minute(),
      }).add(moment().utcOffset(), 'minutes');

      this.axios.post("/planning/" + this.$route.params.uuid, {
        starts_at: starts_at.toISOString(),
        ends_at: ends_at.toISOString(),
        pause_duration: this.pause_duration,
        files: this.files
      }).then(() => {
        this.load();

        this.validation_step = false;
        this.$router.push('/planning');
      });
    },
  },
  mounted() {
    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });
  },
});
</script>