<template>
  <div>
    <UserSelector
        :active="open"
        :filter="filter"
        @change="change"
        @close="open=false"
    />

    <ion-button color="light" expand="full"
                @click="(event) => {event.stopPropagation(); if(value){change(null)}else{open=true;}}">
      <ion-text v-if="!value" color="medium">
        {{ $t('global.select') }}
      </ion-text>
      <span v-else>
          {{ value }}
        </span>
    </ion-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserSelector from "@/components/data/selectors/UserSelector.vue";
import { IonButton, IonText } from "@ionic/vue";
import { User } from "@/users";
import CloseIcon from "iconoir/icons/regular/asterisk.svg";

export default defineComponent({
  components: {UserSelector, IonButton, IonText},
  emits: ['change'],
  props: ['filter', 'selectedItem'],
  setup() {
    return {CloseIcon};
  },
  mounted() {
    if (this.$props.selectedItem) {
      this.value = this.$props.selectedItem.display_name;
    }
  },
  watch: {
    selectedItem(value: User | null) {
      if (value) {
        this.value = value.display_name;
      }
    }
  },
  methods: {
    change(value: any) {
      this.value = value?.value?.display_name;

      this.$emit('change', value);
    }
  },
  data() {
    return {
      open: false,
      value: null as User['display_name'] | null
    }
  }
});
</script>