<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button
              :text="$t('equipment.back_button_label')"
              default-href="/equipment"
          ></ion-back-button>
        </ion-buttons>

        <ion-title>
          {{ equipment?.display_name }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div
          v-for="tracked in equipment?.tracker?.sort(
          (a, b) => moment(b.tracked_at).unix() - moment(a.tracked_at).unix()
        )"
          :key="tracked.uuid"
          :class="$style['item'] + ' ion-padding'"
      >
        <div :class="$style['item__left']">
          <div :class="$style['item__bullet']">
            <ion-icon v-if="tracked.action === 'check:in'" :icon="homeIcon"/>
            <ion-icon v-else :icon="mapIcon"/>
          </div>
        </div>
        <div>
          <h2 v-if="tracked.action === 'check:out'">
            {{ tracked?.site?.display_name }}
          </h2>

          <h2 v-else>
            {{ $t("equipment.tracker.events." + tracked.action) }}
          </h2>

          <p>
            {{ moment(tracked?.tracked_at).format("lll") }}
          </p>

          <p>
            <ion-icon :icon="userIcon"/>
            {{
              tracked?.roles
                  ?.filter((role) => role.role === "user")
                  ?.map((role) => role.user.display_name)
                  .join(", ")
            }}
          </p>

          <p v-if="0">
            <ion-icon :icon="scanQrCodeIcon"/>
            {{
              tracked?.roles
                  ?.filter((role) => role.role === "reporter")
                  ?.map((role) => role.user.display_name)
                  .join(", ")
            }}
          </p>

          <div v-if="tracked?.comment">
            <p>
              <ion-icon :icon="chatbubbleOutline"/>
              {{ tracked?.comment }}
            </p>
          </div>

          <div v-if="tracked?.files?.length" class="ion-anti-padding">
            <FilesPreview :files="tracked?.files" class="ion-padding"/>
          </div>

          <div v-if="tracked?.signature">
            <h2>
              {{ $t("equipment.tracker.sign.title") }}
            </h2>
            <img :src="tracked?.signature" width="50%" height="50%" />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import moment from "moment";

import text from "@/helpers/text";
import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, } from "@ionic/vue";
import { defineComponent, ref } from "vue";

import { useUserStore } from "@/stores/user";

import { Equipment } from "@/equipments";

import {
  arrowUndo,
  caretUp,
  chatbubbleOutline,
  checkmarkOutline,
  close,
  hourglassOutline,
  personCircle,
} from "ionicons/icons";

// Import home from iconoir
import homeIcon from "iconoir/icons/regular/home.svg";
import mapIcon from "iconoir/icons/regular/map.svg";
import userIcon from "iconoir/icons/regular/user.svg";
import scanQrCodeIcon from "iconoir/icons/regular/scan-qr-code.svg";
import FilesPreview from "@/components/data/files/FilesPreview.vue";

export default defineComponent({
  components: {
    FilesPreview,
    IonContent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonPage,
    IonIcon,
  },
  setup() {
    const user = useUserStore();
    const comment_field = ref();

    return {
      moment,
      homeIcon,
      user,
      mapIcon,
      userIcon,
      hourglassOutline,
      close,
      checkmarkOutline,
      scanQrCodeIcon,
      personCircle,
      arrowUndo,
      caretUp,
      comment_field,
      text,
      chatbubbleOutline,
    };
  },
  data() {
    return {
      equipment: null as Equipment | null,
      loaded: false,
      discussion_modal: false,
    };
  },
  methods: {
    load() {
      this.axios
          .get("/equipment/" + this.$route.params.uuid + "?withTracking=1")
          .then((response) => {
            this.equipment = response.data;

            this.loaded = true;
          });
    },
  },
  computed: {},
  mounted() {
    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });
  },
});
</script>

<style lang="scss" module>
.item {
  display: flex;

  > div {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:after {
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      background: var(--ion-color-light);
    }
  }

  &__bullet {
    flex-shrink: 0;
    margin-top: 0.25em;
    margin-bottom: 0.5em;

    background: var(--ion-color-light);
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
