<template>
  <ion-header v-if="modalControls" :translucent="true" color="dark">
    <ion-toolbar v-if="modalControls" color="dark">
      <ion-buttons slot="end" color="dark">
        <ion-button color="light" @click="$emit('skip')">
          Skip
        </ion-button>
        <ion-button color="light" @click="$emit('close')">
          <ion-icon :icon="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content :class="$style['scanner-content']" :fullscreen="true" color="dark">
    <div v-if="devices?.length" :class="$style['scanner-video']">
      <video ref="video"></video>
    </div>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import QrScanner from 'qr-scanner';
import { close } from 'ionicons/icons';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonToolbar, toastController } from "@ionic/vue";

export default defineComponent({
  components: {
    IonContent, IonHeader, IonToolbar, IonButton, IonButtons, IonIcon
  },
  props: ['modalControls'],
  emits: ['close', 'action', 'skip'],
  setup() {
    const video = ref(null);
    return {video, close}
  },
  data() {
    return {
      devices: [],
      qrScanner: null as any,
      scanLoading: false,
      pause: false
    }
  },
  mounted() {
    QrScanner.listCameras(true).then((devices: any) => {
      const back_cameras = devices.filter((device: any) => device.label.toLowerCase().includes('back'));
      if (back_cameras?.length) {
        this.devices = back_cameras;
      } else {
        this.devices = devices;
      }

      setTimeout(this.setScan, 100);
    });
  },
  beforeUnmount() {
    if (this.qrScanner) {
      this.qrScanner.stop();
      this.qrScanner.destroy();
    }
  },
  methods: {
    setScan() {
      if (this.video) {
        this.qrScanner = new QrScanner(
            this.video,
            (result: any) => {
              if (result && !this.scanLoading && !this.pause) {
                this.scanLoading = true;
                this.axios.post('scan', {scanned: result}).then((response: any) => {
                  if (response.data.equipment) {
                    this.$emit('action', response.data);
                    this.$emit('close');
                  }
                }).catch((error: any) => {
                  toastController
                      .create({
                        message: error.response.data.message,
                        duration: 2000,
                        color: "danger",
                      })
                }).finally(() => {
                  this.scanLoading = false;
                  this.pause = true;
                  setTimeout(() => {
                    this.pause = false;
                  }, 2000);
                });
              }
            },
        );

        if ((this.devices[0] as any)?.id) {
          this.qrScanner.setCamera('environment' ?? (this.devices[0] as any)?.id);
        }

        this.qrScanner.start();
      }
    }
  }
});
</script>

<style lang="scss" module>
.scanner-content {
  .scanner-video {
    overflow: hidden;
    width: 100%;
    height: calc(100% + 5em);
    margin-top: -5em;
    top: 0;
    left: 0;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    $squareSize: 240px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: $squareSize;
      height: $squareSize;
      margin-top: -.5 * $squareSize;
      margin-left: -.5 * $squareSize;
      border: 2px solid #fff;
      border-radius: 4px;
    }

    @keyframes blurAppear {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation: blurAppear 5s ease-out;
  }
}
</style>  