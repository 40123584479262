import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-key"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_PlanningStatus = _resolveComponent("PlanningStatus")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_PlanningSkeleton = _resolveComponent("PlanningSkeleton")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("planning.screen_title")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.loaded)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 0,
            ref: "list_container",
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              (_ctx.planning?.length)
                ? (_openBlock(), _createBlock(_component_ion_grid, {
                    key: 0,
                    fixed: true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_infinite_scroll, {
                                id: "infinite-scroll",
                                position: "top",
                                threshold: "100px",
                                onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadPrevPage($event)))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_infinite_scroll_content, { "loading-spinner": null })
                                ]),
                                _: 1
                              }),
                              _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['list-wrapper'])
                              }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planning, (item) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: item.uuid,
                                    class: _normalizeClass(_ctx.$style['list-item']),
                                    "data-key": item.uuid
                                  }, [
                                    _createElementVNode("div", {
                                      onClick: ($event: any) => (_ctx.$router.push('/planning/' + item.uuid))
                                    }, [
                                      _createElementVNode("p", {
                                        class: _normalizeClass(_ctx.$style['date'])
                                      }, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.moment(item.starts_at).format('LL')), 1)
                                      ], 2),
                                      _createElementVNode("h2", {
                                        class: _normalizeClass(_ctx.$style['name'])
                                      }, [
                                        _createElementVNode("span", null, _toDisplayString(item.display_name), 1)
                                      ], 2),
                                      (item.site?.name)
                                        ? (_openBlock(), _createElementBlock("p", {
                                            key: 0,
                                            class: _normalizeClass(_ctx.$style['site'])
                                          }, _toDisplayString(item.site.name), 3))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_PlanningStatus, { entry: item }, null, 8, ["entry"])
                                    ], 8, _hoisted_2)
                                  ], 10, _hoisted_1))
                                }), 128))
                              ], 2),
                              _createVNode(_component_ion_infinite_scroll, {
                                id: "infinite-scroll",
                                threshold: "100px",
                                onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadNextPage($event)))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_infinite_scroll_content)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['no-entry'])
                  }, [
                    _createVNode(_component_ion_text, { color: "medium" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t("planning.no_entry")), 1)
                      ]),
                      _: 1
                    })
                  ], 2))
            ]),
            _: 1
          }, 512))
        : (_openBlock(), _createBlock(_component_ion_content, {
            key: 1,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_grid, { fixed: true }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_PlanningSkeleton)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}