<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>
          {{ $t('forms.control_sheet.title') }}
        </ion-title>

      </ion-toolbar>
    </ion-header>

    <ion-content v-if="success">
      <div class="ion-padding">
        <h1>
          {{ $t('forms.control_sheet.success.title') }}
        </h1>
        <p>
          {{ $t('forms.control_sheet.success.description') }}
        </p>
      </div>
    </ion-content>

    <ion-content v-else>
      <form @submit="submit">
        <ion-list>
          <ion-item>
            <ion-input
                v-model="date"
                :max="maxDate"
                format="YYYY-MM-DD"
                label="Date de l'incident"

                labelPlacement="floating"
                required

                type="date"
            />
          </ion-item>

          <ion-item>
            <ion-select :value="control_level" interface="action-sheet" label="Niveau de l'incident"
                        labelPlacement="floating" @ionChange="control_level=$event.detail.value">
              <ion-select-option v-for="level in control_sheet?.levels??[]" :key="level" :value="level">
                {{ $t('control_sheets.levels.' + level) }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item>
            <ion-input
                v-model="last_name" label="Votre nom"
                labelPlacement="floating"
                placeholder="Dupont"

                required
            />
          </ion-item>

          <ion-item>
            <ion-input
                v-model="first_name" label="Votre prénom"
                labelPlacement="floating"
                placeholder="Jean"

                required
            />
          </ion-item>

          <ion-item>
            <ion-input
                v-model="user_last_name" label="Nom de l'utilisateur de la machine"
                labelPlacement="floating"
                placeholder="Dupont"

                required
            />
          </ion-item>

          <ion-item>
            <ion-input
                v-model="user_first_name" label="Prénom de l'utilisateur de la machine"
                labelPlacement="floating"
                placeholder="Jean"

                required
            />
          </ion-item>

          <ion-item v-if="!user">
            <ion-input
                v-model="email" label="Email"
                labelPlacement="floating"
                placeholder="me@mail.com"

                required
            />
          </ion-item>

          <ion-item>
            <ion-label position="stacked">
              Machine concernée
            </ion-label>

            <EquipmentSelectorButton
                @change="equipment=$event.value"
            />
          </ion-item>

          <ion-item>
            <ion-input
                v-model="hours"
                label="Heures"
                labelPlacement="floating"
                min="0"
                type="number"
            />
          </ion-item>

          <ion-item>
            <ion-input
                v-model="kms"
                label="Kms"
                labelPlacement="floating"
                min="0"
                type="number"

            />
          </ion-item>

          <ion-item>
            <ion-textarea
                v-model="description"
                label="Description"

                labelPlacement="floating"
                placeholder="Description de la panne ou de l'entretien à réaliser"

                required

                rows="7"
            />
          </ion-item>

          <ion-item>
            <ion-textarea
                v-model="cause"
                label="Cause de la panne"

                labelPlacement="floating"
                required

                rows="7"
            />
          </ion-item>


          <ion-item @click="open_site_selector=true">
            <ion-label position="stacked">
              Chantier où se trouve la machine
            </ion-label>

            <ion-button color="light" expand="full" @click="open_site_selector=true">
              <div>
                <ion-text v-if="!site?.name" color="medium">
                  {{ $t('forms.control_sheet.select_site') }}
                </ion-text>
                <span v-else>
                  <span v-if="site?.reference">
                    {{ site.reference }} —
      </span>
                      {{ text.truncate(site?.name, 36) }}

                    </span>
              </div>
            </ion-button>

            <SiteSelector :active="open_site_selector"
                          @change="site=$event.value"
                          @close="open_site_selector=false"/>
          </ion-item>

          <ion-item>
            <ion-input
                v-model="site_manager"
                label="Nom du responsable du chantier"

                labelPlacement="floating"
            />
          </ion-item>

          <ion-item v-if="0">
            <ion-label position="stacked">
              Pièces jointes
            </ion-label>

            <uploadable-file
                v-if="!files?.length"
                :value="[]"
                accept="image/*"
                capture="environment"
                multiple="true"
                @change="files=$event"
            ></uploadable-file>

            <files-preview
                v-else
                :files="files"
            ></files-preview>
          </ion-item>
        </ion-list>

        <div class="ion-padding">
          <ion-button :disabled="loading" color="success" expand="block" @click="submit">
            {{ $t('forms.control_sheet.submit') }}
          </ion-button>
        </div>
      </form>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  toastController
} from "@ionic/vue";

import { useUserStore } from "@/stores/user";
import SiteSelector from "@/components/data/selectors/SiteSelector.vue";
import text from "@/helpers/text";
import { Site } from "@/sites";
import UploadableFile from "@/components/ui/fields/UploadableFile.vue";
import FilesPreview from "@/components/data/files/FilesPreview.vue";
import { Equipment } from "@/equipments";
import EquipmentSelectorButton from "@/components/data/selectors/EquipmentSelectorButton.vue";
import moment from 'moment';
import { useControlSheetStore } from "@/stores/control_sheet";

export default defineComponent({
  components: {
    EquipmentSelectorButton,
    FilesPreview,
    UploadableFile,
    IonText, IonButton,
    IonItem,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonInput,
    IonTextarea, SiteSelector, IonLabel, IonSelect, IonSelectOption
  },
  setup() {
    const user = useUserStore();
    const control_sheet = useControlSheetStore();

    return {user, control_sheet, text};
  },
  emits: ['close'],
  data() {
    return {
      date: moment().format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),

      control_level: null as string | null,

      last_name: null as string | null,
      first_name: null as string | null,

      user_last_name: null as string | null,
      user_first_name: null as string | null,

      email: null as string | null,

      hours: null,
      kms: null,
      site: null as Site | null,
      equipment: null as Equipment | null,

      open_site_selector: false,

      description: null as string | null,
      cause: null as string | null,
      site_manager: null as string | null,

      files: [] as File[],

      loading: false,
      success: false,
    }
  },
  mounted() {
    document.title = this.$t('forms.control_sheet.title') + " — " + "Travexploit Portal";

    if (this.user) {
      this.setUserInfo();
    }
    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.setUserInfo();
    });
  }, methods: {
    setUserInfo() {
      this.last_name = this.user?.last_name ?? '';
      this.first_name = this.user?.first_name ?? '';
      this.email = this.user?.email ?? '';
    },
    submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.axios.post('/forms/control-sheet', {
        submitted_at: this.date,
        last_name: this.last_name,
        first_name: this.first_name,
        user_last_name: this.user_last_name,
        user_first_name: this.user_first_name,
        equipment: this.equipment,
        email: this.email ?? this.user.email,
        hours: this.hours,
        kms: this.kms,
        site: this.site,
        description: this.description,
        cause: this.cause,
        site_manager: this.site_manager,
        files: this.files,
        level: this.control_level,
      }).then(() => {
        this.success = true;

        setTimeout(() => {
          window.close();

          this.$emit('close');
          this.$router.go(-1);
        }, 3000);
      }).catch(async (err) => {
        await toastController.create({
          header: this.$t("toast.error.title"),
          message: err.response?.data?.message ?? err.message,
          color: "danger",
          duration: 3000,
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
});
</script>

<style lang="scss" module>
</style>