import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_ctx.files)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style['wrapper'])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file) => {
          return (_openBlock(), _createElementBlock("div", {
            key: file.file_name,
            class: _normalizeClass(_ctx.$style['file'])
          }, [
            _createVNode(_component_ion_card, {
              type: "button",
              onClick: _withModifiers(($event: any) => (_ctx.openFile(file)), ["prevent"])
            }, {
              default: _withCtx(() => [
                ( _ctx.preview_images[file.file_name])
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.$style['image'])
                    }, [
                      _createElementVNode("img", {
                        src: _ctx.preview_images[file.file_name]
                      }, null, 8, _hoisted_1)
                    ], 2))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(_ctx.$style['loader'])
                    }, [
                      _createVNode(_component_ion_spinner)
                    ], 2)),
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("files.types." + file.type)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ], 2))
        }), 128)),
        _createVNode(_component_ion_modal, {
          class: _normalizeClass(_ctx.$style['document-modal']),
          "is-open": _ctx.opened_document,
          onWillDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.opened_document=null))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_toolbar, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Document")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_buttons, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.opened_document=null;})
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('global.close')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.opened_document)
              ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("iframe", {
                      class: _normalizeClass(_ctx.$style['preview-frame']),
                      src: _ctx.opened_document
                    }, null, 10, _hoisted_2)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class", "is-open"])
      ], 2))
    : _createCommentVNode("", true)
}