<template>
  <ListItemSkeleton v-if="!loaded"/>
  <div v-else-if="!planning?.length">
    <ion-text class="ion-text-center" color="medium">
      <p>
        {{ $t("planning.no_result") }}
      </p>
    </ion-text>
  </div>
  <div v-else>
    <ion-refresher slot="fixed" @ionRefresh="reload">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <list-item
        v-for="planning_entry in planning"
        :key="planning_entry.uuid"
        :entry="planning_entry"
    />

    <ion-infinite-scroll
        id="infinite-scroll"
        threshold="100px"
        @ionInfinite="loadNextPage($event)"
    >
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PlanningEntry } from "@/planning";
import ListItemSkeleton from "@/components/skeletons/ListItemSkeleton.vue";
import {
  InfiniteScrollCustomEvent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonText
} from "@ionic/vue";
import ListItem from "@/components/data/planning/ListItem.vue";
import moment from "moment";

export default defineComponent({
  components: {
    IonInfiniteScrollContent,
    IonInfiniteScroll,
    IonRefresherContent,
    ListItem,
    IonRefresher,
    IonText, ListItemSkeleton
  },
  props: ['site'],
  data() {
    return {
      loaded: false as boolean,
      planning: [] as PlanningEntry[],
      page: 1 as number,
    };
  },
  setup() {
    return {moment}
  },
  methods: {
    load(callback?: (data: any) => void) {
      const url = '/planning';
      const params = {
        site: this.site?.uuid,
        from_date: moment().subtract((this.page - 1), 'month').startOf('month').format('YYYY-MM-DD'),
        to_date: moment().subtract((this.page - 1), 'month').endOf('month').format('YYYY-MM-DD'),
      };

      this.axios.get(url, {params}).then((response) => {
        response.data?.data.forEach((planning_entry: PlanningEntry) => {
          this.planning.push(planning_entry);
        });

        this.loaded = true;
        this.page = this.page + 1;

        if (callback) {
          callback(response.data);
        }
      });
    },

    reload(event: CustomEvent) {
      this.page = 1;
      this.load();
      (event?.target as any)?.complete();
    },

    loadNextPage(e: InfiniteScrollCustomEvent) {
      this.load(() => {
        e.target.complete();
      });
    },
  },
  mounted() {
    this.load();
  }
});
</script>