import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_EquipmentSelectorButton = _resolveComponent("EquipmentSelectorButton")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_SiteSelector = _resolveComponent("SiteSelector")!
  const _component_uploadable_file = _resolveComponent("uploadable-file")!
  const _component_files_preview = _resolveComponent("files-preview")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('forms.control_sheet.title')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.success)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h1", null, _toDisplayString(_ctx.$t('forms.control_sheet.success.title')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('forms.control_sheet.success.description')), 1)
              ])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
            default: _withCtx(() => [
              _createElementVNode("form", {
                onSubmit: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
              }, [
                _createVNode(_component_ion_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          modelValue: _ctx.date,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
                          max: _ctx.maxDate,
                          format: "YYYY-MM-DD",
                          label: "Date de l'incident",
                          labelPlacement: "floating",
                          required: "",
                          type: "date"
                        }, null, 8, ["modelValue", "max"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select, {
                          value: _ctx.control_level,
                          interface: "action-sheet",
                          label: "Niveau de l'incident",
                          labelPlacement: "floating",
                          onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.control_level=$event.detail.value))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.control_sheet?.levels??[], (level) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: level,
                                value: level
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('control_sheets.levels.' + level)), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          modelValue: _ctx.last_name,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.last_name) = $event)),
                          label: "Votre nom",
                          labelPlacement: "floating",
                          placeholder: "Dupont",
                          required: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          modelValue: _ctx.first_name,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.first_name) = $event)),
                          label: "Votre prénom",
                          labelPlacement: "floating",
                          placeholder: "Jean",
                          required: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          modelValue: _ctx.user_last_name,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user_last_name) = $event)),
                          label: "Nom de l'utilisateur de la machine",
                          labelPlacement: "floating",
                          placeholder: "Dupont",
                          required: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          modelValue: _ctx.user_first_name,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user_first_name) = $event)),
                          label: "Prénom de l'utilisateur de la machine",
                          labelPlacement: "floating",
                          placeholder: "Jean",
                          required: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    (!_ctx.user)
                      ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              modelValue: _ctx.email,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.email) = $event)),
                              label: "Email",
                              labelPlacement: "floating",
                              placeholder: "me@mail.com",
                              required: ""
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Machine concernée ")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_EquipmentSelectorButton, {
                          onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.equipment=$event.value))
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          modelValue: _ctx.hours,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.hours) = $event)),
                          label: "Heures",
                          labelPlacement: "floating",
                          min: "0",
                          type: "number"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          modelValue: _ctx.kms,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kms) = $event)),
                          label: "Kms",
                          labelPlacement: "floating",
                          min: "0",
                          type: "number"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_textarea, {
                          modelValue: _ctx.description,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.description) = $event)),
                          label: "Description",
                          labelPlacement: "floating",
                          placeholder: "Description de la panne ou de l'entretien à réaliser",
                          required: "",
                          rows: "7"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_textarea, {
                          modelValue: _ctx.cause,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.cause) = $event)),
                          label: "Cause de la panne",
                          labelPlacement: "floating",
                          required: "",
                          rows: "7"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, {
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.open_site_selector=true))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Chantier où se trouve la machine ")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          color: "light",
                          expand: "full",
                          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.open_site_selector=true))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              (!_ctx.site?.name)
                                ? (_openBlock(), _createBlock(_component_ion_text, {
                                    key: 0,
                                    color: "medium"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('forms.control_sheet.select_site')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createElementBlock("span", _hoisted_2, [
                                    (_ctx.site?.reference)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.site.reference) + " — ", 1))
                                      : _createCommentVNode("", true),
                                    _createTextVNode(" " + _toDisplayString(_ctx.text.truncate(_ctx.site?.name, 36)), 1)
                                  ]))
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_SiteSelector, {
                          active: _ctx.open_site_selector,
                          onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.site=$event.value)),
                          onClose: _cache[14] || (_cache[14] = ($event: any) => (_ctx.open_site_selector=false))
                        }, null, 8, ["active"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          modelValue: _ctx.site_manager,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.site_manager) = $event)),
                          label: "Nom du responsable du chantier",
                          labelPlacement: "floating"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    (0)
                      ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { position: "stacked" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Pièces jointes ")
                              ]),
                              _: 1
                            }),
                            (!_ctx.files?.length)
                              ? (_openBlock(), _createBlock(_component_uploadable_file, {
                                  key: 0,
                                  value: [],
                                  accept: "image/*",
                                  capture: "environment",
                                  multiple: "true",
                                  onChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.files=$event))
                                }))
                              : (_openBlock(), _createBlock(_component_files_preview, {
                                  key: 1,
                                  files: _ctx.files
                                }, null, 8, ["files"]))
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_ion_button, {
                    disabled: _ctx.loading,
                    color: "success",
                    expand: "block",
                    onClick: _ctx.submit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('forms.control_sheet.submit')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ])
              ], 32)
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}