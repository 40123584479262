<template>
  <ion-page>
    <ion-header>
      <ion-toolbar
          :color="isMobile?'light':'primary'"
          :style="!isMobile?null:{
            '--border-width':0
          }">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :class="$style['wrapper']" color="light">
      <div v-if="user?.is_logged_in">

        <div class="ion-padding">
          <ion-text>
            <h1 :class="$style['title']">
              <span>{{ $t("home.welcome") }}<span v-if="user?.display_name">,</span></span>
              <span v-if="user?.display_name">{{ user?.display_name }}.</span>
            </h1>
          </ion-text>
        </div>

        <IonGrid v-if="stats?.length">
          <IonRow>
            <IonCol v-for="stat in stats" :key="stat.name" size=12 size-lg="4" size-md="6" size-sm="6">
              <StatItem :stat="stat"/>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import { defineComponent } from "vue";

import { useUserStore } from "@/stores/user";
import { Stats } from "@/stats";
import StatItem from "@/components/data/dashboard/StatItem.vue";

export default defineComponent({
  setup() {
    const user = useUserStore();

    return {
      user
    };
  },
  data() {
    return {
      loading: true,
      stats: [] as Stats,
      isMobile: window.innerWidth < 768
    }
  },
  mounted() {
    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });
  },
  methods: {
    load() {
      this.axios.get("/user/stats").then((response: { data: Stats }) => {
        this.stats = response.data;
        this.loading = false;
      });
    }
  },
  components: {
    IonHeader, IonToolbar, IonTitle, IonButtons,
    StatItem,
    IonContent,
    IonGrid,
    IonRow,
    IonCol, IonMenuButton,
    IonText, IonPage
  },
});
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  > span {
    display: block;
  }
}
</style>