<template>
  <div :class="{
    [$style['message']]:true,
    [$style['message--from-me']]: from_me,
    [$style['message--same-user']]: message.same_user,
  }">
    <p
        v-if="!message.same_user" :class="$style['message__user']"> {{ item.user?.display_name }}</p>

    <div v-if="item.deleted" :class="$style['message__content']+' '+$style['message__content--deleted']">
      {{ $t('messages.deleted') }}
    </div>
    <div v-else :class="$style['message__content']" v-html="item.message"></div>

    <p :class="$style['message__datetime']">
      {{ moment(item.created_at).fromNow() }}
    </p>
  </div>
</template>

<style lang="scss" module>
.message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 2em;

  &--same-user {
    margin-top: .5em;
  }

  &__content {
    background: white;
    padding: .5em 1em;
    border-radius: 1em;
    max-width: 60%;

    &--deleted {
      font-style: italic;
      opacity: .7;
    }

    .message--from-me & {
      background: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
    }
  }

  &__user, &__datetime {
    font-size: .9em;
    opacity: .5;
    margin: 0;
    padding: 0;
  }

  &__user {
    font-weight: bold;
  }

  &--from-me {
    align-items: flex-end;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { Message } from "@/messages";
import { useUserStore } from "@/stores/user";
import moment from "moment";

export default defineComponent({
  props: ['message'],
  setup() {
    const user = useUserStore();
    return {user, moment};
  },
  computed: {
    item() {
      return this.message as Message;
    },
    from_me() {
      return this.item.user?.uuid === this.user?.uuid;
    }
  }
});
</script>