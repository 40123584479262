<template>
  <ion-row :class="{
    [$style['wrapper']]:true,[$style['is-'+side]]:$style['is-'+side]
  }">
    <ion-col :class="'ion-no-padding'" size-lg="7" size-xl="8" size-xs="12">
      <slot name="content"/>
    </ion-col>
    <ion-col :class="$style['sidebar']" size-lg="5" size-xl="4" size-xs="12">
      <div :class="$style['sidebar-inner']">
        <slot name="sidebar"/>
      </div>
    </ion-col>
  </ion-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonCol, IonRow } from "@ionic/vue";

export default defineComponent({
  components: {IonRow, IonCol},
  props: ['side']
});
</script>


<style lang="scss" module>
.sidebar {
  padding: 0;
  flex-shrink: 1 !important;

  position: relative;

  @media (min-width: 990px) {
    background: var(--ion-color-light);
    margin-top: 0;
    border-left: 1px solid var(--ion-color-light);
  }

  .sidebar-inner {
    position: sticky;
    top: 0;
  }
}

.wrapper {
  min-height: 100%;

  &.is-left {
    .sidebar {
      order: -1;
      @media (min-width: 990px) {
        background: rgba(var(--ion-color-light-rgb), .5);
        border-right: 1px solid var(--ion-color-light);
        border-left: none;
      }
    }
  }
}
</style>