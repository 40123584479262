<template>
  <div :class="$style['wrapper']">
    <IonSpinner/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IonSpinner } from '@ionic/vue'


export default defineComponent({
  components: {IonSpinner}
})
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 90vh;
}
</style>