import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SheetStatus = _resolveComponent("SheetStatus")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    button: true,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/control-sheets/' + _ctx.sheet.uuid)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['inner'])
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.sheet.display_name), 1),
          ( _ctx.sheet.equipment?.name)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.sheet.equipment?.name), 1))
            : _createCommentVNode("", true),
          ( _ctx.sheet.site?.name)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.sheet.site?.name), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style['right'])
        }, [
          _createVNode(_component_SheetStatus, { sheet: _ctx.sheet }, null, 8, ["sheet"]),
          (!_ctx.sheet?.sync_needed)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass(_ctx.$style['date'])
              }, _toDisplayString(_ctx.moment(_ctx.sheet.submitted_at) > _ctx.moment().subtract(14, 'days') ? _ctx.moment(_ctx.sheet.submitted_at).fromNow() : _ctx.moment(_ctx.sheet.submitted_at).format('LL')), 3))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['user-pictures'])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet.roles, (role) => {
              return (_openBlock(), _createElementBlock("img", {
                key: role.user.uuid,
                src: role.user.profile_picture,
                loading: "lazy"
              }, null, 8, _hoisted_3))
            }), 128))
          ], 2)
        ], 2)
      ], 2)
    ]),
    _: 1
  }))
}