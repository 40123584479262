import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_ctx.order?.sync_needed)
    ? (_openBlock(), _createBlock(_component_ion_spinner, { key: 0 }))
    : (_openBlock(), _createBlock(_component_ion_text, {
        key: 1,
        class: _normalizeClass(_ctx.$style['status']),
        color: 
                        _ctx.order?.status?.includes('validation')
                          ? 'warning'
                          : _ctx.order?.status === 'done'
                          ? 'success'
                          : _ctx.order?.status === 'refused'
                          ? 'danger'
                          : _ctx.order?.status === 'draft'
                          ? 'medium'
                          : 'dark'
                      
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("orders.status." + (_ctx.order?.status ?? "unknown"))), 1)
        ]),
        _: 1
      }, 8, ["class", "color"]))
}