<template>
  <ion-item :button="true" @click="$router.push('/control-sheets/' + sheet.uuid)">


    <div :class="$style['inner']">
      <div>
        <h4>{{ sheet.display_name }}</h4>
        <p v-if=" sheet.equipment?.name">{{ sheet.equipment?.name }}</p>
        <p v-if=" sheet.site?.name">{{ sheet.site?.name }}</p>

      </div>
      <div :class="$style['right']">
        <SheetStatus :sheet="sheet"/>
        <p v-if="!sheet?.sync_needed" :class="$style['date']">
          {{
            moment(sheet.submitted_at) > moment().subtract(14, 'days') ? moment(sheet.submitted_at).fromNow() : moment(sheet.submitted_at).format('LL')
          }}
        </p>

        <div :class="$style['user-pictures']">
          <img v-for="role in sheet.roles" :key="role.user.uuid" :src="role.user.profile_picture" loading="lazy"/>
        </div>
      </div>
    </div>
  </ion-item>

</template>
<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";
import SheetStatus from "@/components/data/control-sheets/SheetStatus.vue";

export default defineComponent({
  components: {SheetStatus},
  props: ['sheet'],
  setup() {
    return {moment}
  }
});
</script>


<style lang="scss" module>
.inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  font-size: .9em;

  @media(max-width: 990px) {
    font-size: .8em;
  }

  @media(max-width: 700px) {
    flex-direction: column
  }

  h4, p {
    padding: 0;
    margin: 0;
  }

  p {
    margin-top: .25em;
    color: var(--ion-color-medium);
  }

  .right {
    display: flex;
    align-items: center;

    @media(min-width: 701px) {
      flex-direction: column;
      align-items: flex-end;
    }

    @media(max-width: 700px) {
      .date {
        &:before {
          content: '—';
          margin: 0 .5em;
          margin-top: 0;
        }
      }
    }
  }
}

.user-pictures {
  @media(max-width: 700px) {
    display: none;
  }

  $size: 2em;

  margin-top: 1em;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  justify-content: flex-end;

  margin-left: $size*.2;

  img {
    width: $size;
    height: $size;
    border-radius: 50%;
    margin-left: -$size*.25;
    object-fit: cover;
    border: $size*.05 solid var(--background);
  }
}
</style>