import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListItemSkeleton = _resolveComponent("ListItemSkeleton")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_list_item = _resolveComponent("list-item")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!

  return (!_ctx.loaded)
    ? (_openBlock(), _createBlock(_component_ListItemSkeleton, { key: 0 }))
    : (!_ctx.planning?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_text, {
            class: "ion-text-center",
            color: "medium"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("planning.no_result")), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _ctx.reload
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }, 8, ["onIonRefresh"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planning, (planning_entry) => {
            return (_openBlock(), _createBlock(_component_list_item, {
              key: planning_entry.uuid,
              entry: planning_entry
            }, null, 8, ["entry"]))
          }), 128)),
          _createVNode(_component_ion_infinite_scroll, {
            id: "infinite-scroll",
            threshold: "100px",
            onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadNextPage($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content)
            ]),
            _: 1
          })
        ]))
}