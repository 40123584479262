import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_router_outlet),
      _createVNode(_component_ion_modal, {
        canDismiss: false,
        isOpen: 
        !_ctx.asGuest && _ctx.user.loaded && (_ctx.should_re_login || !_ctx.user.is_logged_in)
      
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_content, { class: "ion-padding" }, {
            default: _withCtx(() => [
              _createVNode(_component_LoginForm)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["isOpen"])
    ]),
    _: 1
  }))
}