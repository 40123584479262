import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withKeys as _withKeys, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { class: "ion-padding" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "ion-padding" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 2,
  class: "ion-padding"
}
const _hoisted_12 = {
  key: 3,
  class: "ion-padding"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = {
  key: 4,
  class: "ion-padding"
}
const _hoisted_16 = {
  key: 1,
  class: "ion-padding"
}
const _hoisted_17 = { slot: "content" }
const _hoisted_18 = { slot: "content" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { slot: "content" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { slot: "content" }
const _hoisted_25 = { slot: "content" }
const _hoisted_26 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ProductsTable = _resolveComponent("ProductsTable")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_FilesPreview = _resolveComponent("FilesPreview")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_SiteBlock = _resolveComponent("SiteBlock")!
  const _component_OrderSkeleton = _resolveComponent("OrderSkeleton")!
  const _component_OrderStatus = _resolveComponent("OrderStatus")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ContentSidebarWrapper = _resolveComponent("ContentSidebarWrapper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_FolderSelector = _resolveComponent("FolderSelector")!
  const _component_related_messages = _resolveComponent("related-messages")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    text: _ctx.$t('orders.back_button_label'),
                    "default-href": "/orders"
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.order?.display_name), 1)
                ]),
                _: 1
              }),
              (_ctx.order?.status !== 'done' && _ctx.order?.status !== 'refused')
                ? (_openBlock(), _createBlock(_component_ion_buttons, {
                    key: 0,
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        disabled: _ctx.save_loading || !_ctx.loaded || _ctx.order?.sync_needed,
                        onClick: _ctx.presentActionSheet
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("global.actions")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ContentSidebarWrapper, null, {
            content: _withCtx(() => [
              (_ctx.loaded)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (_ctx.show_summary)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(_ctx.$style['email-summary'])
                        }, [
                          (0)
                            ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.$t("orders.fields.reference")) + ": " + _toDisplayString(_ctx.order?.reference), 1))
                            : _createCommentVNode("", true),
                          (_ctx.order?.provider)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style['label'])
                                }, _toDisplayString(_ctx.$t("orders.fields.provider.name")), 3),
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style['value'])
                                }, _toDisplayString(_ctx.order?.provider?.name), 3)
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.order?.site)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style['label'])
                                }, _toDisplayString(_ctx.$t("orders.fields.site")), 3),
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style['value'])
                                }, _toDisplayString(_ctx.order?.site?.name), 3)
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.issuer)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style['label'])
                                }, _toDisplayString(_ctx.$t("users.roles.issuer")), 3),
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style['value'])
                                }, _toDisplayString(_ctx.issuer?.display_name), 3)
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.order?.created_at)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style['label'])
                                }, _toDisplayString(_ctx.$t("orders.fields.created_at")), 3),
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.$style['value'])
                                }, _toDisplayString(_ctx.moment(_ctx.order?.created_at).format("LL")), 3)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style['label'])
                            }, _toDisplayString(_ctx.$t("orders.products_table.labels.total")), 3),
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style['value'])
                            }, _toDisplayString(_ctx.text.formatPrice(_ctx.order_total)), 3)
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style['label'])
                            }, _toDisplayString(_ctx.$t("orders.fields.company")), 3),
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style['value'])
                            }, _toDisplayString(_ctx.user?.current_organization?.name), 3)
                          ])
                        ], 2))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ProductsTable, {
                      class: _normalizeClass(_ctx.$style['products-table']),
                      editable: 
                !_ctx.save_loading &&
                _ctx.order?.status === 'draft' &&
                !_ctx.order?.sync_needed
              ,
                      order: _ctx.order,
                      scheme: _ctx.show_summary ? 'medium' : 'primary',
                      onChange: _ctx.itemsChanged
                    }, null, 8, ["class", "editable", "order", "scheme", "onChange"]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['comment-wrapper'])
                      }, [
                        _createVNode(_component_ion_textarea, {
                          ref: "delivery_address_field",
                          modelValue: _ctx.order_delivery_address,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.order_delivery_address) = $event)),
                          "auto-grow": true,
                          class: _normalizeClass(_ctx.$style['validation-comment']),
                          disabled: 
                    _ctx.current_user_validation?.approved ||
                    _ctx.order?.status === 'refused'
                  ,
                          placeholder: _ctx.$t('orders.delivery_address_field.placeholder'),
                          spellcheck: true,
                          autocapitalize: "on",
                          onIonBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.delivery_address_autocompose = '')),
                          onKeyup: _ctx.deliveryAddressChanged,
                          onKeydown: [
                            _withKeys(_ctx.validateDeliveryAddressAutoCompose, ["enter"]),
                            _withKeys(_ctx.validateDeliveryAddressAutoCompose, ["tab"])
                          ]
                        }, null, 8, ["modelValue", "class", "disabled", "placeholder", "onKeyup", "onKeydown"]),
                        (_ctx.delivery_address_autocompose)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(_ctx.$style['auto-compose']),
                              innerHTML: 
                    (
                      _ctx.order_delivery_address + _ctx.delivery_address_autocompose
                    ).replaceAll('\n', '<br />')
                  
                            }, null, 10, _hoisted_8))
                          : _createCommentVNode("", true)
                      ], 2),
                      (_ctx.order?.status !== 'done' && _ctx.order?.status !== 'refused')
                        ? _withDirectives((_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['comment-actions'])
                          }, [
                            _createVNode(_component_ion_button, {
                              disabled: !_ctx.can_save,
                              color: "success",
                              onClick: _ctx.save_delivery_address
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("global.save")), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled", "onClick"])
                          ], 2)), [
                            [_vShow, 0]
                          ])
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['comment-wrapper'])
                      }, [
                        _createVNode(_component_ion_textarea, {
                          ref: "comment_field",
                          modelValue: _ctx.order_comment,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.order_comment) = $event)),
                          "auto-grow": true,
                          class: _normalizeClass(_ctx.$style['validation-comment']),
                          disabled: 
                    _ctx.current_user_validation?.approved ||
                    _ctx.order?.status === 'refused'
                  ,
                          placeholder: _ctx.$t('orders.comment_field.placeholder'),
                          spellcheck: true,
                          autocapitalize: "on",
                          onIonBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.comment_autocompose = '')),
                          onKeyup: _ctx.commentChanged,
                          onKeydown: [
                            _withKeys(_ctx.validateAutoCompose, ["enter"]),
                            _withKeys(_ctx.validateAutoCompose, ["tab"])
                          ]
                        }, null, 8, ["modelValue", "class", "disabled", "placeholder", "onKeyup", "onKeydown"]),
                        (_ctx.comment_autocompose)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(_ctx.$style['auto-compose']),
                              innerHTML: 
                    (_ctx.order_comment + _ctx.comment_autocompose).replaceAll(
                      '\n',
                      '<br />'
                    )
                  
                            }, null, 10, _hoisted_10))
                          : _createCommentVNode("", true),
                        (_ctx.order?.status !== 'done' && _ctx.order?.status !== 'refused')
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: _normalizeClass(_ctx.$style['comment-toolbar'])
                            }, [
                              _createVNode(_component_ion_button, {
                                id: "defined-comments-trigger",
                                disabled: 
                      _ctx.current_user_validation?.approved || _ctx.order?.sync_needed
                    ,
                                fill: "clear",
                                shape: "round"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: _ctx.arrowUndo }, null, 8, ["icon"])
                                ]),
                                _: 1
                              }, 8, ["disabled"]),
                              _createVNode(_component_ion_popover, {
                                trigger: "defined-comments-trigger",
                                "trigger-action": "click"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(
                        _ctx.$t('orders.defined_comments.values')
                      ), (comment, index) => {
                                    return (_openBlock(), _createBlock(_component_ion_item, {
                                      key: index,
                                      button: true,
                                      type: "button",
                                      onClick: ($event: any) => (_ctx.order_comment = comment)
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(comment), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ], 2))
                          : _createCommentVNode("", true)
                      ], 2),
                      (_ctx.order?.status !== 'done' && _ctx.order?.status !== 'refused')
                        ? _withDirectives((_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['comment-actions'])
                          }, [
                            _createVNode(_component_ion_button, {
                              disabled: !_ctx.can_save,
                              color: "success",
                              onClick: _ctx.save_comment
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("global.save")), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled", "onClick"])
                          ], 2)), [
                            [_vShow, 0]
                          ])
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.order?.files)
                      ? (_openBlock(), _createBlock(_component_FilesPreview, {
                          key: 1,
                          files: _ctx.order?.files,
                          class: "ion-padding"
                        }, null, 8, ["files"]))
                      : _createCommentVNode("", true),
                    (0 && !_ctx.current_user_validation?.approved)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_ion_textarea, {
                            modelValue: _ctx.validation_comment,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.validation_comment) = $event)),
                            class: _normalizeClass(_ctx.$style['validation-comment']),
                            placeholder: 
                  _ctx.current_user_validation?.comment ??
                  _ctx.$t('orders.validation.comment_placeholder')
                ,
                            spellcheck: true,
                            autocapitalize: "on"
                          }, null, 8, ["modelValue", "class", "placeholder"])
                        ]))
                      : _createCommentVNode("", true),
                    (0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createVNode(_component_ion_button, {
                            disabled: 
                  _ctx.current_user_validation?.approved ||
                  _ctx.save_loading ||
                  _ctx.order?.sync_needed
                ,
                            color: "success",
                            expand: "block",
                            fill: "solid",
                            size: "large",
                            onClick: _ctx.approveOrder
                          }, {
                            default: _withCtx(() => [
                              (_ctx.save_loading)
                                ? (_openBlock(), _createBlock(_component_ion_spinner, { key: 0 }))
                                : (!_ctx.current_user_validation?.approved)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t("orders.validation.validate_button_label")), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t("orders.validation.validated_button_label")), 1))
                            ]),
                            _: 1
                          }, 8, ["disabled", "onClick"])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.order?.site)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createVNode(_component_SiteBlock, {
                            site: _ctx.order?.site
                          }, null, 8, ["site"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createVNode(_component_OrderSkeleton)
                  ]))
            ]),
            sidebar: _withCtx(() => [
              _createVNode(_component_ion_accordion_group, {
                disabled: !_ctx.loaded,
                multiple: true,
                value: 
              _ctx.order?.status?.includes('validation')
                ? ['informations', 'validation']
                : ['informations']
            
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_accordion, { value: "informations" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        slot: "header",
                        color: "light"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("orders.tabs.informations")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.reference")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.order?.reference), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.status")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_OrderStatus, { order: _ctx.order }, null, 8, ["order"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.folder")), 1)
                              ]),
                              _: 1
                            }),
                            (_ctx.order)
                              ? (_openBlock(), _createBlock(_component_ion_label, {
                                  key: 0,
                                  slot: "end"
                                }, {
                                  default: _withCtx(() => [
                                    (_ctx.last_folder)
                                      ? (_openBlock(), _createElementBlock("span", {
                                          key: 0,
                                          onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.folder_modal = true), ["prevent","stop"]))
                                        }, _toDisplayString(_ctx.$t("orders.folder_labels." + _ctx.last_folder)), 1))
                                      : (_openBlock(), _createBlock(_component_ion_button, {
                                          key: 1,
                                          color: "light",
                                          onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.folder_modal = true), ["prevent","stop"]))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t("global.select")), 1)
                                          ]),
                                          _: 1
                                        }))
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.created_at")), 1)
                              ]),
                              _: 1
                            }),
                            (_ctx.order)
                              ? (_openBlock(), _createBlock(_component_ion_label, {
                                  key: 0,
                                  slot: "end"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.moment(_ctx.order?.created_at) > _ctx.moment().subtract(5, "days")
                        ? _ctx.moment(_ctx.order?.created_at).fromNow()
                        : _ctx.moment(_ctx.order?.created_at).format("LLL")), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        (_ctx.order?.created_at !== _ctx.order?.updated_at)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 0,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.updated_at")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.moment(_ctx.order?.updated_at) > _ctx.moment().subtract(5, "days")
                        ? _ctx.moment(_ctx.order?.updated_at).fromNow()
                        : _ctx.moment(_ctx.order?.updated_at).format("LLL")), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  (_ctx.order?.validations?.length)
                    ? (_openBlock(), _createBlock(_component_ion_accordion, {
                        key: 0,
                        value: "validation"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            slot: "header",
                            color: "light"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("orders.tabs.validation")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_18, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order?.validations, (validation) => {
                              return (_openBlock(), _createBlock(_component_ion_item, {
                                key: validation.user.uuid,
                                lines: "none"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_avatar, { slot: "start" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_img, {
                                        src: validation.user.profile_picture
                                      }, null, 8, ["src"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      (_ctx.user.uuid === validation.user.uuid)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.$t("orders.fields.validation.you")), 1))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(validation.user.display_name), 1))
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_label, { slot: "end" }, {
                                    default: _withCtx(() => [
                                      (validation.approved)
                                        ? (_openBlock(), _createBlock(_component_ion_text, {
                                            key: 0,
                                            color: "success"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                                            ]),
                                            _: 1
                                          }))
                                        : (validation.refused)
                                          ? (_openBlock(), _createBlock(_component_ion_text, {
                                              key: 1,
                                              color: "danger"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                                              ]),
                                              _: 1
                                            }))
                                          : (_openBlock(), _createBlock(_component_ion_text, {
                                              key: 2,
                                              color: "warning"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, { icon: _ctx.hourglassOutline }, null, 8, ["icon"])
                                              ]),
                                              _: 1
                                            }))
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_accordion, { value: "roles" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        slot: "header",
                        color: "light"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("orders.tabs.roles")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_21, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
                    ...(_ctx.order?.roles ?? []),
                    ...(_ctx.order?.site?.roles ?? []),
                  ], (role) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: role.user.uuid,
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_avatar, { slot: "start" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_img, {
                                    src: role.user.profile_picture
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  (_ctx.user.uuid === role.user.uuid)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$t("orders.fields.validation.you")), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(role.user.display_name), 1))
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, { slot: "end" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_text, { color: "medium" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("users.roles." + role.role)), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }),
                  (_ctx.commented_validations?.length)
                    ? (_openBlock(), _createBlock(_component_ion_accordion, {
                        key: 1,
                        value: "comments"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            slot: "header",
                            color: "light"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("orders.tabs.comments")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_24, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commented_validations, (validation) => {
                              return (_openBlock(), _createBlock(_component_ion_item, {
                                key: validation.user.uuid,
                                lines: "none"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_chip, { color: "medium" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, { icon: _ctx.personCircle }, null, 8, ["icon"]),
                                      (_ctx.user.uuid === validation.user.uuid)
                                        ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.validation.you")), 1)
                                            ]),
                                            _: 1
                                          }))
                                        : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(validation.user.display_name), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createTextVNode(" " + _toDisplayString(validation.comment), 1)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_accordion, { value: "provider" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        slot: "header",
                        color: "light"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("orders.tabs.provider")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_25, [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.provider.name")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.order?.provider?.name), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.provider.email")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  innerHTML: 
                        _ctx.order?.provider?.recipients
                          ?.map((item) => item.email)
                          .join('<br /> ')
                      
                                }, null, 8, _hoisted_26)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.provider.iban_number")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.order?.provider?.iban_number), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.provider.vat_number")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.order?.provider?.vat_number), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("orders.fields.provider.billing_address")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.order?.provider?.billing_address?.number) + " " + _toDisplayString(_ctx.order?.provider?.billing_address?.street) + ", " + _toDisplayString(_ctx.order?.provider?.billing_address?.zip) + " " + _toDisplayString(_ctx.order?.provider?.billing_address?.city) + ", " + _toDisplayString(_ctx.order?.provider?.billing_address?.country), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["disabled", "value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.order)
        ? (_openBlock(), _createBlock(_component_FolderSelector, {
            key: 0,
            active: _ctx.folder_modal,
            onChange: _cache[7] || (_cache[7] = 
        ($event) => {
          _ctx.order.folder = $event.value;
          _ctx.save();
        }
      ),
            onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.folder_modal = false))
          }, null, 8, ["active"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_modal, {
        "backdrop-dismiss": true,
        "can-dismiss": true,
        "is-open": _ctx.discussion_modal,
        onWillDismiss: _cache[10] || (_cache[10] = ($event: any) => (_ctx.discussion_modal = false))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.discussion_modal = false))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("global.close")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_title)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              (_ctx.discussion_modal && _ctx.order?.uuid)
                ? (_openBlock(), _createBlock(_component_related_messages, {
                    key: 0,
                    related: _ctx.order?.uuid
                  }, null, 8, ["related"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createVNode(_component_ion_fab, {
        slot: "fixed",
        horizontal: "end",
        vertical: "bottom"
      }, {
        default: _withCtx(() => [
          (
          _ctx.order?.status !== 'done' &&
          _ctx.order?.status !== 'refused' &&
          (_ctx.save_loading || !_ctx.loaded)
        )
            ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                key: 0,
                disabled: true,
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_spinner)
                ]),
                _: 1
              }))
            : (_ctx.order?.status !== 'done' && _ctx.order?.status !== 'refused')
              ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                  key: 1,
                  disabled: _ctx.save_loading || !_ctx.loaded || _ctx.order?.sync_needed,
                  color: "primary",
                  onClick: _ctx.presentActionSheet
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.caretUp }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]))
              : _createCommentVNode("", true),
          (_ctx.loaded)
            ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                key: 2,
                color: "dark",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.discussion_modal = true))
              }, {
                default: _withCtx(() => [
                  (_ctx.order?.messages_count)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 0,
                        color: "dark",
                        style: {"background":"none","z-index":"3","position":"absolute"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.order?.messages_count), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_icon, { icon: _ctx.chatbubbleOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}