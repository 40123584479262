import { defineStore } from "pinia";

declare type  File = string;
declare type BreakDuration = number;

export const usePlanningStore = defineStore("planning", {
    state: () => {
        return {
            files: [] as File[],
            required_files: [] as File[],
            break_durations: [] as BreakDuration[],
        }
    }
});
