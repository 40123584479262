import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_OrdersSkeleton = _resolveComponent("OrdersSkeleton")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_list_item = _resolveComponent("list-item")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_SiteSelector = _resolveComponent("SiteSelector")!
  const _component_FolderSelector = _resolveComponent("FolderSelector")!
  const _component_ContentSidebarWrapper = _resolveComponent("ContentSidebarWrapper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("orders.screen_title")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "secondary" }, {
                default: _withCtx(() => [
                  (0)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        text: _ctx.$t('orders.new_button_label'),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/orders/new')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("orders.new_order_label")) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["text"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_button, {
                    text: _ctx.$t('orders.new_button_label'),
                    onClick: _ctx.reload
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.refreshIcon }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["text", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.is_mobile)
            ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                key: 0,
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_searchbar, {
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                    placeholder: _ctx.$t('orders.search_placeholder')
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ContentSidebarWrapper, { side: "left" }, {
            content: _withCtx(() => [
              (!_ctx.loaded)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_OrdersSkeleton)
                  ]))
                : (_ctx.orders.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ion_refresher, {
                        slot: "fixed",
                        onIonRefresh: _ctx.reloadUp
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_refresher_content)
                        ]),
                        _: 1
                      }, 8, ["onIonRefresh"]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
                        return (_openBlock(), _createBlock(_component_list_item, {
                          key: order.uuid,
                          order: order
                        }, null, 8, ["order"]))
                      }), 128)),
                      _createVNode(_component_ion_infinite_scroll, {
                        id: "infinite-scroll",
                        threshold: "500px",
                        onIonInfinite: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadNextPage($event)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_infinite_scroll_content)
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: _normalizeClass(_ctx.$style['no-order'])
                    }, [
                      _createVNode(_component_ion_text, {
                        class: "ion-text-center",
                        color: "medium"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.status || _ctx.site || _ctx.search || _ctx.folder)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("orders.no_result_filtered")), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("orders.no_result")), 1))
                        ]),
                        _: 1
                      })
                    ], 2))
            ]),
            sidebar: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style['filters-content'])
              }, [
                (!_ctx.is_mobile)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass([_ctx.$style['filter-item-wrapper'], ""])
                    }, [
                      _createVNode(_component_ion_searchbar, {
                        modelValue: _ctx.search,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search) = $event)),
                        class: _normalizeClass(_ctx.$style['filter-item-search']),
                        placeholder: _ctx.$t('orders.search_placeholder'),
                        color: "light",
                        mode: "ios"
                      }, null, 8, ["modelValue", "class", "placeholder"])
                    ], 2))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.available_filters, (filter, name) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: name,
                    class: _normalizeClass(_ctx.$style['filter-item-wrapper'])
                  }, [
                    (name === 'roles')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(_ctx.$style['filter-item'])
                        }, [
                          _createVNode(_component_ion_select, {
                            modelValue: _ctx.roles,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.roles) = $event)),
                            multiple: true,
                            placeholder: _ctx.$t('orders.filters.roles'),
                            style: {"width":"100%"}
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter, (item) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: item.uuid,
                                  value: item.uuid
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.display_name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "placeholder"])
                        ], 2))
                      : _createCommentVNode("", true),
                    (name === 'site')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(_ctx.$style['filter-item'])
                        }, [
                          _createVNode(_component_ion_button, {
                            color: "light",
                            expand: "full",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.open_site_selector = true))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['button-inner'])
                              }, [
                                _createVNode(_component_ion_icon, {
                                  icon: _ctx.mapIcon,
                                  color: "medium"
                                }, null, 8, ["icon"]),
                                (!_ctx.site?.name)
                                  ? (_openBlock(), _createBlock(_component_ion_text, {
                                      key: 0,
                                      color: "medium"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t("orders.filters.site")), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                      _createTextVNode(_toDisplayString(_ctx.text.truncate(_ctx.site?.name, 26)) + " ", 1),
                                      _createVNode(_component_ion_icon, {
                                        class: _normalizeClass(_ctx.$style['remove-filter']),
                                        icon: _ctx.closeIcon,
                                        color: "dark",
                                        onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.site = null), ["stop"]))
                                      }, null, 8, ["class", "icon"])
                                    ]))
                              ], 2)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_SiteSelector, {
                            active: _ctx.open_site_selector,
                            onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.site = $event.value)),
                            onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.open_site_selector = false))
                          }, null, 8, ["active"])
                        ], 2))
                      : _createCommentVNode("", true),
                    (name === 'status')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: _normalizeClass(_ctx.$style['filter-item'])
                        }, [
                          _createVNode(_component_ion_select, {
                            modelValue: _ctx.status,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.status) = $event)),
                            multiple: true,
                            placeholder: _ctx.$t('orders.filters.status'),
                            style: {"width":"100%"}
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter, (item) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: item.name,
                                  value: item.name
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("orders.status." + item.name)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "placeholder"])
                        ], 2))
                      : _createCommentVNode("", true),
                    (name === 'folder')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 3,
                          class: _normalizeClass(_ctx.$style['filter-item'])
                        }, [
                          _createVNode(_component_ion_button, {
                            color: "light",
                            expand: "full",
                            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.open_folder_selector = true))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['button-inner'])
                              }, [
                                _createVNode(_component_ion_icon, {
                                  icon: _ctx.folderIcon,
                                  color: "medium"
                                }, null, 8, ["icon"]),
                                (_ctx.last_folder)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                                      _createTextVNode(_toDisplayString(_ctx.text.truncate(
                          _ctx.$t("orders.folder_labels." + _ctx.last_folder),
                          26
                        )) + " ", 1),
                                      _createVNode(_component_ion_icon, {
                                        class: _normalizeClass(_ctx.$style['remove-filter']),
                                        icon: _ctx.closeIcon,
                                        color: "dark",
                                        onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.folder = null), ["stop"]))
                                      }, null, 8, ["class", "icon"])
                                    ]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                      _createVNode(_component_ion_text, { color: "medium" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t("orders.filters.folder")), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]))
                              ], 2)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_FolderSelector, {
                            active: _ctx.open_folder_selector,
                            onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.folder = $event.value)),
                            onClose: _cache[13] || (_cache[13] = ($event: any) => (_ctx.open_folder_selector = false))
                          }, null, 8, ["active"])
                        ], 2))
                      : _createCommentVNode("", true)
                  ], 2))
                }), 128))
              ], 2)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_fab, {
        slot: "fixed",
        horizontal: "end",
        vertical: "bottom"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, {
            color: "primary",
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.$router.push('/orders/new')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}