<template>
  <div :class="{
    [$style['message']]:true,
    [$style['message--same-user']]: message.same_user,
  }">
    <p
        v-if="!message.same_user"
        :class="$style['message__user']"> {{ item.user?.display_name }}</p>

    <div v-if="!message.same_user"
         :class="$style['message__action']">
      {{ item.message }}
    </div>

    <div :class="$style['message__content']">
      <p v-for="meta in item.meta" :key="meta.key">
        <span :class="$style['message__key']">
          {{ meta.key }}
        </span> {{ meta.new }}
      </p>
    </div>

    <p
        :class="$style['message__datetime']">
      {{ moment(item.created_at).fromNow() }}
    </p>
  </div>
</template>

<style lang="scss" module>
.message {
  width: 100%;
  // text-align: center;
  margin-top: 2em;

  &--same-user {
    margin-top: 0;
  }

  &__action,
  &__content,
  &__user,
  &__datetime {
    font-size: .9em;
    opacity: .5;
    margin: 0;
    padding: 0;
  }

  &__key,
  &__action {
    font-size: .8em;
    text-transform: uppercase;
  }

  &__content, {
    opacity: 1;

    p {
      margin: 0;
      padding: 0;
    }
  }

  &__key {
    font-weight: bold;
    background: black;
    color: white;
    border-radius: 3px;
    padding: 0 3px;
    opacity: .2;
  }

  &__user,
  &__action {
    font-weight: bold;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { Message } from "@/messages";
import { useUserStore } from "@/stores/user";
import moment from "moment";

export default defineComponent({
  props: ['message'],
  setup() {
    const user = useUserStore();
    return {user, moment};
  },
  computed: {
    item() {
      return this.message as Message;
    }
  }
});
</script>