import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    button: !!_ctx.statItem.link,
    class: _normalizeClass(_ctx.$style['card']),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.statItem.link && _ctx.$router.push(_ctx.formattedLink)))
  }, {
    default: _withCtx(() => [
      (!_ctx.statItem.count)
        ? (_openBlock(), _createBlock(_component_ion_icon, {
            key: 0,
            class: _normalizeClass(_ctx.$style['icon']),
            icon: _ctx.checkmark,
            color: "success",
            size: "large"
          }, null, 8, ["class", "icon"]))
        : (_openBlock(), _createBlock(_component_ion_icon, {
            key: 1,
            class: _normalizeClass(_ctx.$style['icon']),
            icon: _ctx.warning,
            color: "secondary",
            size: "large"
          }, null, 8, ["class", "icon"])),
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_title, {
            class: _normalizeClass(_ctx.$style['title'])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.statItem.count), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('stats.labels.' + _ctx.statItem.name)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.statItem.count)
        ? (_openBlock(), _createBlock(_component_ion_button, {
            key: 2,
            class: _normalizeClass(_ctx.$style['button']),
            fill: "clear"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('stats.actions.more')) + " ", 1),
              _createVNode(_component_ion_icon, {
                slot: "end",
                icon: _ctx.arrowForwardOutline
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["button", "class"]))
}