import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['site'])
  }, [
    (_ctx.site?.satellite_image_url)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.site?.satellite_image_url,
          loading: "lazy"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['inner'])
    }, [
      _createElementVNode("h2", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.site.display_name), 1)
      ]),
      (_ctx.site.address||_ctx.site.reference)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
            (_ctx.site.reference)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                  _createTextVNode(" Réf. " + _toDisplayString(_ctx.site.reference) + " ", 1),
                  (_ctx.site.address)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, " — "))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.site.address)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  (_ctx.site.address.street)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.site.address.street) + " ", 1))
                    : _createCommentVNode("", true),
                  (_ctx.site.address.number)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.site.address.number) + " ", 1))
                    : _createCommentVNode("", true),
                  ((_ctx.site.address.street || _ctx.site.address.number) && (_ctx.site.address.zip||_ctx.site.address.city))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, " — "))
                    : _createCommentVNode("", true),
                  (_ctx.site.address.zip)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.site.address.zip) + " ", 1))
                    : _createCommentVNode("", true),
                  (_ctx.site.address.city)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.site.address.city) + " ", 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}