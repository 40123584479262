<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>
          {{ $t("equipment.screen_title") }}
        </ion-title>
      </ion-toolbar>
      <ion-toolbar v-if="is_mobile" color="primary">
        <ion-searchbar
            v-model="search"
            :placeholder="$t('equipment.search_placeholder')"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ContentSidebarWrapper side="left">
        <template v-slot:content>
          <div v-if="!loaded">
            <EquipmentSkeleton/>
          </div>
          <div v-else-if="equipment?.length">
            <ion-refresher slot="fixed" @ionRefresh="reloadUp">
              <ion-refresher-content></ion-refresher-content>
            </ion-refresher>

            <ListItem
                v-for="item in equipment"
                v-show="isFiltered(item)"
                :key="item.uuid"
                :equipment="item"
            />

            <ion-infinite-scroll
                id="infinite-scroll"
                threshold="500px"
                @ionInfinite="loadNextPage($event)"
            >
              <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </div>
          <div v-else :data-class="$style['no-equipment']">
            <ion-text class="ion-text-center" color="medium">
              <p v-if="status || site || search">
                {{ $t("equipment.no_result_filtered") }}
              </p>
              <p v-else>
                {{ $t("equipment.no_result") }}
              </p>
            </ion-text>
          </div>
        </template>

        <template v-slot:sidebar>
          <div :data-class="$style['filters-content']">
            <div
                v-if="!is_mobile"
                :data-class="$style['filter-item-wrapper']"
                class=""
            >
              <ion-searchbar
                  v-model="search"
                  :data-class="$style['filter-item-search']"
                  :placeholder="$t('orders.search_placeholder')"
                  color="light"
                  mode="ios"
              ></ion-searchbar>
            </div>

            <div
                v-for="(filter, name) in available_filters"
                :key="name"
                :data-class="$style['filter-item-wrapper']"
            >
              <IonItem v-if="name === 'site'" :data-class="$style['filter-item']" lines="none"
                       style="--background:transparent">
                <IonLabel>{{ $t('equipment.filters.site') }}</IonLabel>
                <SiteSelectorButton
                    @change="site=$event?.value"
                />
              </IonItem>

              <IonItem v-if="name === 'user'" :data-class="$style['filter-item']" lines="none"
                       style="--background:transparent">
                <IonLabel>{{ $t('equipment.filters.user') }}</IonLabel>
                <UserSelectorButton
                    @change="userFilter=$event?.value"
                />
              </IonItem>

              <IonItem v-if="name === 'status'" :data-class="$style['filter-item']" lines="none"
                       style="--background:transparent">
                <IonLabel>{{ $t('equipment.filters.status') }}</IonLabel>

                <ion-select
                    v-model="status"
                    :multiple="false"
                    :placeholder="$t('equipment.filters.status_all')"
                    style="width: 100%"
                >
                  <ion-select-option
                      :value="null"
                  >
                    {{ $t('equipment.filters.status_all') }}
                  </ion-select-option>
                  <ion-select-option
                      v-for="item in filter"
                      :key="item.name"
                      :value="item.name"
                  >
                    {{ $t("equipment.status." + item.name) }}
                  </ion-select-option>
                </ion-select>
              </IonItem>
            </div>
          </div>
        </template>
      </ContentSidebarWrapper>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  InfiniteScrollCustomEvent,
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

import { defineAsyncComponent, defineComponent } from "vue";
import { useUserStore } from "@/stores/user";

import EquipmentSkeleton from "@/components/skeletons/EquipmentSkeleton.vue";
import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue";

import { Equipment } from "@/equipments";
import { User } from "@/users";
import { Site } from "@/sites";
import text from "@/helpers/text";
import ListItem from "@/components/data/equipment/ListItem.vue";
import { closeCircle as closeIcon, map as mapIcon, reload as refreshIcon, } from "ionicons/icons";

const SiteSelectorButton = defineAsyncComponent(
    () => import("@/components/data/selectors/SiteSelectorButton.vue")
);
const UserSelectorButton = defineAsyncComponent(
    () => import("@/components/data/selectors/UserSelectorButton.vue")
);

export default defineComponent({
  setup() {
    const user = useUserStore();

    return {user, refreshIcon, closeIcon, mapIcon, text};
  },
  components: {
    IonRefresherContent,
    IonRefresher,
    IonText,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButtons,
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonMenuButton,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    EquipmentSkeleton,
    ContentSidebarWrapper,
    ListItem,
    IonLabel,
    IonItem,
    SiteSelectorButton,
    UserSelectorButton
  },
  data() {
    return {
      equipment: [],
      page: 1,
      loaded: false,
      available_filters: [],
      search: "",
      status: "",
      site: null,
      userFilter: null,
      search_timeout: null,
    } as {
      equipment: Equipment[];
      page: number;
      loaded: boolean;
      available_filters: any[];
      search: string;
      status: string;
      site: Site | null;
      search_timeout: null | number;
      userFilter: User | null;
    };
  },
  computed: {
    is_mobile() {
      return window?.innerWidth < 768;
    },
  },
  methods: {
    isFiltered(item: Equipment) {
      const userFilter = this.userFilter;
      const siteFilter = this.site;
      const statusFilter = this.status;

      const lastTracker = (item as any).tracker?.sort((a: any, b: any) => new Date(b.tracked_at).getTime() - new Date(a.tracked_at).getTime())[0];
      const roles = lastTracker?.roles ?? [];
      const lastUser = roles.find((role: any) => role.role === 'user')?.user ?? null;
      const lastSite = lastTracker?.site ?? null;
      const lastStatus = lastTracker?.action ?? null;

      if (userFilter && lastUser?.uuid !== userFilter.uuid) return false;
      if (siteFilter && lastSite?.uuid !== siteFilter.uuid) return false;
      if (statusFilter && lastStatus !== statusFilter) return false;

      return true;
    },
    reload() {
      this.load(true);
    },
    reloadUp(event: CustomEvent) {
      this.load(true);
      (event?.target as any)?.complete();
    },
    loadFilters() {
      this.axios.get("/equipment/filters").then((response) => {
        this.available_filters = response.data;
      });
    },
    load(refresh = false) {
      if (refresh) {
        this.page = 1;
        this.loaded = false;
      }

      // Make query string
      this.axios
          .get(
              "/equipment?withTracking=1&page=" +
              this.page +
              "&search=" +
              encodeURIComponent(this.search) +
              "&site=" +
              encodeURIComponent(this.site?.uuid ?? "") +
              "&action=" +
              encodeURIComponent(this.status ?? "") +
              "&user=" +
              encodeURIComponent(this.userFilter?.uuid ?? "")
          )
          .then((response) => {
            if (refresh) {
              this.equipment = [];
            }
            response.data?.data?.forEach((entry: Equipment) => {
              this.equipment.push(entry);
            });

            this.loaded = true;
            this.page++;
          });

      if (this.page === 1) {
        this.loadFilters();
      }
    },

    loadNextPage(e: InfiniteScrollCustomEvent) {
      this.axios
          .get(
              "/equipment?withTracking=1&page=" +
              this.page +
              "&search=" +
              encodeURIComponent(this.search) +
              "&site=" +
              encodeURIComponent(this.site?.uuid ?? "") +
              "&action=" +
              encodeURIComponent(this.status ?? "")
          )
          .then((response) => {
            response.data?.data?.forEach((entry: Equipment) => {
              this.equipment.push(entry);
            });

            this.page++;

            if (this.page > response.data.last_page) {
              e.target.disabled = true;
            }

            e.target.complete();
          });
    },
  },
  watch: {
    search: function () {
      if (this.search_timeout) {
        clearTimeout(this.search_timeout);
      }

      this.search_timeout = setTimeout(() => {
        this.load(true);
      }, 750);
    },
    site: function () {
      this.load(true);
    },
    status: function () {
      this.load(true);
    },
    userFilter: function () {
      this.load(true);
    },
  },
  mounted() {
    if (this.$route.query.status) {
      this.status = this.$route.query.status as string;
    }

    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });
  },
});
</script>

<style lang="scss" module>
.no-order {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 20%;
  padding: 1em;
}

.filters-content {
  padding-top: 1em;
  padding-bottom: 1em;
}

$filter-icon-space: 2.5em;
$filter-icon-width: 1.4em;

.filter-item-wrapper {
  padding: 0.25em 1em;

  ion-searchbar,
  ion-button,
  ion-select {
    font-size: 1em !important;
    --box-shadow: none;

    input,
    ion-icon {
      font-size: 1em !important;
    }
  }

  ion-select {
    --padding-start: #{$filter-icon-space};
  }

  ion-searchbar,
  .button-inner {
    input {
      padding-inline-start: $filter-icon-space !important;
    }

    ion-icon {
      width: $filter-icon-width !important;
      margin-left: ($filter-icon-space - $filter-icon-width) * 0.5 !important;
      margin-right: ($filter-icon-space - $filter-icon-width) * 0.5 !important;
      left: 0 !important;
    }
  }

  .filter-item,
  .filter-item-search {
    --border-radius: 10px;
    border-radius: var(--border-radius);
    padding: 0;
    margin: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    display: flex;
    background: var(--ion-color-light);
    color: var(--ion-color-light-contrast);
    height: 2.75em;

    overflow: hidden;

    > * {
      height: inherit !important;
      margin: 0;
      margin-inline: 0;

      &:first-child {
        font-weight: normal;
        flex-grow: 1;
      }
    }

    ion-button {
      --padding-start: 0;
    }

    .button-inner {
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;

      ion-icon {
        width: $filter-icon-width !important;
        //margin-left: (( $filter-icon-space - $filter-icon-width)*.5) !important;
        //margin-right: ($filter-icon-space - $filter-icon-width)*.5 !important;
      }

      > span {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .remove-filter {
          margin-right: -0.6em !important;
          width: 1em !important;
          font-size: 1.2em !important;
        }
      }
    }
  }
}
</style>
