import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, _renderList(16, (index) => {
    return _createVNode(_component_ion_item, {
      key: index,
      button: true,
      disabled: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style['inner'])
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("h1", null, [
              _createVNode(_component_ion_skeleton_text, {
                animated: "",
                style: {"width":"7em"}
              })
            ]),
            _createElementVNode("p", null, [
              _createVNode(_component_ion_skeleton_text, {
                animated: "",
                style: {"width":"3em"}
              })
            ]),
            _createElementVNode("p", null, [
              _createVNode(_component_ion_skeleton_text, {
                animated: "",
                style: {"width":"5em"}
              })
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              style: {"width":"4em"}
            })
          ])
        ], 2)
      ]),
      _: 2
    }, 1024)
  }), 64))
}