import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { ref: "video" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.modalControls)
      ? (_openBlock(), _createBlock(_component_ion_header, {
          key: 0,
          translucent: true,
          color: "dark"
        }, {
          default: _withCtx(() => [
            (_ctx.modalControls)
              ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                  key: 0,
                  color: "dark"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_buttons, {
                      slot: "end",
                      color: "dark"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          color: "light",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('skip')))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Skip ")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          color: "light",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_content, {
      class: _normalizeClass(_ctx.$style['scanner-content']),
      fullscreen: true,
      color: "dark"
    }, {
      default: _withCtx(() => [
        (_ctx.devices?.length)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style['scanner-video'])
            }, [
              _createElementVNode("video", _hoisted_1, null, 512)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"])
  ], 64))
}