import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    [_ctx.$style['message']]:true,
    [_ctx.$style['message--from-me']]: _ctx.from_me,
    [_ctx.$style['message--same-user']]: _ctx.message.same_user,
  })
  }, [
    (!_ctx.message.same_user)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.$style['message__user'])
        }, _toDisplayString(_ctx.item.user?.display_name), 3))
      : _createCommentVNode("", true),
    (_ctx.item.deleted)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style['message__content']+' '+_ctx.$style['message__content--deleted'])
        }, _toDisplayString(_ctx.$t('messages.deleted')), 3))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(_ctx.$style['message__content']),
          innerHTML: _ctx.item.message
        }, null, 10, _hoisted_1)),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.$style['message__datetime'])
    }, _toDisplayString(_ctx.moment(_ctx.item.created_at).fromNow()), 3)
  ], 2))
}