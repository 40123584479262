<template>
  <ListItemSkeleton v-if="!loaded"/>
  <div v-else-if="!orders?.length">
    <ion-text class="ion-text-center" color="medium">
      <p>
        {{ $t("orders.no_result") }}
      </p>
    </ion-text>
  </div>
  <div v-else>
    <ion-refresher slot="fixed" @ionRefresh="reload">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <list-item
        v-for="order in orders"
        :key="order.uuid"
        :order="order"
    />

    <ion-infinite-scroll
        id="infinite-scroll"
        threshold="100px"
        @ionInfinite="loadNextPage($event)"
    >
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Order } from "@/orders";
import ListItemSkeleton from "@/components/skeletons/ListItemSkeleton.vue";
import {
  InfiniteScrollCustomEvent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonText
} from "@ionic/vue";
import ListItem from "@/components/data/orders/ListItem.vue";

export default defineComponent({
  components: {
    IonInfiniteScrollContent,
    IonInfiniteScroll,
    IonRefresherContent,
    ListItem,
    IonRefresher,
    IonText, ListItemSkeleton
  },
  props: ['site'],
  data() {
    return {
      loaded: false as boolean,
      orders: [] as Order[],
      page: 1 as number,
    };
  },
  methods: {
    load(callback?: (data: any) => void) {
      const url = '/orders';
      const params = {
        site: this.site?.uuid,
        page: this.page
      };

      this.axios.get(url, {params}).then((response) => {
        response.data?.data.forEach((order: Order) => {
          this.orders.push(order);
        });

        this.loaded = true;
        this.page = (response.data?.current_page ?? 1) + 1;

        if (callback) {
          callback(response.data);
        }
      });
    },

    reload(event: CustomEvent) {
      this.page = 1;
      this.load();
      (event?.target as any)?.complete();
    },

    loadNextPage(e: InfiniteScrollCustomEvent) {
      this.load((data) => {
        if (this.page > data.last_page) {
          e.target.disabled = true;
        }

        e.target.complete();
      });
    },
  },
  mounted() {
    this.load();
  }
});
</script>