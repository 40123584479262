<template>
  <div>
    <EquipmentSelector
        :active="open"
        :filter="filter"
        @change="change"
        @close="open=false"
    />

    <ion-button color="light" expand="full" @click="open=true">
      <ion-text v-if="!value" color="medium">
        {{ $t('global.select') }}
      </ion-text>
      <span v-else>
          {{ value }}
        </span>
    </ion-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EquipmentSelector from "@/components/data/selectors/EquipmentSelector.vue";
import { IonButton, IonText } from "@ionic/vue";
import { Equipment } from "@/equipments";

export default defineComponent({
  components: {EquipmentSelector, IonButton, IonText},
  emits: ['change'],
  props: ['filter', 'selectedItem'],
  setup() {
    return {};
  },
  mounted() {
    if (this.$props.selectedItem) {
      this.value = this.$props.selectedItem.name;
    }
  },
  watch: {
    selectedItem(value: Equipment | null) {
      if (value) {
        this.value = value.name;
      }
    }
  },
  methods: {
    change(value: any) {
      this.value = value.value.name;

      this.$emit('change', value);
    }
  },
  data() {
    return {
      open: false,
      value: null as Equipment['name'] | null
    }
  }
});
</script>