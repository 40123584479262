<template>
  <ion-modal :is-open="is_open"
             @willDismiss="is_open=false"
  >

    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ $t('widgets.equipment_selector.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="is_open=false">
            {{ $t('global.close') }}
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar v-model="search" :placeholder="$t('global.search')"></ion-searchbar>
      </ion-toolbar>
      <ion-toolbar v-if="filter_types?.length">
        <ion-segment :value="selected_type??''" @ionChange="selected_type=$event.detail.value">
          <ion-segment-button v-for="type in filter_types" :key="type" :value="type">
            <ion-label>{{ $t('equipment.types.' + type) }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>

    </ion-header>
    <ion-content>
      <ion-list v-if="!first_loading&&equipments.length">
        <ion-item v-for="equipment in equipments" :key="equipment.uuid" :button="true"
                  @click="selectEquipment(equipment)">
          <ion-label>
            {{ equipment.reference }} — {{ equipment.name }}
          </ion-label>
        </ion-item>

        <ion-infinite-scroll
            :disabled="loading"
            :hidden="has_more_data||loading"
            threshold="200px"
            @ionInfinite="loadEquipments($event)"
        >
          <ion-infinite-scroll-content/>
        </ion-infinite-scroll>

      </ion-list>


      <div v-else-if="!loading" :class="{
        'ion-padding':true,
        [$style['no-result']]:true
       }"
      >
        <p>
          {{ $t('widgets.equipment_selector.no_result') }}</p>
      </div>
      <div v-else-if="loading" :class="{
      'ion-padding':true,
      [$style['no-result']]:true
    }">
        <ion-spinner></ion-spinner>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  InfiniteScrollCustomEvent,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonModal,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import { Equipment } from "@/equipments";
import { useUserStore } from "@/stores/user";
import { useEquipmentStore } from "@/stores/equipment";

export default defineComponent({
  props: ['active', 'filter'],
  emits: ['close', 'change'],
  setup() {
    const equipment = useEquipmentStore();

    return {
      equipment
    };
  },
  data() {
    return {
      equipments: [] as Equipment[],
      search: "",
      first_loading: true,
      loading: false,
      page: 1,
      has_more_data: true,
      search_timeout: null as null | number,
      selected_type: null as null | string
    };
  },
  mounted() {
    this.selected_type = this.filter_types[0] ?? null;

    this.$watch('equipment.types', (types) => {
      if (types.includes(this.selected_type)) return;
      if (!this.filter_types?.length) return;

      this.selected_type = this.filter_types[0];
    });
  },
  methods: {
    loadEquipments(infinite_event?: InfiniteScrollCustomEvent) {
      if (this.loading || !this.selected_type) return;

      const user = useUserStore();

      this.loading = true;
      this.axios.get("equipment" + (!user.is_logged_in ? '/guest' : '') + "?page=" + this.page + '&' + this.filter_string).then((response) => {
        this.equipments = [...this.equipments, ...response.data.data];
        this.loading = false;
        this.first_loading = false;
        this.page++;
        this.has_more_data = !!response.data.data.length;

        if (infinite_event) {
          infinite_event.target.complete();
        }
      });
    },
    selectEquipment(equipment: Equipment) {
      this.$emit('change', {value: equipment});
      this.is_open = false;
    }
  },
  computed: {
    filter_types() {
      let types = this.equipment.types;

      if (this.$props.filter?.types) {
        types = types.filter((type) => this.$props.filter?.types?.includes(type));
      }

      return types;
    },
    is_open: {
      get() {
        return this.$props.active;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('close');
        }
      }
    },
    filter_string() {
      const filter = {
        type: this.selected_type ?? null,
        search: this.search ?? null
      }

      return Object.entries(filter).map(([key, value]) => {
        if (!value) return '';

        return key + '=' + encodeURIComponent((value as string));
      }).join('&');
    }
  },
  watch: {
    search() {
      if (this.search_timeout) {
        clearTimeout(this.search_timeout);
      }
      this.search_timeout = setTimeout(() => {
        this.equipments = [];
        this.page = 1;
        this.has_more_data = true;
        this.loadEquipments();
      }, 500);
    },
    selected_type() {
      console.log('selected_type');
      this.equipments = [];
      this.page = 1;
      this.has_more_data = true;
      this.loadEquipments();
    }
  },
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonSearchbar, IonModal, IonSpinner, IonTitle, IonButton, IonButtons, IonToolbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent, IonSegment, IonSegmentButton
  }
});
</script>

<style lang="scss" module>
.no-result {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 70%;

  > * {
    margin: .5em 0;
  }
}


</style>