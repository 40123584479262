import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanningStatus = _resolveComponent("PlanningStatus")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    button: true,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/planning/' + _ctx.entry.uuid)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['inner'])
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.entry.display_name), 1)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style['right'])
        }, [
          _createVNode(_component_PlanningStatus, { entry: _ctx.entry }, null, 8, ["entry"])
        ], 2)
      ], 2)
    ]),
    _: 1
  }))
}