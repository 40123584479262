import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-hide-md-down" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "ion-hide-md-down" }
const _hoisted_4 = { colspan: "6" }
const _hoisted_5 = { slot: "end" }
const _hoisted_6 = { slot: "end" }
const _hoisted_7 = { slot: "end" }
const _hoisted_8 = { slot: "end" }
const _hoisted_9 = { slot: "end" }
const _hoisted_10 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_IonButton = _resolveComponent("IonButton")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['wrapper'])
  }, [
    _createElementVNode("table", {
      class: _normalizeClass({
      [_ctx.$style['items']]: true,
      [_ctx.$style['is-'+_ctx.scheme]]: _ctx.scheme
    })
    }, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.$t("orders.products_table.labels." + 'sku')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t("orders.products_table.labels." + 'label')), 1),
          _createElementVNode("td", {
            class: _normalizeClass(_ctx.$style['quantity'])
          }, _toDisplayString(_ctx.$t("orders.products_table.labels." + 'quantity')), 3),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t("orders.products_table.labels." + 'unit')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t("orders.products_table.labels." + 'price')), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.$t("orders.products_table.labels." + 'total')), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.items, (item, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: index,
            class: _normalizeClass({
        [_ctx.$style['editable']]:_ctx.editable
      }),
            onClick: _withModifiers(($event: any) => (_ctx.editable?_ctx.editRow(item,index):null), ["stop"])
          }, [
            _createElementVNode("td", _hoisted_3, _toDisplayString(item.sku), 1),
            _createElementVNode("td", null, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style['label'])
              }, _toDisplayString(item.label), 3),
              _createElementVNode("p", {
                class: _normalizeClass('ion-hide-md-up '+_ctx.$style['in-sku'])
              }, _toDisplayString(item.sku), 3)
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style['quantity'])
              }, _toDisplayString(item.quantity), 3)
            ]),
            _createElementVNode("td", null, _toDisplayString(item.unit), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.text.formatPrice(item.price)), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.text.formatPrice(item.total)), 1)
          ], 10, _hoisted_2))
        }), 128)),
        _createElementVNode("tr", {
          class: _normalizeClass({
        [_ctx.$style['editable']]:true,
        [_ctx.$style['new']]:true,
        [_ctx.$style['is-hidden']]:!_ctx.editable
      }),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.newItem && _ctx.newItem(...args)), ["stop"]))
        }, [
          _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.$t('orders.new.new_line')), 1)
        ], 2)
      ])
    ], 2),
    (0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style['totals'])
        }, [
          _createElementVNode("table", {
            class: _normalizeClass(_ctx.$style['totals-table'])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.totals, (total) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: total.name
              }, [
                _createElementVNode("td", null, _toDisplayString(_ctx.$t("orders.products_table.labels." + total.name)), 1),
                _createElementVNode("td", null, _toDisplayString(_ctx.text.formatPrice(total.price)), 1)
              ]))
            }), 128))
          ], 2)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_modal, {
      canDismiss: true,
      "is-open": _ctx.edit_modal,
      onWillDismiss: _cache[8] || (_cache[8] = ($event: any) => (_ctx.edit_modal=false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit_modal=false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.close')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.edit_item && _ctx.edit_row !== null ? (_ctx.edit_item.sku ?? _ctx.edit_item.label ?? _ctx.$t('orders.new.edit_line')) : _ctx.$t('orders.new.new_line')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createElementVNode("form", {
              onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.saveItem && _ctx.saveItem(...args)), ["prevent"]))
            }, [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("orders.products_table.labels." + 'sku')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_ion_input, {
                      modelValue: _ctx.edit_item.sku,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.edit_item.sku) = $event)),
                      placeholder: "123456",
                      type: "text"
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("orders.products_table.labels." + 'label')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_ion_input, {
                      modelValue: _ctx.edit_item.label,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.edit_item.label) = $event)),
                      placeholder: "Product #123456",
                      type: "text"
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("orders.products_table.labels." + 'quantity')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_ion_input, {
                      modelValue: _ctx.edit_item.quantity,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.edit_item.quantity) = $event)),
                      min: "0",
                      step: "0.01",
                      type: "number"
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("orders.products_table.labels." + 'unit')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_ion_input, {
                      modelValue: _ctx.edit_item.unit,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.edit_item.unit) = $event)),
                      placeholder: "Pc",
                      type: "text"
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("orders.products_table.labels." + 'price')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_ion_input, {
                      modelValue: _ctx.edit_item.price,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.edit_item.price) = $event)),
                      placeholder: "9.99",
                      step: "0.01",
                      type: "number"
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_IonButton, {
                  color: "success",
                  expand: "block",
                  type: "submit"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.save')), 1)
                  ]),
                  _: 1
                }),
                (_ctx.edit_row)
                  ? (_openBlock(), _createBlock(_component_IonButton, {
                      key: 0,
                      color: "medium",
                      expand: "block",
                      fill: "clear",
                      onClick: _ctx.deleteItem
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.delete')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ], 32)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 2))
}