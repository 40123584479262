import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue, isPlatform } from "@ionic/vue";
import { createPinia } from "pinia";

import { createI18n } from "vue-i18n";

import axios from "axios";

import VueAxios from "vue-axios";

import "@ionic/vue/css/core.css";

import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

import "./theme/variables.css";
import "./theme/fonts.scss";
import "./theme/overrides.scss";

import locale_fr from "./locales/fr";
import moment from "moment";

import axiosRetry from 'axios-retry';

const i18n = createI18n({
    locale: "fr",
    fallbackLocale: "fr",
    messages: {
        fr: locale_fr,
    },
});

const browser_locale = navigator.language;
moment.locale(browser_locale ?? "fr");

const pinia = createPinia();

let api_url = "https://api.portal.travexploit.be/api/";

if (!((window as any)?.Capacitor?.isNative) && document?.location?.href?.includes("localhost")) {
    api_url = "http://localhost:8000/api/";
}

navigator.serviceWorker.register('/sw.js')
    .then(function (registration) {
        console.log('Service worker successfully registered.', registration);
    })
    .catch(function (error) {
        console.log('Service worker registration failed:', error);
    });

axios.defaults.baseURL = api_url;
axiosRetry(axios, {retryDelay: axiosRetry.exponentialDelay});

const app = createApp(App)
    .use(IonicVue, {
        mode: isPlatform('desktop') || isPlatform('ios') ? 'ios' : 'md',
    })
    .use(router)
    .use(i18n)
    .use(VueAxios, axios)
    .use(pinia);

router.isReady().then(() => {
    app.mount("#app");
});
