import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['header'])
  }, [
    _createVNode(_component_ion_img, {
      alt: _ctx.title,
      class: _normalizeClass(_ctx.$style['header-image']),
      src: _ctx.image,
      title: _ctx.title
    }, null, 8, ["alt", "class", "src", "title"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['header-content'])
    }, [
      _createVNode(_component_ion_grid, { fixed: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (_ctx.title)
                      ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                          default: _withCtx(() => [
                            _createElementVNode("h1", {
                              class: _normalizeClass(_ctx.$style['header-title'])
                            }, _toDisplayString(_ctx.title), 3)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.subtitle)
                      ? (_openBlock(), _createBlock(_component_ion_text, {
                          key: 1,
                          color: "medium"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", {
                              class: _normalizeClass(_ctx.$style['header-subtitle'])
                            }, _toDisplayString(_ctx.subtitle), 3)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.$slots.default)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: _normalizeClass(_ctx.$style['header-slot'])
                        }, [
                          _renderSlot(_ctx.$slots, "default")
                        ], 2))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 3
              })
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ], 2)
  ], 2))
}