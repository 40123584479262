import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ControlSheetsSkeleton = _resolveComponent("ControlSheetsSkeleton")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ContentSidebarWrapper = _resolveComponent("ContentSidebarWrapper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.screen_title")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ContentSidebarWrapper, { side: "left" }, {
            sidebar: _withCtx(() => []),
            content: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_ControlSheetsSkeleton)
                  ]))
                : (_ctx.sheets.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ion_refresher, {
                        slot: "fixed",
                        onIonRefresh: _ctx.reloadUp
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_refresher_content)
                        ]),
                        _: 1
                      }, 8, ["onIonRefresh"]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheets, (sheet) => {
                        return (_openBlock(), _createBlock(_component_ListItem, {
                          key: sheet.uuid,
                          sheet: sheet
                        }, null, 8, ["sheet"]))
                      }), 128)),
                      _createVNode(_component_ion_infinite_scroll, {
                        id: "infinite-scroll",
                        threshold: "500px",
                        onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadNextPage($event)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_infinite_scroll_content)
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_ion_text, {
                        class: "ion-text-center",
                        color: "medium"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t("control_sheets.no_result")), 1)
                        ]),
                        _: 1
                      })
                    ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_fab, {
        slot: "fixed",
        horizontal: "end",
        vertical: "bottom"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, {
            color: "primary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/forms/control-sheet')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}