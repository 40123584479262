import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_openBlock(), _createBlock(_component_ion_text, {
    class: _normalizeClass(_ctx.$style['status']),
    color: 
                        _ctx.sheet?.status?.includes('validation')
                          ? 'warning'
                          : _ctx.sheet?.status === 'done'
                          ? 'success'
                          : _ctx.sheet?.status === 'refused'
                          ? 'danger'
                          : _ctx.sheet?.status === 'draft'
                          ? 'medium'
                          : 'dark'
                      
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.status." + (_ctx.sheet?.status ?? "unknown"))), 1)
    ]),
    _: 1
  }, 8, ["class", "color"]))
}