<template>
  <svg
      height="2000.000000pt"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      viewBox="0 0 2000.000000 2000.000000"
      width="2000.000000pt"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        fill="currentColor"
        stroke="none"
        transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)"
    >
      <path
          d="M2840 17708 c0 -11 -23 -167 -86 -598 -9 -58 -19 -132 -24 -165 -15
-107 -49 -348 -60 -417 -5 -38 -14 -98 -20 -135 -5 -38 -19 -126 -30 -198 -11
-71 -29 -195 -40 -275 -18 -134 -62 -436 -109 -755 -11 -71 -40 -274 -66 -450
-55 -380 -77 -532 -105 -719 -12 -76 -18 -141 -15 -144 7 -7 5306 -18 10980
-24 l3700 -3 88 355 c48 195 105 425 127 510 41 161 281 1128 559 2255 89 358
168 675 176 706 8 30 15 58 15 62 0 4 -3395 7 -7545 7 -6029 0 -7545 -3 -7545
-12z"
      />
      <path
          d="M6787 11528 c-4 -29 -13 -100 -22 -158 -9 -58 -20 -136 -25 -175 -6
-38 -14 -99 -20 -135 -10 -64 -41 -272 -80 -540 -11 -74 -24 -162 -29 -195 -5
-33 -26 -172 -46 -310 -20 -137 -40 -275 -45 -305 -5 -30 -25 -167 -45 -305
-20 -137 -41 -277 -46 -310 -5 -33 -20 -136 -33 -230 -14 -93 -39 -262 -56
-375 -17 -113 -42 -281 -55 -375 -33 -225 -64 -437 -75 -505 -5 -30 -24 -165
-44 -300 -20 -135 -45 -306 -56 -380 -12 -74 -25 -166 -30 -205 -6 -38 -12
-81 -15 -95 -3 -14 -10 -59 -15 -100 -5 -41 -14 -102 -19 -135 -5 -33 -21
-139 -35 -235 -14 -96 -35 -236 -46 -310 -11 -74 -36 -245 -56 -380 -20 -135
-40 -272 -45 -305 -5 -33 -18 -121 -29 -195 -11 -74 -31 -209 -44 -300 -14
-91 -41 -277 -61 -415 -20 -137 -41 -277 -46 -310 -5 -33 -14 -91 -19 -130 -5
-38 -14 -97 -19 -130 -5 -33 -21 -139 -35 -235 -14 -96 -40 -267 -56 -380 -43
-285 -95 -644 -134 -910 l-32 -225 2400 -3 c1320 -1 2403 1 2407 5 4 5 17 67
29 138 44 273 141 860 181 1100 23 138 52 315 64 395 13 80 31 186 39 235 23
130 75 450 86 520 5 33 16 101 24 150 14 82 68 406 176 1070 22 138 66 401 96
585 30 184 68 418 85 520 16 102 36 219 44 260 7 41 21 125 30 185 9 61 20
133 25 160 23 125 34 194 95 565 35 217 87 532 114 700 28 168 69 418 91 555
22 138 54 331 71 430 17 99 34 207 40 240 5 33 20 130 34 215 41 244 43 259
60 365 8 55 17 107 20 115 2 8 9 44 14 80 13 87 35 223 65 405 14 83 30 179
35 215 6 36 24 146 41 245 16 99 30 183 30 188 0 4 -1120 7 -2489 7 l-2488 0
-6 -52z"
      />
    </g>
  </svg>
</template>