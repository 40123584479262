import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_openBlock(), _createBlock(_component_ion_text, {
    class: _normalizeClass(_ctx.$style['status']),
    color: _ctx.entry?.status==='planned'||_ctx.entry?.status==='running'||_ctx.entry?.status==='changed'
                          ? 'warning'
                          : _ctx.entry?.status === 'to_review'|| _ctx.entry?.status === 'blocked'
                          ? 'danger'
                          : _ctx.entry?.status === 'validated'
                          ? 'success'
                          : 'dark'
                      
  }, {
    default: _withCtx(() => [
      (_ctx.entry?.status === 'to_review'|| _ctx.entry?.status === 'blocked')
        ? (_openBlock(), _createBlock(_component_ion_icon, {
            key: 0,
            icon: _ctx.alertCircleOutline
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("planning.status." + (_ctx.entry?.status ?? "unknown"))), 1)
    ]),
    _: 1
  }, 8, ["class", "color"]))
}