import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, {
    class: _normalizeClass({
    [_ctx.$style['wrapper']]:true,[_ctx.$style['is-'+_ctx.side]]:_ctx.$style['is-'+_ctx.side]
  })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_col, {
        class: _normalizeClass('ion-no-padding'),
        "size-lg": "7",
        "size-xl": "8",
        "size-xs": "12"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "content")
        ]),
        _: 3
      }),
      _createVNode(_component_ion_col, {
        class: _normalizeClass(_ctx.$style['sidebar']),
        "size-lg": "5",
        "size-xl": "4",
        "size-xs": "12"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['sidebar-inner'])
          }, [
            _renderSlot(_ctx.$slots, "sidebar")
          ], 2)
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }, 8, ["class"]))
}