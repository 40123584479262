<template>
  <div :class="$style['wrapper']">

    <table :class="{
      [$style['items']]: true,
      [$style['is-'+scheme]]: scheme
    }">
      <thead>
      <tr>
        <td class="ion-hide-md-down">
          {{ $t("orders.products_table.labels." + 'sku') }}
        </td>
        <td>
          {{ $t("orders.products_table.labels." + 'label') }}
        </td>
        <td :class="$style['quantity']">
          {{ $t("orders.products_table.labels." + 'quantity') }}
        </td>
        <td>
          {{ $t("orders.products_table.labels." + 'unit') }}
        </td>
        <td>
          {{ $t("orders.products_table.labels." + 'price') }}
        </td>
        <td>
          {{ $t("orders.products_table.labels." + 'total') }}
        </td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index ) in order.items" :key="index" :class="{
        [$style['editable']]:editable
      }" @click.stop="editable?editRow(item,index):null">
        <td class="ion-hide-md-down">{{ item.sku }}</td>
        <td>
          <p :class="$style['label']">
            {{ item.label }}
          </p>
          <p :class="'ion-hide-md-up '+$style['in-sku']">
            {{ item.sku }}
          </p>
        </td>
        <td>
          <p :class="$style['quantity']">{{ item.quantity }}</p>
        </td>
        <td>{{ item.unit }}</td>
        <td>{{ text.formatPrice(item.price) }}</td>
        <td>{{ text.formatPrice(item.total) }}</td>
      </tr>
      <tr :class="{
        [$style['editable']]:true,
        [$style['new']]:true,
        [$style['is-hidden']]:!editable
      }" @click.stop="newItem"
      >

        <td colspan="6">
          {{ $t('orders.new.new_line') }}
        </td>

      </tr>
      </tbody>
    </table>

    <div v-if="0" :class="$style['totals']">
      <table :class="$style['totals-table']">

        <tr v-for="total in order.totals" :key="total.name">
          <td>
            {{ $t("orders.products_table.labels." + total.name) }}
          </td>
          <td>
            {{ text.formatPrice(total.price) }}
          </td>

        </tr>

      </table>
    </div>


    <ion-modal :canDismiss="true" :is-open="edit_modal" @willDismiss="edit_modal=false">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button @click="edit_modal=false">{{ $t('global.close') }}</ion-button>
          </ion-buttons>
          <ion-title>
            {{
              edit_item && edit_row !== null ? (edit_item.sku ?? edit_item.label ?? $t('orders.new.edit_line')) : $t('orders.new.new_line')
            }}
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <form @submit.prevent="saveItem">
          <ion-item>
            <ion-label>
              {{ $t("orders.products_table.labels." + 'sku') }}
            </ion-label>
            <div slot="end">
              <ion-input v-model="edit_item.sku" placeholder="123456" type="text"/>
            </div>
          </ion-item>

          <ion-item>
            <ion-label>
              {{ $t("orders.products_table.labels." + 'label') }}
            </ion-label>
            <div slot="end">
              <ion-input v-model="edit_item.label" placeholder="Product #123456" type="text"/>
            </div>
          </ion-item>

          <ion-item>
            <ion-label>
              {{ $t("orders.products_table.labels." + 'quantity') }}
            </ion-label>
            <div slot="end">
              <ion-input v-model="edit_item.quantity" min="0" step="0.01" type="number"/>
            </div>
          </ion-item>

          <ion-item>
            <ion-label>
              {{ $t("orders.products_table.labels." + 'unit') }}
            </ion-label>
            <div slot="end">
              <ion-input v-model="edit_item.unit" placeholder="Pc" type="text"/>
            </div>
          </ion-item>

          <ion-item>
            <ion-label>
              {{ $t("orders.products_table.labels." + 'price') }}
            </ion-label>
            <div slot="end">
              <ion-input v-model="edit_item.price" placeholder="9.99" step="0.01" type="number"/>
            </div>
          </ion-item>

          <div class="ion-padding">
            <IonButton color="success" expand="block" type="submit">
              {{ $t('global.save') }}
            </IonButton>
            <IonButton v-if="edit_row" color="medium" expand="block" fill="clear" @click="deleteItem">
              {{ $t('global.delete') }}
            </IonButton>
          </div>
        </form>
      </ion-content>
    </ion-modal>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";

import text from "@/helpers/text";

import { OrderItem } from "@/orders";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/vue";

export default defineComponent({
  props: ["order", "editable", "scheme"],
  emits: ["change"],
  components: {
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonContent,
    IonItem,
    IonLabel,
    IonInput
  },
  data() {
    return {
      edit_modal: false,
      edit_row: null as number | null,
      edit_item: null as OrderItem | null,
    }
  },
  methods: {
    saveItem() {
      this.edit_modal = false;
      this.$emit('change', {data: this.edit_item, index: this.edit_row});
    },
    deleteItem() {
      this.edit_modal = false;
      this.$emit('change', {data: {quantity: 0}, index: this.edit_row});
    },
    newItem() {
      this.edit_row = null;
      this.edit_item = {
        sku: '',
        label: '',
        quantity: 1,
        unit: '',
        price: 0,
      };
      this.edit_modal = true;
    },
    editRow(line: OrderItem, index: number) {
      this.edit_row = index;
      this.edit_item = line;
      this.edit_modal = true;
    }
  },
  setup() {
    return {text};
  },
});
</script>


<style lang="scss" module>

.wrapper {
  overflow: auto;
}

.label {
  font-weight: bold;
  line-height: 1.1em;
}

.in-sku {
  font-size: 0.9em;
  opacity: .5;
  margin-top: .5em;
}

.quantity {
  text-align: center !important;
}

.totals {
  margin-top: 1em;
  //font-size: .9em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 1em;

  table {
    thead {
      opacity: 0;
    }

    tr td {
      padding: 1em;
      border: 1px solid var(--ion-color-light);

      &:first-child {
        border: none !important;
      }
    }
  }
}

table.items {
  width: 100%;

  tr {
    border-bottom: 1px solid var(--ion-color-light);

    &.editable {

      &.is-hidden {
        opacity: 0;
        pointer-events: none;
        border-bottom-color: transparent;
      }

      cursor: pointer;

      &:hover {
        background-color: rgba(var(--ion-color-primary-rgb), .1);
      }

      &.new {
        font-style: italic;
        text-align: center;
      }
    }
  }

  td {
    padding: .75em;

    &:first-child,
    &:nth-child(2) {
      padding-left: 1.5em;
    }

    &:last-child {
      padding-right: 1.5em;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  &.is-primary {
    thead {
      background: var(--ion-color-primary-shade);
      color: var(--ion-color-primary-contrast);
    }
  }

  &.is-secondary {
    thead {
      background: var(--ion-color-secondary-shade);
      color: var(--ion-color-secondary-contrast);
    }
  }

  &.is-medium {
    thead {
      background: var(--ion-color-medium);
      color: var(--ion-color-medium-contrast);
    }
  }
}
</style>