<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button
              :text="$t('planning.back_button_label')"
              default-href="/planning"
          ></ion-back-button>
        </ion-buttons>

        <ion-title>
          {{ entry?.display_name }}
        </ion-title>

      </ion-toolbar>
    </ion-header>

    <ion-content v-if="entry">
      <ion-grid :fixed="true">
        <ion-row>
          <ion-col>

            <h1>
              <span v-if="moment(entry.starts_at).format('LL')===moment().format('LL')">
                {{ $t('calendar.today') }}
              </span>
              <span v-else-if="moment(entry.starts_at).format('LL')===moment().subtract(1,'day').format('LL')">
                {{ $t('calendar.yesterday') }}
              </span>
              <span v-else-if="moment(entry.starts_at).format('LL')===moment().add(1,'day').format('LL')">
                {{ $t('calendar.tomorrow') }}
              </span>
              <span v-else>
              {{ moment(entry.starts_at).format('LL') }}
              </span>
            </h1>
            <h2>
              {{ entry.task_name }}
            </h2>

            <div>
              <UserChip v-for="role in compiled_roles" :key="role.user.uuid" :display_role="true" :role_data="role"/>
            </div>

            <div :class="$style['spaced']">
              <ion-text color="medium">
                <h3>{{ $t('planning.timeline.title') }}</h3>
              </ion-text>
              <div :class="{
                [$style['timeline']]: true,
                [$style['is-'+entry.status]]: $style['is-'+entry.status]
              }">
                <div :class="$style['hours']">
                  <p :class="$style['hour']">
                    {{ moment(entry.starts_at).format('LT') }}
                  </p>
                  <button v-if="entry.status==='to_review'" :class="$style['hour']"
                          @click="edit_hours=true;validation_step='files'">
                    {{ moment(entry.ends_at).format('LT') }}
                    <ion-icon :icon="chevronForwardOutline"></ion-icon>
                  </button>
                  <p v-else :class="$style['hour']">
                    {{ moment(entry.ends_at).format('LT') }}
                  </p>
                </div>
                <div :class="$style['description']">
                  <div>
                    <p :class="$style['status']">
                      {{ $t('planning.status.' + entry.status) }}
                    </p>
                    <p>
                      {{ entry.task_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="entry.status==='blocked'" :class="$style['spaced']">
              <ion-text color="danger">
                <h1>
                  {{ $t('planning.review.title') }}
                </h1>
                <p>
                  {{ $t('planning.review.blocked') }}
                </p>
                <ion-button color="danger" size="large" @click="validation_step='manual_validation'">
                  {{ $t('planning.review.contact_button') }}
                </ion-button>
              </ion-text>
            </div>

            <div v-if="entry.status==='to_review'" :class="$style['spaced']">
              <h1>
                {{ $t('planning.review.title') }}
              </h1>

              <div>
                <ion-button @click="edit_hours=false;validation_step='files'">
                  {{ $t('planning.review.yes') }}
                </ion-button>
                <ion-button fill="outline" @click="edit_hours=true;validation_step='files'">
                  {{ $t('planning.review.no') }}
                </ion-button>
              </div>

              <ion-text color="medium">
                <p>
                  {{ $t('planning.review.disclaimer') }}
                </p>
              </ion-text>
            </div>

            <div v-if="user_is_validator&&entry?.status==='changed'" :class="$style['spaced']">
              <h1>
                {{ $t('planning.review.title') }}
              </h1>
              <ion-button color="success" @click="save">
                {{ $t('planning.review.validate') }}
              </ion-button>
              <ion-button color="success" fill="outline" @click="validation_step='edit_hours'">
                {{ $t('planning.review.validator_edit_hours') }}
              </ion-button>
            </div>

            <FilesPreview v-if="entry?.files?.length" :files="entry?.files" class="ion-padding"/>

            <div :class="$style['spaced']">
              <SiteBlock v-if="entry?.site" :site="entry.site"/>
            </div>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-content v-else>
      <ion-grid :fixed="true">
        <ion-row>
          <ion-col>
            <PlanningEntrySkeleton/>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-modal v-if="entry?.status==='changed'||entry?.status==='to_review'||entry?.status==='blocked'"
               :is-open="validation_step"
               @canDismiss="true" @willDismiss="validation_step=false">
      <ion-header>
        <ion-toolbar>
          <ion-title v-if="validation_step">
            {{ entry?.task_name }} ({{ moment(entry.starts_at).format('L') }}) — {{ entry?.site?.name }}
          </ion-title>
          <ion-buttons slot="end">
            <ion-button @click="validation_step=false">
              {{
                entry?.status === 'blocked' || entry?.status === 'changed' ? $t('global.close') : $t('global.cancel')
              }}
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content v-if="validation_step==='manual_validation'" class="ion-padding">
        <a :class="$style['email-info']"
           :href="'mailto:' + ['planning@travexploit.be', ...site_supervisors.map(supervisor=>supervisor.email)].join(',')">
          <ion-icon :icon="mailUnreadOutline" color="primary"/>
          <p>planning@travexploit.be</p>
          <p v-for="supervisor in site_supervisors" :key="supervisor.uuid">
            <span>{{ supervisor.email }}</span>
          </p>
        </a>
      </ion-content>
      <ion-content v-if="validation_step==='files'">
        <ion-text align="center" color="primary">
          <h4 v-if="task_assignee" class="ion-padding">
            {{ $t('planning.review.' + validation_step + '.subtitle') }}
          </h4>

        </ion-text>

        <ion-list>
          <ion-item v-for="file in [...planningDataset.required_files,...planningDataset.files]" :key="file">
            <ion-label>
              {{
                $t('planning.review.' + validation_step + '.' + file + '_file') + (!planningDataset.required_files.includes(file) ? ' (' + $t('global.optional').toLowerCase() + ')' : '')
              }}
            </ion-label>
            <uploadable-file
                :params="{related: entry.uuid, type:file}"
                :value="files[file]"
                accept="image/*"
                capture="environment"
                multiple="true"
                @change="setFile($event, file)"
            />
          </ion-item>
        </ion-list>

        <div class="ion-padding">
          <ion-button :disabled="!files_uploaded" color="success" expand="block"
                      @click="edit_hours?validation_step='edit_hours':save">
            {{ $t('planning.review.' + validation_step + '.validate') }}
          </ion-button>
        </div>

      </ion-content>
      <ion-content v-if="validation_step==='edit_hours'">
        <div>
          <ion-text align="center" color="primary">
            <h4 v-if="task_assignee" class="ion-padding">
              {{ $t('planning.review.edit_hours.subtitle') }} {{ task_assignee?.display_name }} ?
            </h4>
          </ion-text>

          <ion-item>
            <ion-label>
              {{ $t('planning.review.edit_hours.starts_at') }}
            </ion-label>
            <div slot="end" :class="$style['hours-table-value']">
              <ion-input v-model="starts_at_hour" :step="(15*60)" type="time"/>
            </div>
          </ion-item>

          <ion-item>
            <ion-label>
              {{ $t('planning.review.edit_hours.ends_at') }}
            </ion-label>
            <div slot="end" :class="$style['hours-table-value']">
              <ion-input v-model="ends_at_hour" :step="(15*60)" type="time"/>
            </div>
          </ion-item>

          <ion-item>
            <ion-label>
              {{ $t('planning.review.edit_hours.pause_duration') }}
            </ion-label>

            <ion-select
                :value="pause_duration"
                interface="popover"
                @ionChange="pause_duration=$event.detail.value"
            >
              <ion-select-option v-for="duration in this.planningDataset.break_durations"
                                 :key="duration"
                                 :value="duration"
              >
                {{ duration }} {{ $t('calendar.minutes') }}
              </ion-select-option>
            </ion-select>

          </ion-item>

          <ion-item>
            <ion-label>
              {{ $t('planning.review.edit_hours.duration') }}
            </ion-label>
            <div slot="end" :class="$style['hours-table-value']">
                          <span v-if="total_duration>0">
                            {{ moment.utc().startOf('day').add(total_duration, 'minutes').format('LT') }}
                          </span>
              <span v-else>
                            <ion-text color="danger">

                            {{ moment.utc().startOf('day').add(0, 'minutes').format('LT') }}
                            </ion-text>
                          </span>
            </div>
          </ion-item>

          <ion-item>
            <ion-label>
              {{ $t('planning.review.edit_hours.changed_reason') }}
            </ion-label>
            <div slot="end" :class="$style['hours-table-value']">
              <ion-button v-if="!changed_comment" @click="show_messages=true">
                {{ $t('planning.review.edit_hours.changed_reason_button') }}
              </ion-button>
              <ion-button v-else color="light" @click="show_messages=true">
                {{ changed_comment }}
              </ion-button>
            </div>
          </ion-item>

          <div class="ion-padding">
            <ion-button :disabled="total_duration<=0" color="success" expand="block"
                        @click="save">
              {{ $t('planning.review.edit_hours.validate') }}
            </ion-button>
          </div>
        </div>
      </ion-content>
    </ion-modal>

    <ion-modal v-if="entry?.uuid" :is-open="show_messages"
               @canDismiss="true" @willDismiss="show_messages=false">
      <ion-header v-if="0">
        <ion-toolbar>
          <ion-title v-if="validation_step">
            {{ entry?.task_name }}
          </ion-title>
          <ion-buttons slot="end">
            <ion-button @click="show_messages=false">
              {{
                $t('global.close')
              }}
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <RelatedMessages v-if="show_messages" :related="entry.uuid" @message_sent="commentSubmit"/>
      </ion-content>
    </ion-modal>

  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UserChip from "@/components/data/user/UserChip.vue";

import SiteBlock from "@/components/data/site/SiteBlock.vue";
import UploadableFile from "@/components/ui/fields/UploadableFile.vue";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/vue";

import PlanningEntrySkeleton from "@/components/skeletons/PlanningEntrySkeleton.vue";

import { chevronForwardOutline, mailUnreadOutline } from "ionicons/icons";

import { useUserStore } from "@/stores/user";
import { PlanningEntry } from "@/planning";

import moment from "moment";
import { usePlanningStore } from "@/stores/planning";
import FilesPreview from "@/components/data/files/FilesPreview.vue";
import RelatedMessages from "@/components/data/messages/RelatedMessages.vue";

export default defineComponent({
  components: {
    RelatedMessages,
    FilesPreview,
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonButton,
    IonContent,
    IonGrid,
    PlanningEntrySkeleton,
    IonRow,
    IonCol,
    UserChip,
    SiteBlock,
    IonText,
    IonModal,
    IonItem,
    IonLabel,
    IonInput,
    IonIcon,
    UploadableFile,
    IonList,
    IonSelect,
    IonSelectOption
  },
  setup() {
    const planningDataset = usePlanningStore();
    const user = useUserStore();
    return {user, moment, chevronForwardOutline, mailUnreadOutline, planningDataset};
  },
  data() {
    return {
      loaded: false as boolean,
      entry: null as PlanningEntry | null,
      validation_step: false as boolean | string,
      edit_hours: false,
      show_messages: false,
      changed_comment: null as string | null,

      starts_at_hour: '00:00',
      ends_at_hour: '00:00',
      pause_duration: 0,

      files: [] as any
    }
  },
  computed: {
    user_is_validator() {
      return this.entry?.roles?.filter((role: any) => role.role === 'validator').map((role: any) => role.user.uuid).includes(this.user.uuid);
    },
    total_duration() {
      if (!this.entry) return 0;

      return moment.duration(moment(this.entry.ends_at).diff(moment(this.entry.starts_at))).subtract(this.pause_duration, 'minutes').asMinutes();
    },
    task_assignee() {
      return this.entry?.roles?.filter((role: any) => role.role === 'assignee')[0]?.user;
    },
    compiled_roles() {
      const entry_roles = this.entry?.roles ?? [];
      const contractors = entry_roles.filter(role => role.user?.contractor).map(role => {
        return {user: role.user?.contractor, role: 'contractor'}
      }) ?? [];
      const site_roles = this.entry?.site?.roles ?? [];

      return [...entry_roles, ...contractors, ...site_roles].sort((a, b) => {
        if (a.role === 'contractor' && b.role === 'assignee') {
          return -1;
        } else if (a.role === 'assignee' && b.role === 'contractor') {
          return 1;
        } else {
          return 0;
        }
      });
    },
    site_supervisors() {
      return this.compiled_roles.filter((role: any) => role.role === 'site-supervisor').map((role: any) => role.user);
    },
    files_uploaded() {
      if (this.user_is_validator) {
        return true;
      }

      if (!this.files) return false;

      const flattened_files = Object.values(this.files).flatMap((file: any) => file);

      const uploaded_file_types = flattened_files.map((file: any) => file.type);
      if (!uploaded_file_types) return false;

      const required_file_types = this.planningDataset.required_files.map((file: any) => file);
      if (!required_file_types) return false;

      return required_file_types.every((file_type: string) => uploaded_file_types.includes(file_type));
    }
  },
  methods: {
    commentSubmit(data: any) {
      this.show_messages = false;
      this.changed_comment = data.message.length > 12 ? data.message.substring(0, 12) + '...' : data.message;
    },
    load() {
      this.axios.get("/planning/" + this.$route.params.uuid).then((response) => {
        this.entry = response.data;

        this.starts_at_hour = moment(this.entry?.starts_at).parseZone().format('HH:mm');
        this.ends_at_hour = moment(this.entry?.ends_at).parseZone().format('HH:mm');

        this.pause_duration = this.entry?.pause_duration ?? 0;

        this.loaded = true;
      });
    },
    setFile(event: any, file_type: string) {
      this.files = {...this.files, [file_type]: event};
    },
    save() {
      const starts_at = moment(this.entry?.starts_at).set({
        hour: moment(this.starts_at_hour, 'HH:mm').hour(),
        minute: moment(this.starts_at_hour, 'HH:mm').minute(),
      }).add(moment().utcOffset(), 'minutes');

      const ends_at = moment(this.entry?.ends_at).set({
        hour: moment(this.ends_at_hour, 'HH:mm').hour(),
        minute: moment(this.ends_at_hour, 'HH:mm').minute(),
      }).add(moment().utcOffset(), 'minutes');

      this.axios.post("/planning/" + this.$route.params.uuid, {
        starts_at: starts_at.toISOString(),
        ends_at: ends_at.toISOString(),
        pause_duration: this.pause_duration,
        files: this.files
      }).then(() => {
        this.load();

        this.validation_step = false;
        this.$router.push('/planning');
      });
    },
  },
  watch: {
    starts_at_hour(to) {
      if (!this.entry) return;
      this.entry.starts_at = moment(this.entry.starts_at).set({
        hour: moment(to, 'HH:mm').hour(),
        minute: moment(to, 'HH:mm').minute(),
      }).toISOString();
    },
    ends_at_hour(to) {
      if (!this.entry) return;
      this.entry.ends_at = moment(this.entry.ends_at).set({
        hour: moment(to, 'HH:mm').hour(),
        minute: moment(to, 'HH:mm').minute(),
      }).toISOString();
    }
  },
  mounted() {
    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });
  },
});
</script>

<style lang="scss" module>
.spaced {
  margin: 4em 0;
}

.timeline {
  display: flex;

  .hours {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: 1em;

    .hour {
      color: var(--ion-color-medium-contrast);
      background: var(--ion-color-medium);
      font-weight: bold;
      padding: .333333em .5em;
      border-radius: 10px;
      order: 1;
      margin: 0;

      display: flex;
      align-items: center;

      font-size: .9em;
      line-height: 1em;

      &:last-child {
        order: 3;
      }

      ion-icon {
        margin-left: .5em;
      }
    }

    &:after {
      content: '';
      order: 2;
      display: block;
      width: 2px;
      background: var(--ion-color-medium);
      flex-grow: 1;
      min-height: 7.5em;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;

    p {
      margin: 0;
      padding: 0;
    }

    .status {
      font-weight: bold;
      color: var(--ion-color-medium);

      &:after {
        content: ':';
      }
    }
  }

  &.is-running,
  &.is-changed,
  &.is-to_review {
    .hours {
      .hour {
        &:first-child {
          background: var(--ion-color-success);
          color: var(--ion-color-success-contrast);
        }
      }

      &:after {
        background: linear-gradient(to bottom, var(--ion-color-success), var(--ion-color-medium));
      }
    }
  }

  &.is-to_review {
    .hours {
      .hour {
        &:last-child {
          background: var(--ion-color-warning);
          color: var(--ion-color-warning-contrast);
        }
      }

      &:after {
        background: linear-gradient(to bottom, var(--ion-color-success), var(--ion-color-warning));
      }
    }
  }

  &.is-blocked {
    .hours {
      .hour {
        background: var(--ion-color-danger);
        color: var(--ion-color-danger-contrast);
      }

      &:after {
        background: var(--ion-color-danger);
      }
    }

  }

  &.is-validated {
    .hours {
      .hour {
        background: var(--ion-color-success);
        color: var(--ion-color-success-contrast);
      }

      &:after {
        background: var(--ion-color-success);
      }
    }
  }
}

.hours-table-value {
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ion-input {
    all: inherit;
    padding: 0;
    margin: 0;
  }

  input {
    &::-webkit-calendar-picker-indicator {
      background-color: var(--ion-color-primary);
      border-radius: 3px;
      cursor: pointer;
    }
  }
}

.email-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: var(--ion-color-primary);
  text-decoration: none;

  height: 80%;


  font-weight: bold;
  font-size: 1em;

  ion-icon {
    font-size: 3em;
  }

  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>