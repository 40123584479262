<template>
  <ion-item :button="true" @click="$router.push('/planning/' + entry.uuid)">
    <div :class="$style['inner']">
      <div>
        <h1>{{ entry.display_name }}</h1>

      </div>
      <div :class="$style['right']">
        <PlanningStatus :entry="entry"/>
      </div>
    </div>
  </ion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useUserStore } from "@/stores/user";

import { IonItem } from "@ionic/vue";
import PlanningStatus from "@/components/data/planning/PlanningStatus.vue";

import moment from "moment";

export default defineComponent({
  props: ["entry"],
  components: {
    IonItem,
    PlanningStatus
  },
  setup() {
    const user = useUserStore();
    return {user, moment};
  },
  computed: {}
});
</script>

<style lang="scss" module>
.inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  font-size: .9em;

  @media(max-width: 990px) {
    font-size: .8em;
  }

  @media(max-width: 700px) {
    flex-direction: column-reverse;
  }

  h1, p {
    padding: 0;
    margin: 0;
  }

  p {
    margin-top: .25em;
    color: var(--ion-color-medium);
  }

  .right {
    display: flex;
    align-items: center;

    @media(min-width: 701px) {
      flex-direction: column;
      align-items: flex-end;
    }

    @media(max-width: 700px) {
      .date {
        display: none;
      }
    }
  }
}
</style>