<template>
  <div :class="$style['wrapper']">
    <IonSpinner v-if="!loaded"></IonSpinner>
    <div v-else :class="$style['wrapper']">
      <ion-content ref="list" :class="$style['messages']+' ion-padding'">
        <div v-for="(message, index) in messages" :key="index">
          <HistoryBubble v-if="message.type==='history'" :message="message"/>
          <MessageBubble v-else :message="message"/>
        </div>
      </ion-content>

      <form :class="$style['write']+' ion-padding'" @submit="sendMessage">
        <ion-textarea ref="messageInput" v-model="new_message" :class="$style['message']" :disabled="send_loading"
                      :placeholder="$t('messages.new_placeholder')">
        </ion-textarea>
        <ion-button :disabled="send_loading||!new_message" fill="none" type="submit" @click="sendMessage">
          <ion-icon :icon="send" color="primary"></ion-icon>
        </ion-button>
      </form>
    </div>

  </div>
</template>

<script lang="ts">

import { defineComponent, ref } from "vue";
import { IonButton, IonContent, IonIcon, IonSpinner, IonTextarea } from "@ionic/vue";
import { Message } from "@/messages";
import MessageBubble from "@/components/data/messages/MessageBubble.vue";
import { send } from 'ionicons/icons'
import HistoryBubble from "@/components/data/messages/HistoryBubble.vue";

export default defineComponent({
  components: {MessageBubble, HistoryBubble, IonSpinner, IonButton, IonTextarea, IonIcon, IonContent},
  setup() {
    const list = ref<any>(null)
    const messageInput = ref<any>(null)

    return {send, list, messageInput}
  },
  data() {
    return {
      loaded: false as boolean,
      send_loading: false as boolean,
      messages: [] as Message[],
      new_message: "" as string
    };
  },
  props: ['related'],
  emits: ['message_sent'],
  methods: {
    load() {
      this.axios.get("/messages?related=" + this.$props.related).then((response) => {
        this.messages = response.data.sort((a: Message, b: Message) => {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        });

        for (let i = 0; i < this.messages.length; i++) {
          if (i > 0 && this.messages[i].user?.uuid === this.messages[i - 1].user?.uuid
          ) {
            this.messages[i].same_user = true;
          }
        }

        this.loaded = true;
        setTimeout(this.scrollToBottom, 100);
      }).catch((error) => {
        console.log(error);
      });
    },
    scrollToBottom() {
      this.list?.$el?.scrollToBottom(500);
    },
    sendMessage(e: Event) {
      e.preventDefault();

      if (this.send_loading) return;

      this.send_loading = true;
      this.axios.put("/messages", {
        related: this.$props.related,
        message: this.new_message
      }).then((response) => {
        this.messages.push(response.data);
        this.new_message = "";
        this.send_loading = false;
        this.$emit('message_sent', response.data);
        this.scrollToBottom();
      }).catch((error) => {
        console.log(error);
        this.send_loading = false;
      });
    }
  },
  mounted() {
    this.load();
    this.messageInput?.$el?.setFocus()

    window.addEventListener('resize', this.scrollToBottom);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.scrollToBottom);
  }
});
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.messages {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  --background: var(--ion-color-light);
}

.write {
  display: flex;
  width: 100%;
  align-items: flex-end;

  .message {
    flex-grow: 1;
  }
}
</style>