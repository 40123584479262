<template>
  <ion-text :class="$style['status']"
            :color="
                        sheet?.status?.includes('validation')
                          ? 'warning'
                          : sheet?.status === 'done'
                          ? 'success'
                          : sheet?.status === 'refused'
                          ? 'danger'
                          : sheet?.status === 'draft'
                          ? 'medium'
                          : 'dark'
                      "
  >
    {{ $t("control_sheets.status." + (sheet?.status ?? "unknown")) }}
  </ion-text>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonText } from "@ionic/vue";

export default defineComponent({
  props: ['sheet'],
  components: {IonText}
});
</script>

<style lang="scss" module>
.status {
  font-weight: bold;
}
</style>