<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button
              :text="$t('sites.back_button_label')"
              default-href="/sites"
          ></ion-back-button>
        </ion-buttons>

        <ion-title>
          {{ site?.name }}
        </ion-title>

      </ion-toolbar>
    </ion-header>
    <ion-content v-if="loaded">
      <ImageHeader :image="site?.satellite_image_url" :subtitle="subtitle" :title="site?.name"/>

      <ion-grid :fixed="true">
        <ion-row>
          <ion-col>
            <div>
              <UserChip v-for="role in site?.roles" :key="role.user.uuid" :display_role="true" :role_data="role"/>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text color="danger">
              <strong>
                ⚠️ Les fiches de chantier sont en cours de développement. <br/>
                Il est possible que certaines données soient erronnées ou ne soient pas encore présentes.
              </strong>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <PlanningEntriesList :site="site"/>
            <OrderList :site="site"/>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <SiteSkeleton v-else></SiteSkeleton>

  </ion-page>

</template>


<script lang="ts">
import { defineComponent } from "vue";
import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import { Site } from "@/sites";
import { useUserStore } from "@/stores/user";
import ImageHeader from "@/components/wrappers/ImageHeader.vue";
import SiteSkeleton from "@/components/skeletons/SiteSkeleton.vue";
import UserChip from "@/components/data/user/UserChip.vue";
import OrderList from "@/components/data/lists/OrderList.vue";
import PlanningEntriesList from "@/components/data/lists/PlanningEntriesList.vue";

export default defineComponent({
  components: {
    PlanningEntriesList,
    OrderList,
    IonCol, IonRow, IonGrid,
    UserChip,
    SiteSkeleton,
    ImageHeader, IonButtons, IonBackButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText
  },
  setup() {
    const user = useUserStore();
    return {user};
  },
  data() {
    return {
      loaded: false as boolean,
      site: null as Site | null,
    };
  },
  methods: {
    load() {
      this.axios.get("/sites/" + this.$route.params.uuid).then((response) => {
        this.site = response.data;
        this.loaded = true;
      });
    }
  },
  computed: {
    subtitle() {
      let subtitle = '';
      if (this.site?.reference) subtitle += 'Ref.: ' + this.site.reference;
      if (this.site?.reference && (this.site?.address?.street || this.site?.address?.city)) subtitle += ' — ';
      if (this.site?.address?.street) subtitle += this.site.address.street;
      if (this.site?.address?.street && this.site?.address?.number) subtitle += ', ';
      if (this.site?.address?.number) subtitle += this.site.address.number;
      if (this.site?.address?.street && (this.site?.address?.city || this.site?.address?.zip)) subtitle += ' — ';
      if (this.site?.address?.zip) subtitle += this.site.address.zip;
      if (this.site?.address?.zip && this.site?.address?.city) subtitle += ' ';
      if (this.site?.address?.city) subtitle += this.site.address.city;

      return subtitle;
    }
  },
  mounted() {
    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });
  }
});
</script>