<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>
          {{ $t("planning.screen_title") }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="loaded" ref="list_container" class="ion-padding">
      <ion-grid v-if="planning?.length" :fixed="true">
        <ion-row>
          <ion-col>
            <ion-infinite-scroll
                id="infinite-scroll"
                position="top"
                threshold="100px"
                @ionInfinite="loadPrevPage($event)"

            >
              <ion-infinite-scroll-content :loading-spinner="null"></ion-infinite-scroll-content>
            </ion-infinite-scroll>

            <div :class="$style['list-wrapper']">
              <div v-for="item in planning"
                   :key="item.uuid"
                   :class="$style['list-item']"
                   :data-key="item.uuid"
              >
                <div @click="$router.push('/planning/' + item.uuid)">
                  <p
                      :class="$style['date']">
                    <span>
                    {{ moment(item.starts_at).format('LL') }}</span>
                  </p>
                  <h2
                      :class="$style['name']">
                    <span>  {{ item.display_name }}</span>
                  </h2>
                  <p v-if="item.site?.name"
                     :class="$style['site']">
                    {{ item.site.name }}
                  </p>

                  <PlanningStatus :entry="item"/>
                </div>
              </div>
            </div>

            <ion-infinite-scroll
                id="infinite-scroll"
                threshold="100px"
                @ionInfinite="loadNextPage($event)"
            >
              <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>

          </ion-col>
        </ion-row>
      </ion-grid>
      <div v-else :class="$style['no-entry']">
        <ion-text color="medium">
          <p>{{ $t("planning.no_entry") }}</p>
        </ion-text>
      </div>
    </ion-content>

    <ion-content v-else class="ion-padding">
      <ion-grid :fixed="true">
        <ion-row>
          <ion-col>
            <PlanningSkeleton/>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  InfiniteScrollCustomEvent,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/vue";

import { add } from 'ionicons/icons';

import { defineComponent, ref } from "vue";
import { useUserStore } from "@/stores/user";

import PlanningStatus from "@/components/data/planning/PlanningStatus.vue";

import PlanningSkeleton from "@/components/skeletons/PlanningSkeleton.vue";

import moment from "moment";

import { PlanningEntry } from "@/planning";

export default defineComponent({
  setup() {
    const user = useUserStore();
    const list_container = ref();

    return {user, add, moment, list_container};
  },
  components: {
    IonButtons,
    IonContent,
    IonPage,
    PlanningSkeleton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonHeader,
    IonToolbar,
    IonTitle, IonGrid, IonRow, IonCol, PlanningStatus, IonText, IonMenuButton
  },
  data() {
    return {
      planning: [],
      page_top: 0,
      page_bottom: 0,
      scroll_direction: null,
      days_per_page: 90,
      loaded: false
    } as {
      planning: PlanningEntry[];
      page_top: number;
      page_bottom: number;
      scroll_direction: string | null;
      days_per_page: number;
      loaded: boolean;
    };
  },
  methods: {
    load(callback?: any) {

      if (!this.planning?.length && !this.page_top && !this.page_bottom) {
        this.axios.get("/planning").then((response) => {
          this.planning = response.data?.data;
          this.loaded = true;
        });

        return;
      }

      const page = this.scroll_direction === 'top' ? this.page_top : this.page_bottom;

      const now = moment().startOf('day');
      const from = now.clone().add((page) * this.days_per_page, 'days');
      const from_date = from.format('YYYY-MM-DD');
      const to = now.clone().add((page + 1) * this.days_per_page, 'days');
      const to_date = to.format('YYYY-MM-DD');


      this.axios.get("/planning?from=" + from_date + "&to=" + to_date).then((response) => {
        response.data?.data?.forEach((entry: PlanningEntry) => {
          this.planning.push(entry);
        });

        this.planning = this.planning?.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.uuid === thing.uuid
                ))
        )?.sort((a: PlanningEntry, b: PlanningEntry) => {
          return a.starts_at > b.starts_at ? 1 : -1;
        });


        this.loaded = true;

        if (callback) {
          callback();
        }
      });
    },
    loadNextPage(event?: InfiniteScrollCustomEvent) {
      this.scroll_direction = 'bottom';
      this.page_bottom++;
      this.load(() => {
        event?.target.complete();
      });
    },
    loadPrevPage(event?: InfiniteScrollCustomEvent) {
      this.scroll_direction = 'top';
      this.page_top--;
      this.load(() => {
        event?.target.complete();
      });
    },
  },
  mounted() {
    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });
  },
});
</script>

<style lang="scss" module>
.no-entry {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  text-align: center;

  > * {
    max-width: 75%;
  }
}

.list-wrapper {
  // min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .list-item {
    cursor: pointer;
  }

  .list-item {
    $bar-height: 5em;
    $bar-width: 5px;

    width: 100%;
    padding: 2em 0;

    //display: flex;
    //justify-content: center;
    //align-items: center;
    //flex-direction: column;

    position: relative;

    margin-bottom: $bar-height*1.9;
    text-align: center;

    .date, {
      color: var(--ion-color-medium);
      font-weight: bold;

      //span {
      //  background: var(--ion-color-medium);
      //  color: var(--ion-color-medium-contrast);
      //  padding: .25em 1em;
      //  border-radius: 1em;
      //}

      margin-bottom: .5em;
      text-transform: uppercase;
    }

    .site {
      color: var(--ion-color-medium);
      margin-bottom: 1em;
      margin-top: 0;
      font-size: .8em;
      font-weight: bold;
    }

    .name {
      margin-bottom: 0em;
      color: var(--ion-color-primary);

      font-size: 1.25em;

      span {
        background: var(--ion-color-medium);
        color: var(--ion-color-medium-contrast);
        padding: .25em 1em;
        border-radius: 1em;

        display: inline-block;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

    }

    &:before, &:after {
      content: '';
      position: absolute;
      background: var(--ion-color-light-shade);
      width: $bar-width;
      left: calc(50% - ($bar-width * .5));
      height: $bar-height;
      border-radius: $bar-width*.5;
    }

    &:before {
      top: -$bar-height;
      //border-bottom-left-radius: 0;
      //border-bottom-right-radius: 0;
    }

    &:after {
      bottom: -$bar-height;
      //border-top-left-radius: 0;
      //border-top-right-radius: 0;
    }

    &:first-child {
      &:before {
        display: none;

        //height: 50vh;
        //top: -50vh;
      }
    }

    &:last-child {
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }
  }
}
</style>