<template>
  <ion-text :class="$style['status']" :color="entry?.status==='planned'||entry?.status==='running'||entry?.status==='changed'
                          ? 'warning'
                          : entry?.status === 'to_review'|| entry?.status === 'blocked'
                          ? 'danger'
                          : entry?.status === 'validated'
                          ? 'success'
                          : 'dark'
                      "
  >
    <ion-icon v-if="entry?.status === 'to_review'|| entry?.status === 'blocked'" :icon="alertCircleOutline"></ion-icon>
    {{ $t("planning.status." + (entry?.status ?? "unknown")) }}
  </ion-text>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon, IonText } from "@ionic/vue";
import { alertCircleOutline } from "ionicons/icons";

export default defineComponent({
  props: ['entry'],
  components: {IonText, IonIcon},
  setup() {
    return {alertCircleOutline}
  }
});
</script>

<style lang="scss" module>
.status {
  font-weight: bold;
}
</style>