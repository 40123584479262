import { defineStore } from "pinia";

declare type OrderFolder = {
    name: string,
    children?: OrderFolder[]
};

export const useOrderStore = defineStore("order", {
    state: () => {
        return {
            folders: [] as OrderFolder[],
        }
    }
});
