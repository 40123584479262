<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>
          {{ $t("orders.screen_title") }}
        </ion-title>

        <ion-buttons slot="secondary">
          <ion-button
            v-if="0"
            :text="$t('orders.new_button_label')"
            @click="$router.push('/orders/new')"
          >
            {{ $t("orders.new_order_label") }}
            <ion-icon :icon="add" />
          </ion-button>
          <ion-button :text="$t('orders.new_button_label')" @click="reload">
            <ion-icon :icon="refreshIcon" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar v-if="is_mobile" color="primary">
        <ion-searchbar
          v-model="search"
          :placeholder="$t('orders.search_placeholder')"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ContentSidebarWrapper side="left">
        <template v-slot:content>
          <div v-if="!loaded">
            <OrdersSkeleton />
          </div>
          <div v-else-if="orders.length">
            <ion-refresher slot="fixed" @ionRefresh="reloadUp">
              <ion-refresher-content></ion-refresher-content>
            </ion-refresher>

            <list-item
              v-for="order in orders"
              :key="order.uuid"
              :order="order"
            />

            <ion-infinite-scroll
              id="infinite-scroll"
              threshold="500px"
              @ionInfinite="loadNextPage($event)"
            >
              <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </div>
          <div v-else :class="$style['no-order']">
            <ion-text class="ion-text-center" color="medium">
              <p v-if="status || site || search || folder">
                {{ $t("orders.no_result_filtered") }}
              </p>
              <p v-else>
                {{ $t("orders.no_result") }}
              </p>
            </ion-text>
          </div>
        </template>

        <template v-slot:sidebar>
          <div :class="$style['filters-content']">
            <div
              v-if="!is_mobile"
              :class="$style['filter-item-wrapper']"
              class=""
            >
              <ion-searchbar
                v-model="search"
                :class="$style['filter-item-search']"
                :placeholder="$t('orders.search_placeholder')"
                color="light"
                mode="ios"
              ></ion-searchbar>
            </div>

            <div
              v-for="(filter, name) in available_filters"
              :key="name"
              :class="$style['filter-item-wrapper']"
            >
              <div v-if="name === 'roles'" :class="$style['filter-item']">
                <ion-select
                  v-model="roles"
                  :multiple="true"
                  :placeholder="$t('orders.filters.roles')"
                  style="width: 100%"
                >
                  <ion-select-option
                    v-for="item in filter"
                    :key="item.uuid"
                    :value="item.uuid"
                  >
                    {{ item.display_name }}
                  </ion-select-option>
                </ion-select>
              </div>

              <div v-if="name === 'site'" :class="$style['filter-item']">
                <ion-button
                  color="light"
                  expand="full"
                  @click="open_site_selector = true"
                >
                  <div :class="$style['button-inner']">
                    <ion-icon :icon="mapIcon" color="medium" />
                    <ion-text v-if="!site?.name" color="medium">
                      {{ $t("orders.filters.site") }}
                    </ion-text>

                    <span v-else>
                      {{ text.truncate(site?.name, 26) }}

                      <ion-icon
                        :class="$style['remove-filter']"
                        :icon="closeIcon"
                        color="dark"
                        @click.stop="site = null"
                      />
                    </span>
                  </div>
                </ion-button>

                <SiteSelector
                  :active="open_site_selector"
                  @change="site = $event.value"
                  @close="open_site_selector = false"
                />
              </div>

              <div v-if="name === 'status'" :class="$style['filter-item']">
                <ion-select
                  v-model="status"
                  :multiple="true"
                  :placeholder="$t('orders.filters.status')"
                  style="width: 100%"
                >
                  <ion-select-option
                    v-for="item in filter"
                    :key="item.name"
                    :value="item.name"
                  >
                    {{ $t("orders.status." + item.name) }}
                  </ion-select-option>
                </ion-select>
              </div>
              <div v-if="name === 'folder'" :class="$style['filter-item']">
                <ion-button
                  color="light"
                  expand="full"
                  @click="open_folder_selector = true"
                >
                  <div :class="$style['button-inner']">
                    <ion-icon :icon="folderIcon" color="medium" />
                    <span v-if="last_folder">
                      {{
                        text.truncate(
                          $t("orders.folder_labels." + last_folder),
                          26
                        )
                      }}

                      <ion-icon
                        :class="$style['remove-filter']"
                        :icon="closeIcon"
                        color="dark"
                        @click.stop="folder = null"
                      />
                    </span>
                    <span v-else>
                      <ion-text color="medium">
                        {{ $t("orders.filters.folder") }}
                      </ion-text>
                    </span>
                  </div>
                </ion-button>

                <FolderSelector
                  :active="open_folder_selector"
                  @change="folder = $event.value"
                  @close="open_folder_selector = false"
                />
              </div>
            </div>
          </div>
        </template>
      </ContentSidebarWrapper>
    </ion-content>

    <ion-fab slot="fixed" horizontal="end" vertical="bottom">
      <ion-fab-button color="primary" @click="$router.push('/orders/new')">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-page>
</template>

<script lang="ts">
import {
  InfiniteScrollCustomEvent,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue";

import {
  add,
  closeCircle as closeIcon,
  folder as folderIcon,
  map as mapIcon,
  reload as refreshIcon,
} from "ionicons/icons";

import { defineAsyncComponent, defineComponent } from "vue";
import { useUserStore } from "@/stores/user";

import OrdersSkeleton from "@/components/skeletons/OrdersSkeleton.vue";

import ListItem from "@/components/data/orders/ListItem.vue";
import { Order } from "@/orders";
import { Site } from "@/sites";
import text from "@/helpers/text";
import { User } from "@/users";

const FolderSelector = defineAsyncComponent(
  () => import("@/components/data/selectors/FolderSelector.vue")
);
const SiteSelector = defineAsyncComponent(
  () => import("@/components/data/selectors/SiteSelector.vue")
);

export default defineComponent({
  setup() {
    const user = useUserStore();

    return { user, add, refreshIcon, folderIcon, closeIcon, mapIcon, text };
  },
  components: {
    IonContent,
    OrdersSkeleton,
    IonText,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    ListItem,
    IonHeader,
    IonToolbar,
    IonIcon,
    SiteSelector,

    IonTitle,
    IonButtons,
    IonButton,
    IonFab,
    IonFabButton,
    IonRefresher,
    IonRefresherContent,
    FolderSelector,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    ContentSidebarWrapper,
    IonMenuButton,
    IonPage,
  },
  data() {
    return {
      orders: [],
      page: 1,
      loaded: false,
      available_filters: [],
      search: "",
      folder: null,
      status: "",
      open_folder_selector: false,
      site: null,
      roles: [],
      open_site_selector: false,
      search_timeout: null,
    } as {
      orders: Order[];
      page: number;
      loaded: boolean;
      available_filters: any[];
      search: string;
      folder: string | null;
      status: string;
      open_folder_selector: boolean;
      site: Site | null;
      open_site_selector: boolean;
      search_timeout: null | number;
      roles: User["uuid"][];
    };
  },
  computed: {
    is_mobile() {
      return window?.innerWidth < 768;
    },
    last_folder() {
      const folders = this.folder?.split("/");
      return folders?.[folders.length - 1];
    },
  },
  methods: {
    reload() {
      this.load(true);
    },
    reloadUp(event: CustomEvent) {
      this.load(true);
      (event?.target as any)?.complete();
    },
    loadFilters() {
      this.axios.get("/orders/filters").then((response) => {
        this.available_filters = response.data;
      });
    },
    load(refresh = false) {
      if (refresh) {
        this.page = 1;
        this.loaded = false;
      }
      
      // Make query string
      const roles =
        this.roles
          ?.map((role) => "&roles[]=" + encodeURIComponent(role))
          .join("") ?? "";

      this.axios
        .get(
          "/orders?page=" +
            this.page +
            "&status=" +
            encodeURIComponent(this.status ?? "") +
            "&site=" +
            encodeURIComponent(this.site?.uuid ?? "") +
            "&folder=" +
            encodeURIComponent(this.folder ?? "") +
            "&search=" +
            encodeURIComponent(this.search) +
            roles
        )
        .then((response) => {
          if (refresh) {
            this.orders = [];
          }
          response.data.data.forEach((order: Order) => {
            this.orders.push(order);
          });

          this.loaded = true;
          this.page++;
        });

      if (this.page === 1) {
        this.loadFilters();
      }
    },
    loadNextPage(e: InfiniteScrollCustomEvent) {
      this.axios
        .get(
          "/orders?page=" +
            this.page +
            "&status=" +
            encodeURIComponent(this.status ?? "") +
            "&site=" +
            encodeURIComponent(this.site?.uuid ?? "") +
            "&folder=" +
            encodeURIComponent(this.folder ?? "") +
            "&search=" +
            encodeURIComponent(this.search)
        )
        .then((response) => {
          response.data.data.forEach((order: any) => {
            this.orders.push(order);
          });

          this.page++;

          if (this.page > response.data.last_page) {
            e.target.disabled = true;
          }

          e.target.complete();
        });
    },
  },
  watch: {
    search: function () {
      if (this.search_timeout) {
        clearTimeout(this.search_timeout);
      }

      this.search_timeout = setTimeout(() => {
        this.load(true);
      }, 750);
    },
    folder: function () {
      this.load(true);
    },
    site: function () {
      this.load(true);
    },
    status: function () {
      this.load(true);
    },
    roles: function () {
      this.load(true);
    },
  },
  mounted() {
    if (this.$route.query.status) {
      this.status = this.$route.query.status as string;
    }

    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });

    (window as any).Echo?.private(`orders`).listen("OrderListChanged", () => {
      this.axios.get("/orders").then((response) => {
        response.data.data.forEach((order: any) => {
          const order_in_list = this.orders.find(
            (o: any) => o.uuid === order.uuid
          );
          if (order_in_list) {
            order_in_list.status = order.status;
            order_in_list.updated_at = order.updated_at;
          }
        });
      });
    });
  },
  beforeUnmount() {
    (window as any).Echo?.leave(`orders`);
  },
});
</script>

<style lang="scss" module>
.no-order {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 20%;
  padding: 1em;
}

.filters-content {
  padding-top: 1em;
  padding-bottom: 1em;
}

$filter-icon-space: 2.5em;
$filter-icon-width: 1.4em;

.filter-item-wrapper {
  padding: 0.25em 1em;

  ion-searchbar,
  ion-button,
  ion-select {
    font-size: 1em !important;
    --box-shadow: none;

    input,
    ion-icon {
      font-size: 1em !important;
    }
  }

  ion-select {
    --padding-start: #{$filter-icon-space};
  }

  ion-searchbar,
  .button-inner {
    input {
      padding-inline-start: $filter-icon-space !important;
    }

    ion-icon {
      width: $filter-icon-width !important;
      margin-left: ($filter-icon-space - $filter-icon-width) * 0.5 !important;
      margin-right: ($filter-icon-space - $filter-icon-width) * 0.5 !important;
      left: 0 !important;
    }
  }

  .filter-item,
  .filter-item-search {
    --border-radius: 10px;
    border-radius: var(--border-radius);
    padding: 0;
    margin: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    display: flex;
    background: var(--ion-color-light);
    color: var(--ion-color-light-contrast);
    height: 2.75em;

    overflow: hidden;

    > * {
      height: inherit !important;
      margin: 0;
      margin-inline: 0;

      &:first-child {
        font-weight: normal;
        flex-grow: 1;
      }
    }

    ion-button {
      --padding-start: 0;
    }

    .button-inner {
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;

      ion-icon {
        width: $filter-icon-width !important;
        //margin-left: (( $filter-icon-space - $filter-icon-width)*.5) !important;
        //margin-right: ($filter-icon-space - $filter-icon-width)*.5 !important;
      }

      > span {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .remove-filter {
          margin-right: -0.6em !important;
          width: 1em !important;
          font-size: 1.2em !important;
        }
      }
    }
  }
}
</style>
