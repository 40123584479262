<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button
              :text="$t('orders.back_button_label')"
              default-href="/orders"
          ></ion-back-button>
        </ion-buttons>

        <ion-title>
          {{ $t('orders.new.screen_title') }}
        </ion-title>

      </ion-toolbar>
      <ion-progress-bar :value="progress"></ion-progress-bar>
    </ion-header>

    <ion-content v-if="user.is_logged_in">
      <div :class="$style['wrapper']">
        <div class="ion-padding">
          <h2>
            {{ $t('orders.new.informations_label') }}

          </h2>


          <ion-item v-if="0" :button="true"
                    :class="$style['field']" :disabled="loading" color="light"
                    lines="none" @click="uploadFiles">
            <ion-label>
              {{ $t('orders.new.files') }}
            </ion-label>

            <ion-badge v-if="order.files?.length">
              {{ order.files?.length }}
            </ion-badge>

            <ion-button v-else expand="block" fill="outlined" @click.prevent.stop="uploadFiles">
              {{ $t('global.select') }}
            </ion-button>
          </ion-item>


          <ion-item :button="true" :class="$style['field']"
                    :disabled="loading" color="light" lines="none"
                    @click="site_modal=true">
            <ion-label>
              {{ $t('orders.new.site_label') }}
            </ion-label>
            <span v-if="order.site">
              {{ order.site?.name }}
            </span>
            <ion-button v-else expand="block" fill="outlined" @click.prevent.stop="site_modal=true">
              {{ $t('global.select') }}
            </ion-button>
          </ion-item>


          <ion-item :button="true" :class="$style['field']" color="light" lines="none" @click="folder_modal=true">
            <ion-label>
              {{ $t('orders.new.folder_label') }}
            </ion-label>

            <span v-if="order.folder" :class="$style['folder-path']">
              {{ $t('orders.screen_title') }}
              <span v-for="folder in order.folder.split('/')" :key="folder">
                 / {{ $t('orders.folder_labels.' + folder) }}
              </span>
            </span>
            <ion-button v-else expand="block" fill="outlined" @click.prevent.stop="folder_modal=true">
              {{ $t('global.select') }}
            </ion-button>
          </ion-item>

          <ion-item :button="true" :class="$style['field']"
                    :disabled="!order.folder||(folders?.length&&!this.order.folder)||loading" color="light" lines="none"
                    @click="provider_modal=true">
            <ion-label>
              {{ $t('orders.new.provider_label') }}
            </ion-label>
            <span v-if="order.provider">
              {{ order.provider?.name }}
            </span>
            <ion-button v-else expand="block" fill="outlined" @click.prevent.stop="provider_modal=true">
              {{ $t('global.select') }}
            </ion-button>
          </ion-item>

          <ProviderSelector :active="provider_modal" @change="order.provider=$event.value"
                            @close="provider_modal=false"/>

          <SiteSelector :active="site_modal" @change="order.site=$event.value"
                        @close="site_modal=false"/>

          <FolderSelector :active="folder_modal" :root="order?.site?'site':null" @change="order.folder=$event.value"
                          @close="folder_modal=false"/>


          <div :class="$style['actions']">
            <ion-button
                :disabled="(order.folder?.startsWith('site/')&&!order.site)||!order.folder||(folders?.length&&!this.order.folder)||!order.provider||loading"
                fill="solid" @click="create">
              {{ $t('orders.new.next_button_label') }}
              <ion-icon slot="end" :icon="arrowForward"/>
            </ion-button>
          </div>

        </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue";

import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonTitle,
  IonToolbar,
  loadingController
} from "@ionic/vue";

import { arrowForward } from "ionicons/icons";

import { Order } from "@/orders";
import { File } from "@/files";

import { useOrderStore } from "@/stores/order";
import { useUserStore } from "@/stores/user";

const ProviderSelector = defineAsyncComponent(() => import("@/components/data/selectors/ProviderSelector.vue"));
const SiteSelector = defineAsyncComponent(() => import("@/components/data/selectors/SiteSelector.vue"));
const FolderSelector = defineAsyncComponent(() => import("@/components/data/selectors/FolderSelector.vue"));

export default defineComponent({
  components: {
    IonBackButton,
    //IonButton,
    IonContent,
    IonHeader,
    //IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    IonProgressBar,
    IonItem,
    IonLabel, ProviderSelector, SiteSelector, IonIcon, IonBadge, FolderSelector, IonButtons, IonButton
  },
  setup() {
    const orderDataset = useOrderStore();
    const user = useUserStore();
    return {
      orderDataset, user, arrowForward
    }
  },
  data() {
    return {
      order: {} as Order,
      folder: '',
      provider_modal: false,
      site_modal: false,
      folder_modal: false,
      loading: false
    }
  },
  methods: {
    uploadFiles() {
      // Prompt user for image or pdf files
      const input = document.createElement("input") as HTMLInputElement;
      input.type = "file";
      input.multiple = true;
      input.accept = "image/*,application/pdf";
      input.onchange = () => {
        // Input files to base64
        const files = input.files as FileList;
        const promises = [];
        for (let i = 0; i < files.length; i++) {
          promises.push(this.fileToBase64(files[i]));
        }
        Promise.all(promises).then(files => {
          this.order.files = (files.map(file => {
            return {
              source: file,
              type: "upload"
            }
          })) as File[];
        });
      }
      input.click();
    },
    fileToBase64(file: any) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        }
        reader.onerror = (error) => {
          reject(error);
        }
        reader.readAsDataURL(file);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    async create() {
      const loader = (await loadingController.create({
        message: this.$t('orders.new.creating_order_label'),
        duration: 10000
      }));

      await loader.present();

      this.axios.put('/orders', this.order).then((response) => {
        loader.dismiss();
        this.$router.push({name: 'orders.single', params: {uuid: response.data.uuid}});
      }).catch(() => {
        loader.dismiss();
      }).finally(() => {
        loader.dismiss();
      });
    },
  },
  computed: {
    progress() {
      const max_steps = 3;
      let step = 0;

      if (this.order.folder) {
        step++;
      }
      if (this.order.provider) {
        step++;
      }
      return step / max_steps;
    },
  }
});
</script>

<style lang="scss" module>
.wrapper {
  width: 100%;
  max-width: 740px;
  margin: auto;
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  height: 100%;

  .field {
    margin: 1em 0;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.folder-path {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  direction: rtl;
  text-align: right;
}
</style>