<template>
  <ion-chip v-if="loading" color="dark">
    <div :style="'width:100px'"></div>
  </ion-chip>
  <ion-chip v-else color="dark" @click.stop.prevent="($event)=>{event=$event; user_modal=true;}">
    <ion-avatar>
      <ion-img :src="role.user.profile_picture" loading="lazy"/>
    </ion-avatar>
    <ion-label :class="$style['label']">
       <span v-if="user.uuid === role.user.uuid">
          {{ $t("orders.fields.validation.you") }}
        </span>
      <span v-else>
        {{ role.user.display_name }}
      </span>
      <span v-if="display_role" :class="$style['role-container']">
            <ion-text :class="$style['role-name']" color="medium">({{
                $t('users.roles.' + role.role)
              }})
            </ion-text>
        </span>
    </ion-label>
  </ion-chip>

  <ion-popover :class="$style['infos-popover']" :event="event" :is-open="user_modal"
               @didDismiss="user_modal=false">
    <ion-content>
      <div :class="$style['popover-inner']">
        <ion-avatar>
          <ion-img :src="role.user.profile_picture" loading="lazy"/>
        </ion-avatar>
        <h3>
          {{ role.user.display_name }}
        </h3>
        <h4 v-if=" role.user.company_name && role.user.company_name !== role.user.display_name">
          {{ role.user.company_name }}
        </h4>
        <p v-if=" role.user.email">
          {{ role.user.email }}
        </p>
        <p v-if=" role.user.phone">
          {{ role.user.phone }}
        </p>
      </div>
    </ion-content>
  </ion-popover>

</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonAvatar, IonChip, IonContent, IonImg, IonLabel, IonPopover, IonText } from "@ionic/vue";

import { useUserStore } from "@/stores/user";

export default defineComponent({
  components: {
    IonChip,
    IonAvatar,
    IonLabel,
    IonText,
    IonImg, IonContent, IonPopover
  },
  props: ["user_data", "role_data", "display_role", "loading", "type"],
  data() {
    return {
      user_modal: false,
      event: null
    }
  },
  computed: {
    role() {
      if (this.$props.role_data) return this.$props.role_data;

      return {
        user: this.$props.user_data,
        role: "user"
      }
    }
  },
  setup() {
    const user = useUserStore();
    return {
      user
    };
  }
});
</script>


<style lang="scss" module>
.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70vw;
}

.role-name {
  text-transform: uppercase;
  font-weight: bold;
  font-size: .8em;
}

.role-container {
  margin-left: .333em;
}

.infos-popover {
  --width: 290px;

  .popover-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;

    p, h3, h4, ion-avatar {
      margin: .25em 0;
      padding: 0;
    }

    ion-avatar {
      width: 4em;
      height: 4em;
    }

    p {
      font-size: .9em;
    }

    h3 {
      font-size: 1.15em;
    }

    h4 {
      font-size: 1em;
    }
  }
}
</style>