import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import MainScreen from "@/views/MainScreen.vue";

import HomePage from '@/views/tabs/HomePage.vue';
import OrdersPage from '@/views/tabs/OrdersPage.vue';
import NewOrderWizard from '@/views/tabs/orders/NewOrderWizard.vue';
import OrderPage from '@/views/tabs/orders/OrderPage.vue';
import ControlSheetPage from '@/views/tabs/control_sheets/ControlSheetPage.vue';
import EquipmentPage from '@/views/tabs/EquipmentPage.vue';
import EquipmentEntryPage from '@/views/tabs/equipment/EquipmentEntryPage.vue';
import PlanningPage from '@/views/tabs/PlanningPage.vue';
import PlanningEntryPage from '@/views/tabs/planning/PlanningEntryPage.vue';
import PlanningValidationPage from '@/views/tabs/planning/PlanningValidationPage.vue';
import ControlSheetsPage from '@/views/tabs/ControlSheetsPage.vue';
import SiteEntryPage from '@/views/tabs/sites/SiteEntryPage.vue';
import UserPage from '@/views/tabs/UserPage.vue';
import ControlSheet from '@/views/tabs/forms/ControlSheet.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: MainScreen,

        children: [
            {
                path: "",
                redirect: "home",
            },
            {
                path: "home",
                name: "home",
                component: HomePage,
            },
            {
                path: "orders",
                name: "orders.list",
                component: OrdersPage,
            },
            {
                path: "orders/new",
                name: "orders.new",
                component: NewOrderWizard,
            },
            {
                path: "orders/:uuid",
                name: "orders.single",
                component: OrderPage,
            },
            {
                path: "equipment",
                name: "equipment.list",
                component: EquipmentPage,
            },
            {
                path: "equipment/:uuid",
                name: "equipment.single",
                component: EquipmentEntryPage,
            },
            {
                path: "planning",
                name: "planning.list",
                component: PlanningPage,
            },
            {
                path: "planning/:uuid",
                name: "planning.single",
                component: PlanningEntryPage,
            },
            {
                path: "planning/:uuid/validate",
                name: "planning.single.validate",
                component: PlanningValidationPage,
            },
            {
                path: "control-sheets",
                name: "control_sheets.list",
                component: ControlSheetsPage,
            },
            {
                path: "control-sheets/:uuid",
                name: "control_sheets.single",
                component: ControlSheetPage,
            },
            {
                path: "sites/:uuid",
                name: "sites.single",
                component: SiteEntryPage,
            },
            {
                path: "user/:section?",
                name: "user",
                component: UserPage,
            },
            {
                path: "forms/control-sheet",
                name: "forms.control_sheet",
                component: ControlSheet,
                meta: {
                    guest: true
                }
            }
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
