<template>
  <ion-page>

    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>
          {{ $t("control_sheets.screen_title") }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ContentSidebarWrapper side="left">
        <template v-slot:sidebar>

        </template>
        <template v-slot:content>

          <div v-if="loading">
            <ControlSheetsSkeleton/>
          </div>
          <div v-else-if="sheets.length">
            <ion-refresher slot="fixed" @ionRefresh="reloadUp">
              <ion-refresher-content></ion-refresher-content>
            </ion-refresher>


            <ListItem v-for="sheet in sheets" :key="sheet.uuid" :sheet="sheet"/>


            <ion-infinite-scroll
                id="infinite-scroll"
                threshold="500px"
                @ionInfinite="loadNextPage($event)"
            >
              <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>

          </div>
          <div v-else>
            <ion-text class="ion-text-center" color="medium">

              <p>
                {{ $t("control_sheets.no_result") }}
              </p>
            </ion-text>
          </div>

        </template>
      </ContentSidebarWrapper>
    </ion-content>

    <ion-fab slot="fixed" horizontal="end" vertical="bottom">
      <ion-fab-button color="primary" @click="$router.push('/forms/control-sheet')">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>

  </ion-page>
</template>
<script lang="ts">
import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue";
import { useUserStore } from "@/stores/user";
import { defineComponent } from "vue";
import moment from "moment";
import ListItem from "@/components/data/control-sheets/ListItem.vue";
import ControlSheetsSkeleton from "@/components/skeletons/ControlSheetsSkeleton.vue";
import {
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import { add } from "ionicons/icons";

export default defineComponent({
  components: {

    IonFabButton, IonFab, IonIcon, IonTitle,
    IonToolbar, IonButtons,
    ControlSheetsSkeleton,
    ListItem, IonContent, IonText, IonRefresherContent, IonRefresher,
    ContentSidebarWrapper, IonPage, IonMenuButton, IonHeader, IonInfiniteScroll, IonInfiniteScrollContent
  },
  data() {
    return {
      page: 1,
      loading: false,
      sheets: [] as any[],
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.axios.get('/control-sheets?page=' + this.page).then((response) => {
        this.loading = false;
        this.sheets = response.data.data;
      }).catch(() => {
        this.loading = false;
      });
    },
    loadNextPage(event: any) {
      this.page++;
      this.axios.get('/control-sheets?page=' + this.page).then((response) => {
        this.sheets.push(...response.data.data);
        event.target.complete();
        if (!response.data.next_page_url) {
          event.target.disabled = true;
        }
      }).catch(() => {
        event.target.complete();
      });
    },
    reloadUp(event: any) {
      this.page = 1;
      this.axios.get('/control-sheets?page=' + this.page).then((response) => {
        this.sheets = response.data.data;
        event.target.complete();
      }).catch(() => {
        event.target.complete();
      });
    }
  },
  setup() {
    const user = useUserStore();

    return {user, moment, add}
  },
  mounted() {
    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });

  }
});
</script>