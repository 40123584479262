<template>
  <div>

  </div>
</template>

<script>
import { useUserStore } from "@/stores/user";
import { alertController } from "@ionic/vue";

export default {
  data() {
    return {
      notificationsSupported: false,
      notificationsEnabled: false,
      buttonDisabled: false,
      serviceWorkerRegistation: null,
      subscription: null,
      message: null,
    }
  },
  methods: {
    async askUserForNotifications() {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('notifications')) {
          const value = cookie.split('=')[1];
          if (value === 'denied') {
            return;
          }
        }
      }

      const notifAlert = await alertController.create({
        header: 'Ne ratez aucune information!',
        // subHeader: 'A Sub Header Is Optional',
        message: 'Activez les notifications et recevez des alertes en temps réel.',
        buttons: [{
          text: 'Non merci',
          role: 'cancel',
          handler: () => {
            const date = new Date();
            date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
            document.cookie = `notifications=denied; expires=${date.toUTCString()}; path=/`;
          }
        }, {
          text: 'Activer les notifications',
          handler: () => {
            this.toggleSubscription()
          },
          role: 'confirm'
        }]
      });

      await notifAlert.present();
    },
    toggleSubscription() {
      if (this.notificationsSupported) {
        this.buttonDisabled = true
        // Find out if we need to create a subscription or delete it
        if (!this.notificationsEnabled) {
          // Ask permission and when granted, create new subscription
          Notification.requestPermission()
              .then(result => {
                // if granted, create new subscription
                if (result === 'granted') {
                  this.createSubscription()
                      .then(sub => {
                        this.subscription = sub
                        // store new subscription on the server
                        return this.axios.post(`user/subscription`, {
                          subscription: this.subscription,
                        })
                      })
                      .then(() => {
                        this.buttonDisabled = false
                        this.notificationsEnabled = true
                      })
                } else {
                  console.log('User did not granted permission')
                  // Ask user to enable notifications
                  alert("Les notifications sont bloquées. Veuillez ouvrir les préférences de votre navigateur ou cliquer sur le cadenas près de la barre d'adresse pour modifier vos préférences de notification.");
                }
              })
        } else {
          // Destroy subscription
          console.log('Disable subscription');
          if (this.subscription !== null) {
            // destroy on the server
            return this.axios.post(`user/subscription/delete`, {
              endpoint: this.subscription.endpoint,
            })
                // unsubscribe on the client
                .then(() => this.subscription.unsubscribe())
                .then(() => {
                  // update the data
                  this.notificationsEnabled = false
                  this.buttonDisabled = false
                  this.subscription = null
                })
          }
        }
      }
    },
    createSubscription() {
      console.log('ask for active service worker registration');
      if (this.serviceWorkerRegistation === null) {
        return navigator.serviceWorker.ready // returns a Promise, the active SW registration
            .then(swreg => {
              this.serviceWorkerRegistation = swreg
              return this.subscribe(this.serviceWorkerRegistation)
            })
      } else {
        return this.subscribe(this.serviceWorkerRegistation)
      }
    },
    getSubscription(swreg) {
      console.log('ask for available subscription');
      return swreg.pushManager.getSubscription()
    },
    subscribe(swreg) {
      console.log('create new subscription for this browser on this device');
      // create new subscription for this browser on this device
      const vapidPublicKey = 'BKkLK4CbuG1dFhvsRHRDEqdqNX6WuqzFwlo1eKYAy-PJSw8JUH5PdoTJ44nRh_3gxnC383UXQ-L4fVNuPWQmcQ4'; //process.env.VUE_APP_VAPID_PUBLIC_KEY
      const convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey)
      // return the subscription promise, we chain another then where we can send it to the server
      return swreg.pushManager.subscribe({
        userVisibleOnly: true,
        // This is for security. On the backend, we need to do something with the VAPID_PRIVATE_KEY
        // that you can find in .env to make this work in the end
        applicationServerKey: convertedVapidPublicKey
      })
    },
    findSubscription() {
      console.log('get active service worker registration');
      return navigator.serviceWorker.ready
          .then(swreg => {
            console.log('haal active subscription op');
            this.serviceWorkerRegistation = swreg
            return this.getSubscription(this.serviceWorkerRegistation)
          })
    },
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
          // eslint-disable-next-line no-useless-escape
          .replace(/\-/g, '+')
          .replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    setupNotificationsProcess() {
      this.findSubscription()
          .then(sub => {
            if (sub === null) {
              console.log('no active subscription found on the client', sub);
              this.buttonDisabled = false
              this.notificationsEnabled = false

              this.askUserForNotifications();
            } else {
              console.log('Active subscription found', sub);
              // retrieve user info from API
              this.buttonDisabled = false
              this.notificationsEnabled = true
              this.subscription = sub
            }
          })
    }
  },
  created() {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      this.notificationsSupported = true
    }
  },
  setup() {
    const user = useUserStore();

    return {user}
  },
  mounted() {
    if (this.user.is_logged_in)
      this.setupNotificationsProcess();

    this.$watch("user.is_logged_in", () => {
      if (this.user.is_logged_in)
        this.setupNotificationsProcess();
    });
  },
}
</script>