<template>


  <div :class="$style['header']">
    <ion-img :alt="title" :class="$style['header-image']" :src="image" :title="title"></ion-img>
    <div :class="$style['header-content']">
      <ion-grid :fixed="true">
        <ion-row>
          <ion-col>
            <div>
              <ion-text v-if="title">
                <h1 :class="$style['header-title']">
                  {{ title }}
                </h1>
              </ion-text>
              <ion-text v-if="subtitle" color="medium">
                <p :class="$style['header-subtitle']">
                  {{ subtitle }}
                </p>
              </ion-text>

              <div v-if="$slots.default" :class="$style['header-slot']">
                <slot/>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonCol, IonGrid, IonRow } from "@ionic/vue";

export default defineComponent({
  components: {IonCol, IonRow, IonGrid},
  props: ['image', 'title', 'subtitle']
});
</script>

<style lang="scss" module>
.header {
  position: relative;
  overflow: hidden;

  &-content {
    padding-top: 10em;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, .7), rgba(255, 255, 255, 1));
  }

  &-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &::part(image) {
      filter: grayscale(1) brightness(1.5);
      opacity: .9;
      object-fit: cover;
      object-position: center;
    }
  }

  &-title,
  &-subtitle {
    margin: 0;
    padding: 0;
    text-shadow: 0 0 2em rgba(255, 255, 255, 1);
  }

  &-slot {
    margin-top: 1em;
  }
}
</style>