import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_modal, {
      "is-open": _ctx.is_open,
      onWillDismiss: _cache[5] || (_cache[5] = ($event: any) => (_ctx.is_open=false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('widgets.site_selector.title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_buttons, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.is_open=false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.close')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_searchbar, {
              modelValue: _ctx.site_search,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.site_search) = $event)),
              placeholder: _ctx.$t('global.search')
            }, null, 8, ["modelValue", "placeholder"]),
            (!_ctx.first_loading&&_ctx.filtered_sites.length)
              ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtered_sites, (site) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: site.uuid,
                        button: true,
                        onClick: ($event: any) => (_ctx.selectSite(site))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_avatar, { slot: "start" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_img, {
                                class: _normalizeClass(_ctx.$style['site-image']),
                                src: site.satellite_image_url
                              }, null, 8, ["class", "src"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, [
                                _createElementVNode("span", null, _toDisplayString(site.name), 1)
                              ]),
                              (site.address||site.reference)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
                                    (site.reference)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                                          _createTextVNode(" Réf. " + _toDisplayString(site.reference) + " ", 1),
                                          (site.address)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " — "))
                                            : _createCommentVNode("", true)
                                        ]))
                                      : _createCommentVNode("", true),
                                    (site.address)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                                          (site.address.street)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(site.address.street) + " ", 1))
                                            : _createCommentVNode("", true),
                                          (site.address.number)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(site.address.number) + " ", 1))
                                            : _createCommentVNode("", true),
                                          ((site.address.street || site.address.number) && (site.address.zip||site.address.city))
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, " — "))
                                            : _createCommentVNode("", true),
                                          (site.address.zip)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(site.address.zip) + " ", 1))
                                            : _createCommentVNode("", true),
                                          (site.address.city)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(site.address.city) + " ", 1))
                                            : _createCommentVNode("", true)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128)),
                    false
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 0,
                          button: true,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.edit_site={}; _ctx.edit_modal=true})
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_avatar, { slot: "start" }),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h2", null, _toDisplayString(_ctx.$t('widgets.site_selector.new_site')), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.$t('widgets.site_selector.new_site_description')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_infinite_scroll, {
                      disabled: _ctx.loading,
                      hidden: _ctx.has_more||_ctx.loading,
                      threshold: "200px",
                      onIonInfinite: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadSites($event)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_infinite_scroll_content)
                      ]),
                      _: 1
                    }, 8, ["disabled", "hidden"])
                  ]),
                  _: 1
                }))
              : (!_ctx.first_loading)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass({
        'ion-padding':true,
        [_ctx.$style['no-result']]:true
       })
                  }, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('widgets.site_selector.no_result')), 1),
                    (0)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 0,
                          onClick: _cache[4] || (_cache[4] = ($event: any) => {_ctx.edit_site={}; _ctx.edit_modal=true})
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('widgets.site_selector.new_site')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ], 2))
                : (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: _normalizeClass({
      'ion-padding':true,
      [_ctx.$style['no-result']]:true
    })
                    }, [
                      _createVNode(_component_ion_spinner)
                    ], 2))
                  : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open"]),
    _createVNode(_component_ion_modal, {
      "is-open": _ctx.edit_modal,
      onWillDismiss: _cache[7] || (_cache[7] = ($event: any) => (_ctx.edit_modal=false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('widgets.site_selector.edit.title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_buttons, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.edit_modal=false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.close')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content)
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 64))
}