import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["accept", "capture", "disabled", "multiple"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_img = _resolveComponent("ion-img")!

  return (!_ctx.value||!_ctx.value.length)
    ? (_openBlock(), _createElementBlock("label", {
        key: 0,
        class: _normalizeClass(_ctx.$style['field'])
      }, [
        _createElementVNode("input", {
          accept: _ctx.accept,
          capture: _ctx.capture,
          disabled: _ctx.loading||_ctx.disabled,
          multiple: _ctx.multiple,
          type: "file",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args)))
        }, null, 40, _hoisted_1),
        _createVNode(_component_ion_button, {
          disabled: _ctx.loading||_ctx.disabled
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "icon-only",
              icon: _ctx.cloudUploadOutline
            }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["disabled"])
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(_ctx.$style['field'])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value_previews, (file, index) => {
          return (_openBlock(), _createBlock(_component_ion_img, {
            key: index,
            class: _normalizeClass(_ctx.$style['preview-image']),
            src: file
          }, null, 8, ["class", "src"]))
        }), 128)),
        _createVNode(_component_ion_button, {
          color: "default",
          onClick: _ctx.remove
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "icon-only",
              icon: _ctx.closeOutline,
              color: "danger"
            }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["onClick"])
      ], 2))
}