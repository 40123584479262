import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonSpinner = _resolveComponent("IonSpinner")!
  const _component_HistoryBubble = _resolveComponent("HistoryBubble")!
  const _component_MessageBubble = _resolveComponent("MessageBubble")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['wrapper'])
  }, [
    (!_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_IonSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style['wrapper'])
        }, [
          _createVNode(_component_ion_content, {
            ref: "list",
            class: _normalizeClass(_ctx.$style['messages']+' ion-padding')
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  (message.type==='history')
                    ? (_openBlock(), _createBlock(_component_HistoryBubble, {
                        key: 0,
                        message: message
                      }, null, 8, ["message"]))
                    : (_openBlock(), _createBlock(_component_MessageBubble, {
                        key: 1,
                        message: message
                      }, null, 8, ["message"]))
                ]))
              }), 128))
            ]),
            _: 1
          }, 8, ["class"]),
          _createElementVNode("form", {
            class: _normalizeClass(_ctx.$style['write']+' ion-padding'),
            onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args)))
          }, [
            _createVNode(_component_ion_textarea, {
              ref: "messageInput",
              modelValue: _ctx.new_message,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.new_message) = $event)),
              class: _normalizeClass(_ctx.$style['message']),
              disabled: _ctx.send_loading,
              placeholder: _ctx.$t('messages.new_placeholder')
            }, null, 8, ["modelValue", "class", "disabled", "placeholder"]),
            _createVNode(_component_ion_button, {
              disabled: _ctx.send_loading||!_ctx.new_message,
              fill: "none",
              type: "submit",
              onClick: _ctx.sendMessage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.send,
                  color: "primary"
                }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ], 34)
        ], 2))
  ], 2))
}