<template>
  <ion-item :button="true" @click="$router.push('/equipment/' + equipment.uuid)">
    <div :class="$style['inner']">
      <div>
        <h4>{{ equipment.display_name }}</h4>

        <p>
          {{ equipment.reference }}
        </p>

        <p v-if="lastTracker?.action==='check:out'">
          <ion-icon :icon="userIcon"></ion-icon>
          {{ lastUser?.display_name }}
        </p>

        <p v-if="lastTracker?.action==='check:out'&&lastUser">
          <ion-icon :icon="pinIcon"></ion-icon>
          {{ lastTracker?.site?.display_name }}
        </p>

      </div>

      <div :class="$style['right']">
        <p v-if="lastTracker?.action">
          {{ $t('equipment.tracker.events.' + lastTracker.action) }}
        </p>

        <p v-if="lastTracker" :class="$style['date']">
          {{
            moment(lastTracker.tracked_at) > moment().subtract(14, 'days') ? moment(lastTracker.tracked_at).fromNow() : moment(lastTracker.tracked_at).format('LL')
          }}
        </p>

        <div :class="$style['user-pictures']">
          <img v-for="role in roles" :key="role.user.uuid" :src="role.user.profile_picture" loading="lazy"/>
        </div>
      </div>
    </div>
  </ion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useUserStore } from "@/stores/user";

import { IonIcon, IonItem } from "@ionic/vue";

import moment from "moment";

import pinIcon from "iconoir/icons/regular/pin.svg";
import userIcon from "iconoir/icons/regular/user.svg";
import { UserRole } from "@/users";

export default defineComponent({
  props: ["equipment"],
  components: {
    IonItem, IonIcon
  },
  setup() {
    const user = useUserStore();
    return {user, moment, pinIcon, userIcon};
  },
  computed: {
    lastTracker() {
      const tracker = this.equipment.tracker;
      return tracker.sort((a: any, b: any) => new Date(b.tracked_at).getTime() - new Date(a.tracked_at).getTime())[0];
    },
    roles() {
      return this.lastTracker?.roles ?? [];
    },
    lastUser() {
      return this.lastTracker?.roles?.find((role: UserRole) => role.role === 'user')?.user ?? null;
    }
  }
});
</script>

<style lang="scss" module>
.inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  font-size: .9em;

  @media(max-width: 990px) {
    font-size: .8em;
  }

  @media(max-width: 700px) {
    flex-direction: column
  }

  h4, p {
    padding: 0;
    margin: 0;
  }

  p {
    margin-top: .25em;
    color: var(--ion-color-medium);
  }

  .right {
    display: flex;
    align-items: center;

    @media(min-width: 701px) {
      flex-direction: column;
      align-items: flex-end;
    }

    @media(max-width: 700px) {
      .date {
        &:before {
          content: '—';
          margin: 0 .5em;
          margin-top: 0;
        }
      }
    }
  }
}

.user-pictures {
  @media(max-width: 700px) {
    display: none;
  }

  $size: 2em;

  margin-top: 1em;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  justify-content: flex-end;

  margin-left: $size*.2;

  img {
    width: $size;
    height: $size;
    border-radius: 50%;
    margin-left: -$size*.25;
    object-fit: cover;
    border: $size*.05 solid var(--background);
  }
}
</style>