import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EquipmentSelector = _resolveComponent("EquipmentSelector")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_EquipmentSelector, {
      active: _ctx.open,
      filter: _ctx.filter,
      onChange: _ctx.change,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open=false))
    }, null, 8, ["active", "filter", "onChange"]),
    _createVNode(_component_ion_button, {
      color: "light",
      expand: "full",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open=true))
    }, {
      default: _withCtx(() => [
        (!_ctx.value)
          ? (_openBlock(), _createBlock(_component_ion_text, {
              key: 0,
              color: "medium"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('global.select')), 1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.value), 1))
      ]),
      _: 1
    })
  ]))
}