<template>
  <div :class="$style['site']">
    <img v-if="site?.satellite_image_url" :src="site?.satellite_image_url" loading="lazy"/>
    <div :class="$style['inner']">
      <h2>
        <span>{{ site.display_name }}</span>
      </h2>
      <p v-if="site.address||site.reference">
        <span v-if="site.reference">
           Réf. {{ site.reference }}
        <span v-if="site.address"> — </span>
        </span>
        <span v-if="site.address">
          <span v-if="site.address.street">{{ site.address.street }}&nbsp;</span>
          <span v-if="site.address.number">{{ site.address.number }}&nbsp;</span>
          <span v-if="(site.address.street || site.address.number) && (site.address.zip||site.address.city)"> — </span>
          <span v-if="site.address.zip">{{ site.address.zip }}&nbsp;</span>
          <span v-if="site.address.city">{{ site.address.city }}&nbsp;</span>
        </span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
// @click="$router.push('/sites/'+(site?.uuid) )"
import { defineComponent } from "vue";

export default defineComponent({
  props: ['site']
});

</script>

<style lang="scss" module>
$innerColor: 'primary';

.site {
  background: var(--ion-color-medium);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  > img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    pointer-events: none;
    filter: brightness(1.5) grayscale(1);
  }

  .inner {
    display: flex;
    padding: 1em;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2;
    color: var(--ion-color-primary-contrast);
    background: linear-gradient(0deg, rgba(var(--ion-color-#{$innerColor}-rgb), .6) 0%, rgba(var(--ion-color-#{$innerColor}-rgb), 0.1) 100%);
    text-shadow: 0 0 2em rgba(0, 0, 0, .333);

    h2, p {
      margin: 0;
      padding: 0;
    }

    p {
      margin-top: .75em;
      text-transform: uppercase;
      font-weight: bold;
      font-size: .9em;
    }

    h2 {
      margin-top: 5em;

    }
  }
}
</style>