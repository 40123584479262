<template>
  <ion-modal :is-open="is_open"
             @willDismiss="is_open=false">

    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ $t('widgets.site_selector.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="is_open=false">
            {{ $t('global.close') }}
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-searchbar v-model="site_search" :placeholder="$t('global.search')"></ion-searchbar>
      <ion-list v-if="!first_loading&&filtered_sites.length">
        <ion-item v-for="site in filtered_sites" :key="site.uuid" :button="true"
                  @click="selectSite(site)">
          <ion-avatar slot="start">
            <ion-img :class="$style['site-image']" :src="site.satellite_image_url"></ion-img>
          </ion-avatar>
          <ion-label>
            <h2>
              <span>{{ site.name }}</span>
            </h2>
            <p v-if="site.address||site.reference">
        <span v-if="site.reference">
           Réf. {{ site.reference }}
        <span v-if="site.address"> — </span>
        </span>
              <span v-if="site.address">
          <span v-if="site.address.street">{{ site.address.street }}&nbsp;</span>
          <span v-if="site.address.number">{{ site.address.number }}&nbsp;</span>
                <span
                    v-if="(site.address.street || site.address.number) && (site.address.zip||site.address.city)"> — </span>
          <span v-if="site.address.zip">{{ site.address.zip }}&nbsp;</span>
          <span v-if="site.address.city">{{ site.address.city }}&nbsp;</span>
        </span>
            </p>
          </ion-label>
        </ion-item>

        <ion-item v-if="false" :button="true" @click="edit_site={}; edit_modal=true">
          <ion-avatar slot="start">
          </ion-avatar>

          <ion-label>
            <h2>
              {{ $t('widgets.site_selector.new_site') }}
            </h2>
            <p>
              {{ $t('widgets.site_selector.new_site_description') }}
            </p>
          </ion-label>
        </ion-item>


        <ion-infinite-scroll
            :disabled="loading"
            :hidden="has_more||loading"
            threshold="200px"
            @ionInfinite="loadSites($event)"
        >
          <ion-infinite-scroll-content/>
        </ion-infinite-scroll>

      </ion-list>
      <div v-else-if="!first_loading" :class="{
        'ion-padding':true,
        [$style['no-result']]:true
       }">
        <p>
          {{ $t('widgets.site_selector.no_result') }}</p>
        <ion-button v-if="0" @click="edit_site={}; edit_modal=true">
          {{ $t('widgets.site_selector.new_site') }}
        </ion-button>
      </div>
      <div v-else-if="loading" :class="{
      'ion-padding':true,
      [$style['no-result']]:true
    }">
        <ion-spinner></ion-spinner>
      </div>
    </ion-content>
  </ion-modal>

  <ion-modal :is-open="edit_modal"
             @willDismiss="edit_modal=false">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ $t('widgets.site_selector.edit.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="edit_modal=false">
            {{ $t('global.close') }}
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>

    </ion-content>

  </ion-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  InfiniteScrollCustomEvent,
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import { Site } from "@/sites";
import { useUserStore } from "@/stores/user";

export default defineComponent({
  props: ['active', 'page'],
  emits: ['close', 'change'],

  data() {
    return {
      sites: [] as Site[],
      site_search: "",
      first_loading: true,
      loading: false,
      query_page: 1,
      has_more: true,
      edit_modal: false,
      edit_site: {} as Site,
    };
  },
  methods: {
    loadSites(infinite_event?: InfiniteScrollCustomEvent) {
      if (this.loading) {
        return;
      }

      const user = useUserStore();

      this.loading = true;
      this.axios.get("sites" +
          (!user.is_logged_in ? '/guest' : '')
          + '?page=' + this.query_page
      ).then((response) => {
        this.sites = [...this.sites, ...response.data.data];
        this.query_page++;
        this.has_more = !!response.data.data.length;
        this.loading = false;
        this.first_loading = false;


        if (infinite_event) {
          infinite_event.target.complete();
        }
      });
    },
    selectSite(site: Site) {
      this.$emit('change', {value: site});
      this.is_open = false;
    }
  },
  computed: {
    is_open: {
      get() {
        return this.$props.active;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('close');
        }
      }
    },
    filtered_sites() {
      return this.sites.filter(site => {
        const index = [
          site.name,
          site.reference,
          site.address?.street,
          site.address?.zip,
          site.address?.city
        ];

        return index.join(' ').toLowerCase().includes(this.site_search.toLowerCase());
      });
    }
  },
  watch: {
    active(value: boolean) {
      if (value) {
        this.loadSites();
      }
    }
  },
  components: {
    IonInfiniteScroll, IonInfiniteScrollContent,
    IonAvatar,
    IonContent,
    IonHeader,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonSearchbar, IonModal, IonSpinner, IonTitle, IonButton, IonButtons, IonToolbar
  }
});
</script>

<style lang="scss" module>
.no-result {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 70%;

  > * {
    margin: .5em 0;
  }
}


</style>