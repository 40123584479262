import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "ion-padding"
}
const _hoisted_4 = { slot: "content" }
const _hoisted_5 = { slot: "content" }
const _hoisted_6 = { slot: "content" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { slot: "content" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_FilesPreview = _resolveComponent("FilesPreview")!
  const _component_SiteBlock = _resolveComponent("SiteBlock")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_SheetStatus = _resolveComponent("SheetStatus")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ContentSidebarWrapper = _resolveComponent("ContentSidebarWrapper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    text: _ctx.$t('control_sheets.back_button_label'),
                    "default-href": "/control-sheets"
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.sheet?.display_name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ContentSidebarWrapper, null, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h1", null, _toDisplayString(_ctx.sheet?.equipment?.name), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.sheet?.description), 1),
                (_ctx.sheet?.cause)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t("control_sheets.fields.cause")), 1),
                      _createElementVNode("div", null, _toDisplayString(_ctx.sheet?.cause), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.sheet?.files?.length)
                ? (_openBlock(), _createBlock(_component_FilesPreview, {
                    key: 0,
                    files: _ctx.sheet?.files,
                    class: "ion-padding"
                  }, null, 8, ["files"]))
                : _createCommentVNode("", true),
              (_ctx.sheet?.site)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_SiteBlock, {
                      site: _ctx.sheet?.site
                    }, null, 8, ["site"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            sidebar: _withCtx(() => [
              _createVNode(_component_ion_accordion_group, {
                disabled: !_ctx.loaded,
                multiple: true,
                value: _ctx.loaded ? ['informations', 'user', 'validation'] : null
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_accordion, { value: "informations" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        slot: "header",
                        color: "light"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.tabs.informations")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.fields.reference")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.sheet?.reference), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.fields.status")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_SheetStatus, { sheet: _ctx.sheet }, null, 8, ["sheet"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        (_ctx.sheet?.level)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 0,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.fields.level")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.levels." + _ctx.sheet?.level)), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.fields.date")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.moment(_ctx.sheet?.submitted_at) > _ctx.moment().subtract(5, "days")
                        ? _ctx.moment(_ctx.sheet?.submitted_at).fromNow()
                        : _ctx.moment(_ctx.sheet?.submitted_at).format("LLL")), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_accordion, { value: "user" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        slot: "header",
                        color: "light"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.tabs.user")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_5, [
                        (_ctx.sheet?.user_first_name || _ctx.sheet?.user_last_name)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 0,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.fields.first_name")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.sheet?.user_first_name), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_ion_item, {
                              key: 1,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.fields.first_name")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.sheet?.first_name), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })),
                        (_ctx.sheet?.user_first_name || _ctx.sheet?.user_last_name)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 2,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.fields.last_name")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.sheet?.user_last_name), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_ion_item, {
                              key: 3,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.fields.last_name")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.sheet?.last_name), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })),
                        (_ctx.sheet?.user_first_name || _ctx.sheet?.user_last_name)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 4,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.fields.filled_by")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.sheet?.first_name) + " " + _toDisplayString(_ctx.sheet?.last_name), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.sheet?.email?.length)
                          ? (_openBlock(), _createBlock(_component_ion_item, { key: 5 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.fields.email")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.sheet?.email), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_accordion, { value: "roles" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        slot: "header",
                        color: "light"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.tabs.roles")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.roles, (role) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: role.uuid,
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_avatar, { slot: "start" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_img, {
                                    src: role.user.profile_picture
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  (_ctx.user.uuid === role.user.uuid)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("control_sheets.fields.validation.you")), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(role.user.display_name), 1))
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, { slot: "end" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_text, { color: "medium" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("users.roles." + role.role)), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }),
                  (_ctx.sheet?.validations?.length)
                    ? (_openBlock(), _createBlock(_component_ion_accordion, {
                        key: 0,
                        value: "validation"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            slot: "header",
                            color: "light"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("control_sheets.tabs.validation")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_9, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.validations, (validation) => {
                              return (_openBlock(), _createBlock(_component_ion_item, {
                                key: validation.user.uuid,
                                lines: "none"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_avatar, { slot: "start" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_img, {
                                        src: validation.user.profile_picture
                                      }, null, 8, ["src"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      (_ctx.user.uuid === validation.user.uuid)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("control_sheets.fields.validation.you")), 1))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(validation.user.display_name), 1))
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_label, { slot: "end" }, {
                                    default: _withCtx(() => [
                                      (validation.approved)
                                        ? (_openBlock(), _createBlock(_component_ion_text, {
                                            key: 0,
                                            color: "success"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                                            ]),
                                            _: 1
                                          }))
                                        : (validation.refused)
                                          ? (_openBlock(), _createBlock(_component_ion_text, {
                                              key: 1,
                                              color: "danger"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                                              ]),
                                              _: 1
                                            }))
                                          : (_openBlock(), _createBlock(_component_ion_text, {
                                              key: 2,
                                              color: "warning"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, { icon: _ctx.hourglassOutline }, null, 8, ["icon"])
                                              ]),
                                              _: 1
                                            }))
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled", "value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}