<template>
  <ion-spinner v-if="order?.sync_needed"/>
  <ion-text v-else :class="$style['status']"
            :color="
                        order?.status?.includes('validation')
                          ? 'warning'
                          : order?.status === 'done'
                          ? 'success'
                          : order?.status === 'refused'
                          ? 'danger'
                          : order?.status === 'draft'
                          ? 'medium'
                          : 'dark'
                      "
  >
    {{ $t("orders.status." + (order?.status ?? "unknown")) }}
  </ion-text>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonSpinner, IonText } from "@ionic/vue";

export default defineComponent({
  props: ['order'],
  components: {IonSpinner, IonText}
});
</script>

<style lang="scss" module>
.status {
  font-weight: bold;
}
</style>