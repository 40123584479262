export default {
    formatPrice: (value: number | string): string => {
        if (typeof value !== 'number') {
            value = parseFloat((value ?? '0'));
        }

        const browser_locale = navigator.language;
        return new Intl.NumberFormat(browser_locale ?? "fr-BE", {
            style: "currency",
            currency: "EUR",
        }).format(value);
    },
    truncate: (text: string, length: number): string => {
        if (text.length > length) {
            return text.substring(0, length) + "...";
        }
        return text;
    }
};
