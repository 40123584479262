<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button
            :text="$t('control_sheets.back_button_label')"
            default-href="/control-sheets"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ sheet?.display_name }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ContentSidebarWrapper>
        <template v-slot:content>
          <div class="ion-padding">
            <h1>
              {{ sheet?.equipment?.name }}
            </h1>
            <div>{{ sheet?.description }}</div>

            <div v-if="sheet?.cause">
              <h3>{{ $t("control_sheets.fields.cause") }}</h3>
              <div>{{ sheet?.cause }}</div>
            </div>
          </div>

          <FilesPreview
            v-if="sheet?.files?.length"
            :files="sheet?.files"
            class="ion-padding"
          ></FilesPreview>

          <div v-if="sheet?.site" class="ion-padding">
            <SiteBlock :site="sheet?.site" />
          </div>
        </template>

        <template v-slot:sidebar>
          <ion-accordion-group
            :disabled="!loaded"
            :multiple="true"
            :value="loaded ? ['informations', 'user', 'validation'] : null"
          >
            <ion-accordion value="informations">
              <ion-item slot="header" color="light">
                <ion-label>
                  {{ $t("control_sheets.tabs.informations") }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-item lines="none">
                  <ion-label>{{
                    $t("control_sheets.fields.reference")
                  }}</ion-label>
                  <ion-label slot="end"> {{ sheet?.reference }}</ion-label>
                </ion-item>
                <ion-item lines="none">
                  <ion-label>{{
                    $t("control_sheets.fields.status")
                  }}</ion-label>
                  <ion-label slot="end">
                    <SheetStatus :sheet="sheet"></SheetStatus>
                  </ion-label>
                </ion-item>
                <ion-item v-if="sheet?.level" lines="none">
                  <ion-label>{{ $t("control_sheets.fields.level") }}</ion-label>
                  <ion-label slot="end">
                    {{ $t("control_sheets.levels." + sheet?.level) }}
                  </ion-label>
                </ion-item>
                <ion-item lines="none">
                  <ion-label>{{ $t("control_sheets.fields.date") }}</ion-label>
                  <ion-label slot="end">
                    {{
                      moment(sheet?.submitted_at) > moment().subtract(5, "days")
                        ? moment(sheet?.submitted_at).fromNow()
                        : moment(sheet?.submitted_at).format("LLL")
                    }}
                  </ion-label>
                </ion-item>
              </div>
            </ion-accordion>
            <ion-accordion value="user">
              <ion-item slot="header" color="light">
                <ion-label>
                  {{ $t("control_sheets.tabs.user") }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-item
                  v-if="sheet?.user_first_name || sheet?.user_last_name"
                  lines="none"
                >
                  <ion-label>{{
                    $t("control_sheets.fields.first_name")
                  }}</ion-label>
                  <ion-label slot="end">
                    {{ sheet?.user_first_name }}</ion-label
                  >
                </ion-item>
                <ion-item v-else lines="none">
                  <ion-label>{{
                    $t("control_sheets.fields.first_name")
                  }}</ion-label>
                  <ion-label slot="end"> {{ sheet?.first_name }}</ion-label>
                </ion-item>
                <ion-item
                  v-if="sheet?.user_first_name || sheet?.user_last_name"
                  lines="none"
                >
                  <ion-label>{{
                    $t("control_sheets.fields.last_name")
                  }}</ion-label>
                  <ion-label slot="end"> {{ sheet?.user_last_name }}</ion-label>
                </ion-item>
                <ion-item v-else lines="none">
                  <ion-label>{{
                    $t("control_sheets.fields.last_name")
                  }}</ion-label>
                  <ion-label slot="end"> {{ sheet?.last_name }}</ion-label>
                </ion-item>
                <ion-item
                  v-if="sheet?.user_first_name || sheet?.user_last_name"
                  lines="none"
                >
                  <ion-label>{{
                    $t("control_sheets.fields.filled_by")
                  }}</ion-label>
                  <ion-label slot="end">
                    {{ sheet?.first_name }} {{ sheet?.last_name }}</ion-label
                  >
                </ion-item>
                <ion-item v-if="sheet?.email?.length">
                  <ion-label>{{ $t("control_sheets.fields.email") }}</ion-label>
                  <ion-label slot="end"> {{ sheet?.email }}</ion-label>
                </ion-item>
              </div>
            </ion-accordion>
            <ion-accordion value="roles">
              <ion-item slot="header" color="light">
                <ion-label>
                  {{ $t("control_sheets.tabs.roles") }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-item
                  v-for="role in sheet?.roles"
                  :key="role.uuid"
                  lines="none"
                >
                  <ion-avatar slot="start">
                    <ion-img :src="role.user.profile_picture"></ion-img>
                  </ion-avatar>
                  <ion-label>
                    <span v-if="user.uuid === role.user.uuid">
                      {{ $t("control_sheets.fields.validation.you") }}
                    </span>
                    <span v-else>
                      {{ role.user.display_name }}
                    </span>
                  </ion-label>

                  <ion-label slot="end">
                    <ion-text color="medium">
                      {{ $t("users.roles." + role.role) }}
                    </ion-text>
                  </ion-label>
                </ion-item>
              </div>
            </ion-accordion>

            <ion-accordion v-if="sheet?.validations?.length" value="validation">
              <ion-item slot="header" color="light">
                <ion-label>
                  {{ $t("control_sheets.tabs.validation") }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-item
                  v-for="validation in sheet?.validations"
                  :key="validation.user.uuid"
                  lines="none"
                >
                  <ion-avatar slot="start">
                    <ion-img :src="validation.user.profile_picture"></ion-img>
                  </ion-avatar>
                  <ion-label>
                    <span v-if="user.uuid === validation.user.uuid">
                      {{ $t("control_sheets.fields.validation.you") }}
                    </span>
                    <span v-else>
                      {{ validation.user.display_name }}
                    </span>
                  </ion-label>

                  <ion-label slot="end">
                    <ion-text v-if="validation.approved" color="success">
                      <ion-icon :icon="checkmarkOutline" />
                    </ion-text>
                    <ion-text v-else-if="validation.refused" color="danger">
                      <ion-icon :icon="close" />
                    </ion-text>
                    <ion-text v-else color="warning">
                      <ion-icon :icon="hourglassOutline" />
                    </ion-text>
                  </ion-label>
                </ion-item>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </template>
      </ContentSidebarWrapper>
    </ion-content>
  </ion-page>
</template>
<script lang="ts">
import {
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  IonToolbar,
} from "@ionic/vue";

import { defineComponent } from "vue";
import { ControlSheet } from "@/control-sheets";
import { useUserStore } from "@/stores/user";
import FilesPreview from "@/components/data/files/FilesPreview.vue";
import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue";
import SheetStatus from "@/components/data/control-sheets/SheetStatus.vue";
import moment from "moment";
import SiteBlock from "@/components/data/site/SiteBlock.vue";
import { checkmarkOutline, close, hourglassOutline } from "ionicons/icons";

export default defineComponent({
  components: {
    IonIcon,
    SiteBlock,
    IonText,
    IonImg,
    IonAvatar,
    SheetStatus,
    IonLabel,
    IonItem,
    IonAccordion,
    IonAccordionGroup,
    ContentSidebarWrapper,
    FilesPreview,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
  },
  setup() {
    const user = useUserStore();
    return {
      user,
      moment,
      checkmarkOutline,
      close,
      hourglassOutline,
    };
  },
  data() {
    return {
      loaded: false,
      sheet: null as ControlSheet | null,
    };
  },
  methods: {
    load() {
      this.axios
        .get("/control-sheets/" + this.$route.params.uuid)
        .then((response: any) => {
          this.sheet = response.data;
          this.loaded = true;
        });
    },
  },
  mounted() {
    if (this.user.is_logged_in) this.load();

    this.$watch("user.is_logged_in", (is_logged_in: boolean) => {
      if (is_logged_in) this.load();
    });
  },
});
</script>
