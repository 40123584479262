<template>
  <ion-card :button="!!statItem.link" :class="$style['card']" @click="statItem.link && $router.push(formattedLink)">
    <ion-icon v-if="!statItem.count" :class="$style['icon']" :icon="checkmark" color="success" size="large"></ion-icon>
    <ion-icon v-else :class="$style['icon']" :icon="warning" color="secondary" size="large"></ion-icon>

    <ion-card-header>
      <ion-card-title :class="$style['title']">{{ statItem.count }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-card-subtitle>
        {{ $t('stats.labels.' + statItem.name) }}
      </ion-card-subtitle>
    </ion-card-content>

    <ion-button v-if="statItem.count" :class="$style['button']" fill="clear">
      {{ $t('stats.actions.more') }}
      <ion-icon slot="end" :icon="arrowForwardOutline"></ion-icon>
    </ion-button>
  </ion-card>
</template>
<style lang="scss" module>
.card {
  margin-bottom: 0;
}

.button {
  float: right;
}

.title {
  font-size: 2.75em;
}

.icon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 1.5em;
  height: 1.5em;
  opacity: .75;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from "@ionic/vue";
import { Stat } from "@/stats";
import { arrowForwardOutline, checkmark, warning } from "ionicons/icons";

export default defineComponent({
  components: {
    IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonIcon, IonButton
  },
  props: ['stat'],
  computed: {
    statItem() {
      return this.stat as Stat;
    },
    formattedLink() {
      const link = this.statItem.link;
      const params = this.statItem.link_params;

      if (!link) return null;
      if (!params) return link;

      const formattedParams = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
      return `${link}?${formattedParams}`;
    }
  },
  setup() {
    return {arrowForwardOutline, checkmark, warning}
  }
});
</script>