import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ProviderSelector = _resolveComponent("ProviderSelector")!
  const _component_SiteSelector = _resolveComponent("SiteSelector")!
  const _component_FolderSelector = _resolveComponent("FolderSelector")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    text: _ctx.$t('orders.back_button_label'),
                    "default-href": "/orders"
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('orders.new.screen_title')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_progress_bar, { value: _ctx.progress }, null, 8, ["value"])
        ]),
        _: 1
      }),
      (_ctx.user.is_logged_in)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style['wrapper'])
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t('orders.new.informations_label')), 1),
                  (0)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 0,
                        button: true,
                        class: _normalizeClass(_ctx.$style['field']),
                        disabled: _ctx.loading,
                        color: "light",
                        lines: "none",
                        onClick: _ctx.uploadFiles
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('orders.new.files')), 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.order.files?.length)
                            ? (_openBlock(), _createBlock(_component_ion_badge, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.order.files?.length), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_ion_button, {
                                key: 1,
                                expand: "block",
                                fill: "outlined",
                                onClick: _withModifiers(_ctx.uploadFiles, ["prevent","stop"])
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('global.select')), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                        ]),
                        _: 1
                      }, 8, ["class", "disabled", "onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_item, {
                    button: true,
                    class: _normalizeClass(_ctx.$style['field']),
                    disabled: _ctx.loading,
                    color: "light",
                    lines: "none",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.site_modal=true))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('orders.new.site_label')), 1)
                        ]),
                        _: 1
                      }),
                      (_ctx.order.site)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.order.site?.name), 1))
                        : (_openBlock(), _createBlock(_component_ion_button, {
                            key: 1,
                            expand: "block",
                            fill: "outlined",
                            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.site_modal=true), ["prevent","stop"]))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('global.select')), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  }, 8, ["class", "disabled"]),
                  _createVNode(_component_ion_item, {
                    button: true,
                    class: _normalizeClass(_ctx.$style['field']),
                    color: "light",
                    lines: "none",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.folder_modal=true))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('orders.new.folder_label')), 1)
                        ]),
                        _: 1
                      }),
                      (_ctx.order.folder)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['folder-path'])
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('orders.screen_title')) + " ", 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.folder.split('/'), (folder) => {
                              return (_openBlock(), _createElementBlock("span", { key: folder }, " / " + _toDisplayString(_ctx.$t('orders.folder_labels.' + folder)), 1))
                            }), 128))
                          ], 2))
                        : (_openBlock(), _createBlock(_component_ion_button, {
                            key: 1,
                            expand: "block",
                            fill: "outlined",
                            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.folder_modal=true), ["prevent","stop"]))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('global.select')), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  _createVNode(_component_ion_item, {
                    button: true,
                    class: _normalizeClass(_ctx.$style['field']),
                    disabled: !_ctx.order.folder||(_ctx.folders?.length&&!this.order.folder)||_ctx.loading,
                    color: "light",
                    lines: "none",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.provider_modal=true))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('orders.new.provider_label')), 1)
                        ]),
                        _: 1
                      }),
                      (_ctx.order.provider)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.order.provider?.name), 1))
                        : (_openBlock(), _createBlock(_component_ion_button, {
                            key: 1,
                            expand: "block",
                            fill: "outlined",
                            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.provider_modal=true), ["prevent","stop"]))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('global.select')), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  }, 8, ["class", "disabled"]),
                  _createVNode(_component_ProviderSelector, {
                    active: _ctx.provider_modal,
                    onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.order.provider=$event.value)),
                    onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.provider_modal=false))
                  }, null, 8, ["active"]),
                  _createVNode(_component_SiteSelector, {
                    active: _ctx.site_modal,
                    onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.order.site=$event.value)),
                    onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.site_modal=false))
                  }, null, 8, ["active"]),
                  _createVNode(_component_FolderSelector, {
                    active: _ctx.folder_modal,
                    root: _ctx.order?.site?'site':null,
                    onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.order.folder=$event.value)),
                    onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.folder_modal=false))
                  }, null, 8, ["active", "root"]),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['actions'])
                  }, [
                    _createVNode(_component_ion_button, {
                      disabled: (_ctx.order.folder?.startsWith('site/')&&!_ctx.order.site)||!_ctx.order.folder||(_ctx.folders?.length&&!this.order.folder)||!_ctx.order.provider||_ctx.loading,
                      fill: "solid",
                      onClick: _ctx.create
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('orders.new.next_button_label')) + " ", 1),
                        _createVNode(_component_ion_icon, {
                          slot: "end",
                          icon: _ctx.arrowForward
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ], 2)
                ])
              ], 2)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}