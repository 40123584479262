<template>
  <div>
    <ion-text>
      <h1>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h1>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-text>
  </div>
</template>