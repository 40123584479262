import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ion-padding" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_StatItem = _resolveComponent("StatItem")!
  const _component_IonCol = _resolveComponent("IonCol")!
  const _component_IonRow = _resolveComponent("IonRow")!
  const _component_IonGrid = _resolveComponent("IonGrid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, {
            color: _ctx.isMobile?'light':'primary',
            style: _normalizeStyle(!_ctx.isMobile?null:{
            '--border-width':0
          })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title)
            ]),
            _: 1
          }, 8, ["color", "style"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        class: _normalizeClass(_ctx.$style['wrapper']),
        color: "light"
      }, {
        default: _withCtx(() => [
          (_ctx.user?.is_logged_in)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ion_text, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h1", {
                        class: _normalizeClass(_ctx.$style['title'])
                      }, [
                        _createElementVNode("span", null, [
                          _createTextVNode(_toDisplayString(_ctx.$t("home.welcome")), 1),
                          (_ctx.user?.display_name)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, ","))
                            : _createCommentVNode("", true)
                        ]),
                        (_ctx.user?.display_name)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.user?.display_name) + ".", 1))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.stats?.length)
                  ? (_openBlock(), _createBlock(_component_IonGrid, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_IonRow, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stats, (stat) => {
                              return (_openBlock(), _createBlock(_component_IonCol, {
                                key: stat.name,
                                size: "12",
                                "size-lg": "4",
                                "size-md": "6",
                                "size-sm": "6"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_StatItem, { stat: stat }, null, 8, ["stat"])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}