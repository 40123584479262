<template>
  <ion-modal :is-open="is_open" @willDismiss="is_open = false">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ $t("widgets.user_selector.title") }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="is_open = false">
            {{ $t("global.close") }}
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="search"
          :placeholder="$t('global.search')"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list v-if="!first_loading && users.length">
        <ion-item
          v-for="user in users"
          :key="user.uuid"
          :button="true"
          @click="selectUser(user)"
        >
          <ion-avatar slot="start">
            <ion-img
              v-if="user.profile_picture"
              :alt="user.display_name"
              :src="user.profile_picture"
            />
          </ion-avatar>
          <ion-label>
            {{ user.display_name }}
          </ion-label>
        </ion-item>

        <ion-infinite-scroll
          :hidden="has_more_data || loading"
          threshold="200px"
          @ionInfinite="loadUsers($event)"
        >
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-list>

      <div
        v-else-if="!loading"
        :class="{
          'ion-padding': true,
          [$style['no-result']]: true,
        }"
      >
        <p>
          {{ $t("widgets.user_selector.no_result") }}
        </p>
      </div>
      <div
        v-else-if="loading"
        :class="{
          'ion-padding': true,
          [$style['no-result']]: true,
        }"
      >
        <ion-spinner></ion-spinner>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  InfiniteScrollCustomEvent,
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonModal,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { User } from "@/users";
import { useUserStore } from "@/stores/user";

export default defineComponent({
  props: ["active", "filter"],
  emits: ["close", "change"],
  setup() {
    const user = useUserStore();

    return {
      user,
    };
  },
  data() {
    return {
      users: [] as User[],
      search: "",
      first_loading: true,
      loading: false,
      page: 1,
      has_more_data: true,
      search_timeout: null as null | number,
    };
  },
  methods: {
    loadUsers(infinite_event?: InfiniteScrollCustomEvent) {
      if (this.loading) return;

      this.loading = true;
      this.axios
        .get("user/list" + "?page=" + this.page + "&" + this.filter_string)
        .then((response) => {
          this.users = [...this.users, ...response.data.data];
          this.loading = false;
          this.first_loading = false;
          this.page++;
          this.has_more_data = !!response.data.data.length;

          if (infinite_event) {
            infinite_event.target.complete();
          }
        });
    },
    selectUser(user: User) {
      this.$emit("change", { value: user });
      this.is_open = false;
    },
  },
  computed: {
    is_open: {
      get() {
        return this.$props.active;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    filter_string() {
      const filter = {
        search: this.search ?? null,
      };

      return Object.entries(filter)
        .map(([key, value]) => {
          if (!value) return "";

          return key + "=" + encodeURIComponent(value as string);
        })
        .join("&");
    },
  },
  mounted() {
    this.loadUsers();
  },
  watch: {
    search() {
      if (this.search_timeout) {
        clearTimeout(this.search_timeout);
      }
      this.search_timeout = setTimeout(() => {
        this.users = [];
        this.page = 1;
        this.has_more_data = true;
        this.loadUsers();
      }, 500);
    },
  },
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonSearchbar,
    IonModal,
    IonSpinner,
    IonTitle,
    IonButton,
    IonButtons,
    IonToolbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonAvatar,
    IonImg,
  },
});
</script>

<style lang="scss" module>
.no-result {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 70%;

  > * {
    margin: 0.5em 0;
  }
}
</style>
