import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    [_ctx.$style['message']]:true,
    [_ctx.$style['message--same-user']]: _ctx.message.same_user,
  })
  }, [
    (!_ctx.message.same_user)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.$style['message__user'])
        }, _toDisplayString(_ctx.item.user?.display_name), 3))
      : _createCommentVNode("", true),
    (!_ctx.message.same_user)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style['message__action'])
        }, _toDisplayString(_ctx.item.message), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['message__content'])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.meta, (meta) => {
        return (_openBlock(), _createElementBlock("p", {
          key: meta.key
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style['message__key'])
          }, _toDisplayString(meta.key), 3),
          _createTextVNode(" " + _toDisplayString(meta.new), 1)
        ]))
      }), 128))
    ], 2),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.$style['message__datetime'])
    }, _toDisplayString(_ctx.moment(_ctx.item.created_at).fromNow()), 3)
  ], 2))
}