import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["data-class"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["data-class"]
const _hoisted_7 = ["data-class"]
const _hoisted_8 = ["data-class"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_EquipmentSkeleton = _resolveComponent("EquipmentSkeleton")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_IonLabel = _resolveComponent("IonLabel")!
  const _component_SiteSelectorButton = _resolveComponent("SiteSelectorButton")!
  const _component_IonItem = _resolveComponent("IonItem")!
  const _component_UserSelectorButton = _resolveComponent("UserSelectorButton")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ContentSidebarWrapper = _resolveComponent("ContentSidebarWrapper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("equipment.screen_title")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.is_mobile)
            ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                key: 0,
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_searchbar, {
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    placeholder: _ctx.$t('equipment.search_placeholder')
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ContentSidebarWrapper, { side: "left" }, {
            content: _withCtx(() => [
              (!_ctx.loaded)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_EquipmentSkeleton)
                  ]))
                : (_ctx.equipment?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ion_refresher, {
                        slot: "fixed",
                        onIonRefresh: _ctx.reloadUp
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_refresher_content)
                        ]),
                        _: 1
                      }, 8, ["onIonRefresh"]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipment, (item) => {
                        return _withDirectives((_openBlock(), _createBlock(_component_ListItem, {
                          key: item.uuid,
                          equipment: item
                        }, null, 8, ["equipment"])), [
                          [_vShow, _ctx.isFiltered(item)]
                        ])
                      }), 128)),
                      _createVNode(_component_ion_infinite_scroll, {
                        id: "infinite-scroll",
                        threshold: "500px",
                        onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadNextPage($event)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_infinite_scroll_content)
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      "data-class": _ctx.$style['no-equipment']
                    }, [
                      _createVNode(_component_ion_text, {
                        class: "ion-text-center",
                        color: "medium"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.status || _ctx.site || _ctx.search)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("equipment.no_result_filtered")), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("equipment.no_result")), 1))
                        ]),
                        _: 1
                      })
                    ], 8, _hoisted_3))
            ]),
            sidebar: _withCtx(() => [
              _createElementVNode("div", {
                "data-class": _ctx.$style['filters-content']
              }, [
                (!_ctx.is_mobile)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      "data-class": _ctx.$style['filter-item-wrapper'],
                      class: ""
                    }, [
                      _createVNode(_component_ion_searchbar, {
                        modelValue: _ctx.search,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
                        "data-class": _ctx.$style['filter-item-search'],
                        placeholder: _ctx.$t('orders.search_placeholder'),
                        color: "light",
                        mode: "ios"
                      }, null, 8, ["modelValue", "data-class", "placeholder"])
                    ], 8, _hoisted_7))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.available_filters, (filter, name) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: name,
                    "data-class": _ctx.$style['filter-item-wrapper']
                  }, [
                    (name === 'site')
                      ? (_openBlock(), _createBlock(_component_IonItem, {
                          key: 0,
                          "data-class": _ctx.$style['filter-item'],
                          lines: "none",
                          style: {"--background":"transparent"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_IonLabel, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('equipment.filters.site')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_SiteSelectorButton, {
                              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.site=$event?.value))
                            })
                          ]),
                          _: 1
                        }, 8, ["data-class"]))
                      : _createCommentVNode("", true),
                    (name === 'user')
                      ? (_openBlock(), _createBlock(_component_IonItem, {
                          key: 1,
                          "data-class": _ctx.$style['filter-item'],
                          lines: "none",
                          style: {"--background":"transparent"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_IonLabel, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('equipment.filters.user')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_UserSelectorButton, {
                              onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.userFilter=$event?.value))
                            })
                          ]),
                          _: 1
                        }, 8, ["data-class"]))
                      : _createCommentVNode("", true),
                    (name === 'status')
                      ? (_openBlock(), _createBlock(_component_IonItem, {
                          key: 2,
                          "data-class": _ctx.$style['filter-item'],
                          lines: "none",
                          style: {"--background":"transparent"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_IonLabel, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('equipment.filters.status')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_select, {
                              modelValue: _ctx.status,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.status) = $event)),
                              multiple: false,
                              placeholder: _ctx.$t('equipment.filters.status_all'),
                              style: {"width":"100%"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_select_option, { value: null }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('equipment.filters.status_all')), 1)
                                  ]),
                                  _: 1
                                }),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter, (item) => {
                                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                                    key: item.name,
                                    value: item.name
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("equipment.status." + item.name)), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "placeholder"])
                          ]),
                          _: 2
                        }, 1032, ["data-class"]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_8))
                }), 128))
              ], 8, _hoisted_6)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}