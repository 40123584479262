import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_LogoT = _resolveComponent("LogoT")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_ctx.token_login)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style['wrapper'] + ' ' + _ctx.$style['token'])
      }, [
        _createVNode(_component_ion_spinner, { color: "primary" })
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(_ctx.$style['wrapper'])
      }, [
        (_ctx.screen!=='login'&&_ctx.screen!=='reset_success')
          ? (_openBlock(), _createBlock(_component_ion_back_button, {
              key: 0,
              text: _ctx.$t("auth.login_form.action_label"),
              defaultHref: "/login",
              onClick: _cache[0] || (_cache[0] = (e)=>{e.preventDefault(); _ctx.screen='login'})
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        (_ctx.screen !== 'reset_success')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.$style['logo'])
            }, [
              _createVNode(_component_LogoT, {
                class: _normalizeClass(_ctx.$style['icon'])
              }, null, 8, ["class"])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.screen === 'login')
          ? (_openBlock(), _createElementBlock("form", {
              key: 2,
              onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.loginAction && _ctx.loginAction(...args)), ["prevent"]))
            }, [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("auth.login_form.email.label")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                    disabled: _ctx.loading,
                    placeholder: _ctx.$t('auth.login_form.email.placeholder'),
                    type: "email"
                  }, null, 8, ["modelValue", "disabled", "placeholder"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("auth.login_form.password.label")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.password,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                    disabled: _ctx.loading,
                    placeholder: _ctx.$t('auth.login_form.password.placeholder'),
                    type: "password"
                  }, null, 8, ["modelValue", "disabled", "placeholder"]),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    color: "medium",
                    fill: "clear",
                    style: {"height":"4em"},
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.screen = 'reset'))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("auth.login_form.reset_label")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                class: _normalizeClass(_ctx.$style['submit']),
                disabled: _ctx.loading || !_ctx.email || !_ctx.password,
                color: "primary",
                expand: "full",
                size: "large",
                type: "submit",
                onClick: _withModifiers(_ctx.loginAction, ["prevent"])
              }, {
                default: _withCtx(() => [
                  (!_ctx.loading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("auth.login_form.action_label")), 1))
                    : (_openBlock(), _createBlock(_component_ion_spinner, { key: 1 }))
                ]),
                _: 1
              }, 8, ["class", "disabled", "onClick"])
            ], 32))
          : (_ctx.screen === 'reset')
            ? (_openBlock(), _createElementBlock("form", {
                key: 3,
                onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resetAction && _ctx.resetAction(...args)), ["prevent"]))
              }, [
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { position: "stacked" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("auth.login_form.email.label")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_input, {
                      modelValue: _ctx.email,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.email) = $event)),
                      disabled: _ctx.loading,
                      placeholder: _ctx.$t('auth.login_form.email.placeholder'),
                      type: "email"
                    }, null, 8, ["modelValue", "disabled", "placeholder"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_button, {
                  class: _normalizeClass(_ctx.$style['submit']),
                  disabled: _ctx.loading || !_ctx.email,
                  color: "primary",
                  expand: "full",
                  size: "large",
                  type: "submit",
                  onClick: _withModifiers(_ctx.resetAction, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    (!_ctx.loading)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("auth.reset_form.action_label")), 1))
                      : (_openBlock(), _createBlock(_component_ion_spinner, { key: 1 }))
                  ]),
                  _: 1
                }, 8, ["class", "disabled", "onClick"])
              ], 32))
            : (_ctx.screen === 'reset_success')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 4,
                  class: _normalizeClass(_ctx.$style['success'])
                }, [
                  _createVNode(_component_ion_icon, {
                    class: _normalizeClass(_ctx.$style['icon']),
                    icon: _ctx.checkmarkOutline,
                    color: "success"
                  }, null, 8, ["class", "icon"]),
                  _createVNode(_component_ion_text, { align: "center" }, {
                    default: _withCtx(() => [
                      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("auth.reset_form.success_title")), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t("auth.reset_form.success_message")), 1)
                    ]),
                    _: 1
                  })
                ], 2))
              : _createCommentVNode("", true)
      ], 2))
}