<template>
  <ion-item v-for="index in 16" :key="index" :button="true" :disabled="true">
    <div :class="$style['inner']">
      <div>
        <h1>
          <ion-skeleton-text animated style="width:7em"></ion-skeleton-text>
        </h1>
        <p>
          <ion-skeleton-text animated style="width:3em"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width:5em"></ion-skeleton-text>
        </p>


      </div>
      <div>
        <ion-skeleton-text animated style="width:4em"></ion-skeleton-text>


      </div>
    </div>
  </ion-item>

</template>

<script lang="ts">
import { defineComponent } from "vue";


import { IonItem, IonSkeletonText } from "@ionic/vue";

export default defineComponent({
  components: {
    IonItem, IonSkeletonText
  }
});
</script>
<style lang="scss" module>
.inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  font-size: .9em;

  h1, p {
    padding: 0;
    margin: 0;
  }

  p {
    margin-top: .25em;
    font-weight: bold;
    color: var(--ion-color-medium);
  }
}
</style>