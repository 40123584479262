<template>
  <ion-app>
    <ion-router-outlet/>
    <ion-modal
        :canDismiss="false"
        :isOpen="
        !asGuest && user.loaded && (should_re_login || !user.is_logged_in)
      "
    >
      <ion-content class="ion-padding">
        <LoginForm/>
      </ion-content>
    </ion-modal>
  </ion-app>
</template>

<script lang="ts">
import "./sentry";

import { defineComponent } from "vue";
import { IonApp, IonContent, IonModal, IonRouterOutlet, toastController, } from "@ionic/vue";

import { useUserStore } from "@/stores/user";
import { useOrderStore } from "@/stores/order";

import LoginForm from "@/components/forms/auth/LoginForm.vue";

import Echo from "laravel-echo";
import { usePlanningStore } from "@/stores/planning";

import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation";
import { useEquipmentStore } from "@/stores/equipment";
import { useControlSheetStore } from "@/stores/control_sheet";

export default defineComponent({
  components: {
    IonRouterOutlet,
    IonApp,
    IonContent,

    IonModal,
    LoginForm,
  },
  mounted() {
    this.setDocumentTitle();
    this.setNetworkParams();
    this.loadUser();
    this.loadDataset();

    if ((window as any)["cordova"])
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);

    (window as any)["loadUser"] = this.loadUser;
  },
  watch: {
    $route() {
      this.setDocumentTitle();
    },
  },
  methods: {
    setDocumentTitle() {
      const route_name = this.$route?.name?.toString();
      if (route_name)
        document.title =
            this.$t("document_titles." + route_name.toString()) +
            " — " +
            "Portal";
    },
    setNetworkParams() {
      const user_token = localStorage.getItem("user_token");
      this.axios.defaults.headers.common[
          "Authorization"
          ] = `Bearer ${user_token}`;

      this.axios.interceptors.response.use(
          (response) => response,
          async (err) => {
            if (
                err.response.config.method !== "get" ||
                err.response.config.url !== "user"
            ) {
              let error_message = err.response?.data?.message ?? err.message;
              if (err?.response?.status === 404) {
                error_message = this.$t("toast.error.not_found");
              }
              await (
                  await toastController.create({
                    header: this.$t("toast.error.title"),
                    message: error_message,
                    duration: 2000,
                    color: "danger",
                  })
              ).present();
            }

            throw err;
          }
      );

      const echo_options = {
        broadcaster: "pusher",
        key: "193Fks342n4bdsfJh43l",
        forceTLS: false,
        wsHost: this.axios.defaults.baseURL
            ?.replace("http://", "")
            .replace("https://", "")
            .replace("/api/", "")
            .replace(":8000", ""),
        wsPort: this.axios.defaults.baseURL?.includes("localhost") ? 6001 : 443,
        encrypted: false,
        authEndpoint: this.axios.defaults.baseURL + "broadcasting/auth",
        auth: {
          withCredentials: true,
          headers: this.axios.defaults.headers.common,
        },
      };

      (window as any).Pusher = require("pusher-js");
      (window as any).Echo = new Echo(echo_options);
    },
    loadUser() {
      this.axios
          .get("user")
          .then((resp) => {
            this.user.$patch(resp.data);
            this.user.loaded = true;
            this.makeLookAndFeel();
          })
          .catch(() => {
            this.user.loaded = true;

            const organization = this.$route.query?.organization;
            if (organization) {
              this.axios.get("organization/available").then((resp) => {
                const chosen_organization = resp.data.find(
                    (org: any) => org.system_name === organization
                );
                if (chosen_organization) {
                  this.user.current_organization = chosen_organization;
                  this.makeLookAndFeel();
                }
              });
            }
          });
    },
    loadDataset() {
      this.axios.get("datasets").then((resp) => {
        this.order.$patch(resp.data?.order);
        this.planning.$patch(resp.data?.planning);
        this.equipment.$patch(resp.data?.equipment);
        this.control_sheet.$patch(resp.data?.control_sheet);
      });
    },
    makeLookAndFeel() {
      const primary_color =
          this.user?.current_organization?.primary_color || "#cb0202";

      const root = document.documentElement;
      root.style.setProperty("--ion-color-primary", primary_color);
      root.style.setProperty(
          "--ion-color-primary-rgb",
          this.hexToRgb(primary_color).join(",")
      );
      root.style.setProperty("--ion-color-primary-contrast", "#ffffff");
      root.style.setProperty("--ion-color-primary-contrast-rgb", "255,255,255");
      root.style.setProperty(
          "--ion-color-primary-shade",
          this.shadeColor(primary_color, -0.12)
      );
      root.style.setProperty(
          "--ion-color-primary-tint",
          this.shadeColor(primary_color, 0.12)
      );
    },
    shadeColor(hex: string, percent: number) {
      let R = parseInt(hex.substring(1, 3), 16);
      let G = parseInt(hex.substring(3, 5), 16);
      let B = parseInt(hex.substring(5, 7), 16);

      R = Math.round(R * (1 + percent));
      G = Math.round(G * (1 + percent));
      B = Math.round(B * (1 + percent));

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      const RR =
          R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
      const GG =
          G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
      const BB =
          B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

      return "#" + RR + GG + BB;
    },
    hexToRgb(hex: string) {
      const r = parseInt(hex.substring(1, 3), 16);
      const g = parseInt(hex.substring(3, 5), 16);
      const b = parseInt(hex.substring(5, 7), 16);
      return [r, g, b];
    },
  },
  computed: {
    should_re_login(): boolean {
      return !!this.$route?.query?.token;
    },
    asGuest(): boolean {
      return !!this.$route?.meta?.guest;
    },
  },
  setup() {
    const user = useUserStore();
    const order = useOrderStore();
    const planning = usePlanningStore();
    const equipment = useEquipmentStore();
    const control_sheet = useControlSheetStore();
    return {
      user,
      order,
      planning,
      equipment,
      control_sheet,
    };
  },
});
</script>
